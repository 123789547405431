import {
    createCardClickedEvent,
    isPublicationCardItemWithVideo,
    PublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommonCardProps } from '../../cards/CardItem.Props'
import {
    getLandscapeWrapperTag,
    StyledCardHeadlineSkeleton,
    StyledInlineWrapper,
} from '../../cards/Landscape/Landscape.styled'
import { ResponsivePictureSizes } from '../../content/Picture/responsive'
import { MediaMode } from '../CardMedia/CardMedia'
import { ListCardTopicTag } from '../ListCard/components/ListCardTopicTag'
import { LandscapeImageSize } from '../Landscape/Landscape'
import {
    StyledClassificationLabel,
    StyledContentTeaser,
    StyledFundingLabelWrapper,
    StyledHeader,
    StyledHeadline,
    StyledLandscapeContent,
    StyledLandscapeLink,
    StyledLandscapeNumber,
    StyledLandscapeTimeInfo,
    StyledLiveClassificationContainer,
    StyledLiveHeadline,
    StyledLiveInlineWraper,
    StyledNightlyMedia,
} from './LandscapeNightly.styled'
import { Author, TopicBylineWrapper } from '../TheNightly/components'
import {
    getFundingType,
    getEditorialType,
    EditorialType,
} from '../../templates'
import { getReadTimeForDisplay } from '../../__helpers/get-read-time'
import { IconLiveTN } from '../../icons'
import { ConditionalOrElseWrapper } from '../../__helpers/ConditionalWrapper'
import { FundingLabel } from '../../advertising/FundingLabel/FundingLabel'
import { PublishedTime } from '../TheNightly/components/card-body/PublishedTime'
import { isLiveEvent } from '../../__helpers/is-live-event'
import { SavedArticleContextMenu } from '../../user-interactions/SavedArticleContextMenu/SavedArticleContextMenu'

export interface LandscapeNightlyProps extends CommonCardProps {
    className?: string
    cardNumber: number

    asListElement?: boolean
    hasBackground?: boolean
    hasPadding?: boolean
    hideByline?: boolean
    mediaMode?: MediaMode
    topicLevel?: 'parent'
    displayNumber?: boolean
    displayPublicationDate?: boolean
    renderLightHeadline?: boolean
    renderTeaser?: boolean
    useMainHeadline?: boolean
    renderBasicTeaser?: boolean

    // Images
    imageWidths?: ResponsivePictureSizes
    imagePosition?: 'left' | 'right'
    contentPosition?: 'top' | 'center'
    imageSize?: LandscapeImageSize
    item: MaybeLoaded<PublicationCardItem>
    hasContextMenu?: boolean
}

export const LandscapeNightly: React.FC<LandscapeNightlyProps> = ({
    className,
    cardNumber,
    asListElement,
    hasBackground,
    hasPadding,
    hideByline,
    mediaMode,
    topicLevel,
    displayNumber,
    imageWidths,
    imagePosition,
    contentPosition = 'top',
    imageSize,
    disableImageLazyLoad,
    item: cardItem,
    onEvent,
    displayPublicationDate = true,
    renderLightHeadline = false,
    renderTeaser = false,
    renderBasicTeaser = false,
    useMainHeadline = false,
    hasContextMenu = false,
}) => {
    const classificationEnabled = true
    const loadedCardItem = cardItem.loaded ? cardItem.result : undefined

    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (!loadedCardItem) {
            e.preventDefault()
            return
        }
        onEvent(
            createCardClickedEvent(
                onEvent,
                loadedCardItem,
                'InternalLandscape',
                'Landscape',
                cardNumber,
            ),
        )
    }

    const showByline = hideByline === false

    // Classifications
    const editorialType = loadedCardItem
        ? getEditorialType(loadedCardItem, classificationEnabled)
        : undefined
    const fundingType = loadedCardItem
        ? getFundingType(loadedCardItem, classificationEnabled)
        : undefined
    const isLiveClassification = editorialType === EditorialType.Live

    const showUpdated = isLiveEvent(loadedCardItem?.publicationKind)

    // Images
    const adjustedImageSize = imageSize || 'normal'
    const setImagePosition = imagePosition || 'left' // Default image position to left so it doesn't break existing cards
    const imageRatio = '4:3'
    const imageRight = imagePosition === 'right'
    const imageLeft = imagePosition !== 'right'

    const StyledLandscape = getLandscapeWrapperTag(asListElement ? 'li' : 'div')

    const fontScale = 1

    const defaultImageWidths = {
        mobile: '80px',
        tablet: '120px',
        desktop: '96px',
        fallbackWidth: 140,
        mode: 'fixed',
    } as ResponsivePictureSizes

    const renderImage = (
        <StyledNightlyMedia
            item={cardItem}
            imageWidths={imageWidths ? imageWidths : defaultImageWidths}
            fixedRatio={imageRatio}
            onEvent={onEvent}
            disableImageLazyLoad={disableImageLazyLoad}
            imagePosition={setImagePosition}
            imageSize={adjustedImageSize}
            mediaMode={mediaMode}
            CSSCrop={{
                default: { width: 1, height: 1 },
            }}
            numbered={displayNumber ?? false}
            isLarge={false}
            hasBiggerGap={renderTeaser}
        />
    )

    const renderEditorialLabel = editorialType ? (
        isLiveClassification ? (
            <StyledLiveClassificationContainer>
                <IconLiveTN />
                <StyledClassificationLabel classificationType={editorialType}>
                    {editorialType}
                </StyledClassificationLabel>
            </StyledLiveClassificationContainer>
        ) : (
            <StyledClassificationLabel classificationType={editorialType}>
                {editorialType}
            </StyledClassificationLabel>
        )
    ) : null

    const renderHeadline = loadedCardItem ? (
        isLiveClassification ? (
            <StyledLiveHeadline
                headlineText={
                    useMainHeadline
                        ? loadedCardItem.headingWithSearchHighlight ??
                          loadedCardItem.headline
                        : loadedCardItem.shortHeadline
                }
                showVideoIcon={isPublicationCardItemWithVideo(loadedCardItem)}
                kickerMode={'hidden'}
                publicationKind={loadedCardItem.publicationKind}
                requiredAccess={loadedCardItem.requiredAccess}
                fontScale={fontScale}
            />
        ) : (
            <StyledHeadline
                headlineText={
                    useMainHeadline
                        ? loadedCardItem.headingWithSearchHighlight ??
                          loadedCardItem.headline
                        : loadedCardItem.shortHeadline
                }
                showVideoIcon={isPublicationCardItemWithVideo(loadedCardItem)}
                kickerMode={'hidden'}
                publicationKind={loadedCardItem.publicationKind}
                requiredAccess={loadedCardItem.requiredAccess}
                fontScale={fontScale}
                renderLightHeadline={renderLightHeadline}
                headlineAsHTML
            />
        )
    ) : (
        <StyledCardHeadlineSkeleton fontScale={fontScale} count={3} />
    )

    const renderContextMenu =
        hasContextMenu && loadedCardItem ? (
            <SavedArticleContextMenu
                onEvent={onEvent}
                publication={loadedCardItem}
            />
        ) : undefined

    return (
        <StyledLandscape
            className={className}
            data-topic={loadedCardItem && loadedCardItem.primaryTopic.id}
            hasBackground={hasBackground || false}
            hasPadding={hasPadding}
        >
            <StyledLandscapeContent>
                {/* If not hiding, and we want image on the left! */}
                {mediaMode === 'hidden' ? null : imageLeft && renderImage}

                {displayNumber && (
                    <StyledLandscapeNumber>{cardNumber}</StyledLandscapeNumber>
                )}

                <StyledHeader contentPosition={contentPosition}>
                    <StyledLandscapeLink
                        to={toLinkWithHint(cardItem)}
                        onClick={linkClicked}
                        isLoading={!loadedCardItem}
                        hasBackground={false}
                    >
                        {renderEditorialLabel}
                        {renderHeadline}
                    </StyledLandscapeLink>
                    {cardItem.loaded && (
                        <>
                            <ConditionalOrElseWrapper
                                condition={isLiveClassification}
                                Wrapper={StyledLiveInlineWraper}
                                OrElseWrapper={StyledInlineWrapper}
                            >
                                {renderTeaser &&
                                    loadedCardItem &&
                                    loadedCardItem.teaserWithSearchHighlight && (
                                        <StyledContentTeaser
                                            dangerouslySetInnerHTML={{
                                                __html: loadedCardItem.teaserWithSearchHighlight,
                                            }}
                                        />
                                    )}
                                {renderBasicTeaser && loadedCardItem && (
                                    <StyledContentTeaser>
                                        {loadedCardItem.teaser}
                                    </StyledContentTeaser>
                                )}

                                <ListCardTopicTag
                                    item={cardItem}
                                    level={topicLevel}
                                />
                            </ConditionalOrElseWrapper>
                            <StyledLandscapeTimeInfo>
                                {showByline &&
                                    loadedCardItem &&
                                    loadedCardItem.readTimeMinutes !== null && (
                                        <TopicBylineWrapper>
                                            <Author>
                                                {getReadTimeForDisplay(
                                                    loadedCardItem.readTimeMinutes,
                                                )}{' '}
                                                Min Read
                                            </Author>
                                        </TopicBylineWrapper>
                                    )}
                                <PublishedTime
                                    shouldDisplay={displayPublicationDate}
                                    isLiveEvent={showUpdated}
                                    publicationDate={
                                        showUpdated
                                            ? loadedCardItem?.lastUpdated
                                            : loadedCardItem?.publicationDate
                                    }
                                />
                            </StyledLandscapeTimeInfo>
                        </>
                    )}
                    {fundingType && (
                        <StyledFundingLabelWrapper>
                            <FundingLabel
                                fundingType={fundingType}
                                linkToDisclaimer={true}
                            >
                                {fundingType}
                            </FundingLabel>
                        </StyledFundingLabelWrapper>
                    )}
                </StyledHeader>
                {renderContextMenu}

                {/* If not hiding  and we want image on the right! */}
                {mediaMode === 'hidden' ? null : imageRight && renderImage}
            </StyledLandscapeContent>
        </StyledLandscape>
    )
}
LandscapeNightly.displayName = 'LandscapeNightly'
