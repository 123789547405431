import styled from '@emotion/styled'
import { breakpoint, calcRem, PerthNowThemeConfig } from '../../../../__styling'
import { tokens } from '@news-mono/design-tokens'
import { IconSpinner } from '../../../../icons'
import { UserSettingProps } from '../../../../user-setting'
import { textMixin } from '../../../../mixins/TextMixin/TextMixin'

export interface VerifyEmailContentProps extends UserSettingProps {
    userId: string
}

const { neutral } = tokens.thenightly.colors.palette

export const StyledSaveNameContentLayout = styled.div({
    display: 'flex',
    flexDirection: 'column',
    padding: calcRem(0),
    gap: calcRem(24),

    [breakpoint('sm')]: {
        padding: calcRem(12, 32),
        gap: calcRem(32),
    },

    [breakpoint('xs')]: {
        flexDirection: 'row',
    },
})

export const StyledSaveNameContentColumnA = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,

    gap: calcRem(8),
})

export const StyledSaveNameContentColumnB = styled.div({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,

    gap: calcRem(8),
})

export const StyledSaveNameContentBodyText = styled.p({
    margin: 0,
    textAlign: 'left',
})

export const StyledSaveNameContentForm = styled.form({
    display: 'flex',
    gap: calcRem(16),
    flexDirection: 'column',
    alignItems: 'flex-start',
})

export const StyledSaveNameContentInputWrapper = styled.div({
    padding: calcRem(8, 16),
    backgroundColor: neutral[10],
    border: `1px solid ${neutral[20]}`,
    borderRadius: calcRem(8),
    fontSize: calcRem(14),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: neutral[70],
    width: '100%',

    focus: {
        outline: `2px solid ${neutral[30]}`,
    },
})

export const StyledSaveNameContentInput = styled.input<{ hasError: boolean }>(
    ({ theme, hasError }) => [
        {
            width: '100%',
            marginRight: '0',

            border: 'none',
            borderRadius: '0',
        },
        theme.kind === 'thenightly' && {
            padding: calcRem(4, 0),
            color: neutral[70],
            height: '100%',
            fontSize: calcRem(16),
            backgroundColor: 'inherit',
            lineHeight: calcRem(24),

            '::placeholder': {
                color: hasError
                    ? tokens.thenightly.colors.palette.utility.error.default
                    : 'default',
            },

            ':focus': {
                outline: 'none',
            },
        },
        theme.kind === 'perthnow' && [
            textMixin<PerthNowThemeConfig>(theme, 'Label/L/Regular'),
            {
                height: calcRem(48),
                padding: calcRem(0, 12),

                color: '#252525',
                background: '#F6F6F6',

                borderRadius: calcRem(8),
                outlineStyle: 'solid',
                outlineColor: '#DCDCDC',
                outlineWidth: calcRem(1),
                outlineOffset: calcRem(-1),
            },
            hasError && {
                outlineColor: '#C12323',
                outlineWidth: calcRem(2),
                outlineOffset: calcRem(-2),

                '::placeholder': {
                    color: '#C12323',
                },
            },
        ],
    ],
)

export const StyledSaveNameContentButtonWrapper = styled.div({
    width: '100%',
    '&.button': {
        width: '100%',
    },
})

export const StyledSaveNameContentSpinner = styled(IconSpinner)({
    width: '7%',
    alignSelf: 'center',
})

export const StyledSaveNameContentValidationError = styled.div(({ theme }) => ({
    color: tokens.thenightly.colors.palette.utility.error.light,
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(12),
    fontWeight: 400,
    lineHeight: calcRem(16),
    paddingLeft: calcRem(16),
    marginTop: calcRem(-11),
}))
