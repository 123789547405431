import {
    CommonSections,
    LdJsonItemDTO,
    MetaLink,
    PageMetaDTO,
    PageMetaItemDTO,
    PageMetaLinkDTO,
    SectionMetaInfoDTO,
} from '@west-australian-newspapers/publication-types'
import H from 'history'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { productIdFromLocation } from '../data/google/subscriptions'
import { DataLoaderGlobalParams } from '../data/resources'
import { stripUndesirableExtensions } from './get-route-info-for-resolution'
import { getMetaForSection } from './meta'
import { RouteMeta } from './page-definition'
import { getBaseUrl } from './url'
import { getSectionMetaInfo } from './use-section-meta'

function findCanonical(meta: Array<PageMetaLinkDTO>): string | undefined {
    const metaLink = meta.find((value) => value.rel === 'canonical') as
        | MetaLink
        | undefined
    return metaLink ? metaLink.href : undefined
}

function resolveCanonicalUrl(
    serverMeta: PageMetaDTO,
    routeMeta: RouteMeta<string>,
) {
    return routeMeta.pageMeta && routeMeta.pageMeta.link
        ? findCanonical(routeMeta.pageMeta.link)
        : serverMeta.link
        ? findCanonical(serverMeta.link)
        : undefined
}

/**
 * Generate the helmet component with appropriate props
 */
export const PageMeta: React.FC<{
    serverMeta: PageMetaDTO
    routeMeta: RouteMeta<string>
    location: H.Location
    services: DataLoaderGlobalParams
}> = ({ serverMeta, routeMeta, location, services }) => {
    const section = routeMeta.section || CommonSections.default
    const pageMeta: PageMetaDTO = routeMeta.pageMeta || {}
    const socialMeta: PageMetaDTO = routeMeta.socialMeta || {}

    const reduxState = services.store.getState()
    const allSectionMeta = reduxState.meta.sectionMeta

    const sectionInfo: SectionMetaInfoDTO = getSectionMetaInfo(
        allSectionMeta,
        section,
    )

    const socialHeading = socialMeta.title || routeMeta.heading
    const metaHeading = pageMeta.title || routeMeta.heading
    const titleTag = routeMeta.noMetaTitleTemplate
        ? metaHeading
        : `${metaHeading} | ${
              sectionInfo.organisationLd.name === 'The Nightly'
                  ? 'The Nightly'
                  : sectionInfo.Title
          }`
    const metaDescription = routeMeta.includeFromSectionInMetaDescription
        ? pageMeta.description
            ? `${pageMeta.description} from ${sectionInfo.Title}`
            : undefined
        : pageMeta.description

    const socialDescription = socialMeta.description

    const productID = productIdFromLocation(
        undefined,
        services.config.publicUrl,
    )

    const routeSectionMeta = getMetaForSection(allSectionMeta, sectionInfo)
    const metaItems: PageMetaItemDTO[] = [
        {
            name: 'msapplication-config',
            content: sectionInfo.favicons.browserConfig,
        },
    ]
    const linkItems: PageMetaLinkDTO[] = [
        { rel: 'apple-touch-icon', href: sectionInfo.favicons.appleTouch },
        { rel: 'manifest', href: sectionInfo.favicons.webmanifest },
        { rel: 'icon', href: sectionInfo.favicons.favicon16, sizes: '16x16' },
        { rel: 'icon', href: sectionInfo.favicons.favicon32, sizes: '32x32' },
        {
            rel: 'icon',
            href: sectionInfo.favicons.favicon,
            sizes: '16x16 32x32 48x48',
        },
        {
            rel: 'mask-icon',
            href: sectionInfo.favicons.safariPinned,
            color: sectionInfo.favicons.pinnedColor,
        },
    ]

    /*
     * Resolve the internal and external canonical urls for the page.
     * Internal canonical url is the standard version of the page on the current site and is used in og and ld+json.
     * External canonical url is the standard version of the page for amp content or the original source for republished content,
     * and is used for the <link rel="canonical"> tag.
     */
    if (!serverMeta.link) {
        serverMeta.link = []
    }

    const serverCanonical = findCanonical(serverMeta.link)

    const resolvedCanonical = resolveCanonicalUrl(serverMeta, routeMeta)

    const publicUrl = getBaseUrl(services.config.publicUrl, sectionInfo)
    const publicPath = publicUrl + location.pathname

    const externalCanonicalUrl = resolvedCanonical || publicPath
    const internalCanonicalUrl =
        resolvedCanonical &&
        resolvedCanonical.indexOf(services.config.publicUrl) >= 0
            ? resolvedCanonical
            : publicPath

    // Default the canonical meta tag if not provided by the route
    if (!serverCanonical) {
        serverMeta.link.push({
            rel: 'canonical',
            href: stripUndesirableExtensions(externalCanonicalUrl),
        })
    }

    const routeMetaScripts: PageMetaItemDTO[] = [
        {
            property: 'og:url',
            content: stripUndesirableExtensions(publicPath),
        },
    ]

    routeMetaScripts.push({ property: 'og:title', content: socialHeading })
    routeMetaScripts.push({ name: 'twitter:title', content: socialHeading })

    if (metaDescription) {
        routeMetaScripts.push({ name: 'description', content: metaDescription })
    }
    if (socialDescription) {
        routeMetaScripts.push({
            property: 'og:description',
            content: socialDescription,
        })
        routeMetaScripts.push({
            name: 'twitter:description',
            content: socialDescription,
        })
    }

    routeMetaScripts.push({
        property: 'fb:app_id',
        content: sectionInfo.FacebookAppId,
    })
    if (sectionInfo.FacebookPagesId) {
        routeMetaScripts.push({
            property: 'fb:pages',
            content: sectionInfo.FacebookPagesId,
        })
    }

    const appsWithSearchEntry = ['thewest', 'thenightly']
    const includeSearchEntry = appsWithSearchEntry.includes(
        services.config.apiCallerHeader,
    )
    const searchEntryLdJson = includeSearchEntry
        ? [
              {
                  '@type': 'SearchAction',
                  target: {
                      '@type': 'EntryPoint',
                      urlTemplate: `${publicUrl}/search?search={search_term_string}`,
                  },
                  'query-input': 'required name=search_term_string',
              },
          ]
        : undefined

    const websiteLdJson = {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        '@id': `${publicUrl}/#/schema/WebSite`,
        url: publicUrl,
        name: sectionInfo.Title,
        description: sectionInfo.organisationLd.description,
        publisher: {
            '@id': `${publicUrl}/#/schema/Organization`,
        },
        potentialAction: searchEntryLdJson,
        inLanguage: 'en-AU',
    }

    const webPageJson = {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        url: stripUndesirableExtensions(internalCanonicalUrl),
        name: titleTag,
        alternateName: sectionInfo.Title,
        alternativeHeadline: pageMeta.title, // Only populate when pageMeta.title is set
        description: metaDescription,
        isPartOf:
            routeMeta.pageType === 'breach'
                ? {
                      '@type': ['CreativeWork', 'Product'],
                      name: sectionInfo.Title,
                      productID,
                  }
                : undefined,
        publisher: {
            '@type': 'NewsMediaOrganization',
            '@id': `${services.config.publicUrl}/#/schema/Organization`,
        },
        image: {
            '@type': 'ImageObject',
            url: sectionInfo.OgImage.image,
            width: String(sectionInfo.OgImage.width),
            height: String(sectionInfo.OgImage.height),
        },
    }

    const ldJson: LdJsonItemDTO[] = [
        sectionInfo.organisationLd,
        ...(routeMeta.pageType !== 'publication' &&
        routeMeta.pageType !== 'topic'
            ? [webPageJson]
            : []),
        { ...websiteLdJson },
        ...(routeSectionMeta.ldJson || []),
        ...(serverMeta.ldJson || []),
        ...(routeMeta.pageType !== 'publication' && routeMeta.pageMeta
            ? routeMeta.pageMeta.ldJson || pageMeta.ldJson || []
            : []),
        ...(routeMeta.pageType === 'publication' && routeMeta.pageMeta
            ? routeMeta.pageMeta.ldJson?.values() ||
              pageMeta.ldJson?.values() ||
              []
            : []),
    ]

    // Helmet will dedupe meta which is why there are separate Helmet tags
    return (
        <React.Fragment>
            {/* Generic og and twitter */}
            <Helmet
                defer={false}
                title={titleTag}
                meta={metaItems}
                link={linkItems}
            />

            {/* Section meta */}
            <Helmet defer={false} meta={routeSectionMeta.meta} />

            {/* Automatic page meta */}
            <Helmet defer={false} meta={routeMetaScripts} />

            {/* Server resolved meta */}
            <Helmet
                defer={false}
                link={serverMeta.link}
                meta={serverMeta.meta}
            />

            {/* Route specified pageMeta */}
            <Helmet
                defer={false}
                meta={routeMeta.pageMeta ? routeMeta.pageMeta.meta : undefined}
                link={routeMeta.pageMeta ? routeMeta.pageMeta.link : undefined}
                script={[getLdJsonScriptTag(ldJson)]}
            />
        </React.Fragment>
    )
}
PageMeta.displayName = 'PageMeta'

export function getLdJsonScriptTag(obj: object) {
    return {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(obj),
    }
}
