import { Product, useLocationState } from '@news-mono/web-common'
import React from 'react'
import { useProduct } from '../../__product/useProduct'
import { Countdown } from './Countdown'
import {
    Content,
    DonateContent,
    DonateNow,
    heartIconClassname,
    LogoAndInfoContent,
    StyledHeart,
    StyledTelethonCountdown,
    StyledTelethonKids,
    StyledTelethonLogo,
    StyledTelethonShapesRight,
    StyledTelethonTrianglesLeft,
} from './TelethonCountdown.styled'

const telethonLogo = require('./assets/telethon-logo-horizontal.svg')
const telethonKids = require('./assets/telethon-kids-2023@2x.png')
const shapesLeft = require('./assets/telethon-shapes-lhs.png')
const shapesRight = require('./assets/telethon-shapes-rhs.png')

export interface TelethonCountdownProps {
    targetDate: Date
}

export function TelethonCountdown({ targetDate }: TelethonCountdownProps) {
    const { userSelectedRegion } = useLocationState()
    const product = useProduct()

    const [hideCountdown, setHideCountdown] = React.useState(
        targetDate.getTime() < new Date().getTime(),
    )

    const countdownComplete = () => {
        setHideCountdown(true)
    }

    const getDonationLink = () => {
        const utmLink = (masthead: string) =>
            `https://www.telethon7.com/donate/?utm_source=${masthead}&utm_medium=Referral&utm_campaign=telethon2020&utm_term=donate&utm_content=countdownwidget`

        switch (product) {
            case Product.PerthNow:
                return utmLink('PerthNow')
            case Product.SevenNews:
                return utmLink('7NEWS')
            default:
                // Default to the west
                return utmLink('TheWest')
        }
    }

    // If 7NEWS and location !== WA dont show anything
    if (product === Product.SevenNews && userSelectedRegion !== 'WA') {
        return null
    }

    return (
        <StyledTelethonCountdown>
            <StyledTelethonKids>
                <img src={telethonKids} role="presentation" alt="" />
            </StyledTelethonKids>
            <StyledTelethonTrianglesLeft src={shapesLeft} />
            <Content>
                <LogoAndInfoContent hiddenCountdown={hideCountdown}>
                    <StyledTelethonLogo src={telethonLogo} alt="Telethon" />
                    <p>
                        Don’t miss Telethon on Saturday 21 and Sunday&nbsp;22
                        October.
                    </p>
                </LogoAndInfoContent>
                <DonateContent>
                    <DonateNow to={getDonationLink()}>
                        <StyledHeart className={heartIconClassname} />
                        {'Donate Now'}
                    </DonateNow>
                    <Countdown
                        hideWhenComplete={true}
                        targetDate={targetDate}
                        countdownFinished={() => countdownComplete()}
                    />
                </DonateContent>
            </Content>
            <StyledTelethonShapesRight
                src={shapesRight}
                role="presentation"
                alt=""
                hiddenCountdown={hideCountdown}
            />
        </StyledTelethonCountdown>
    )
}
