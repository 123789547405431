export const timeoutOccuredMsg = 'timeout occured'

// Permit the promise: Promise<any> to finish so error tracking does not pick up the reject at the network level
export function promiseWithTimeout<T>(
    timeoutMs: number,
    promise: Promise<any>,
): Promise<T> {
    const timeout: Promise<T> = new Promise((_, reject) =>
        setTimeout(() => reject(new Error(timeoutOccuredMsg)), timeoutMs),
    )
    return Promise.race([timeout, promise])
}
