import { perthnow } from './perthnow'
import { PerthNowPalette } from './perthnow/colors'
import { sevennews } from './sevennews'
import { SevenNewsPalette } from './sevennews/colors'
import { thewest } from './thewest'
import { thenightly } from './thenightly'
import { TheWestPalette } from './thewest/colors'
import { DesignTokens } from './types'

export { PerthNowColourMap, PerthNowPalette } from './perthnow/colors'
export { SevenNewsColourMap, SevenNewsPalette } from './sevennews/colors'
export { TheWestColourMap, TheWestPalette } from './thewest/colors'
export * from './types'

export const tokens: DesignTokens = {
    thewest,
    perthnow,
    sevennews,
    thenightly,
}

export type ThemePallete = PerthNowPalette | SevenNewsPalette | TheWestPalette
