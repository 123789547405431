import React from 'react'
import { CollectionEvent, WebLink } from '@news-mono/web-common'
import {
    StyledOlympicsHeader,
    StyledOlympicsHeaderFullCoverage,
    StyledOlympicsHeaderIcon,
    StyledOlympicsFullCoverageChevron,
    StyledOlympicsHeaderFullCoverageText,
} from './OlympicsCollectionHeader.styled'
import { colors } from '../../__styling'

/** The props utilised to configure the olympics collection layout */
export interface OlympicsCollectionHeaderProps {
    /** onEvent handler passed down from the top level */
    onEvent: (event: CollectionEvent) => void
    /** Default's to a value of true */
    isHomepage?: boolean
}

/**
 * The OlympicsCollectionHeader is the bar above the OlympicsCollection of articles. The header is used as
 * an identifier to identify the olympics content. The header contains the GameOnParis logo, as well as the
 * 'FULL COVERAGE >' text on the right-hand side on the home page on TheWest.
 */
export const OlympicsCollectionHeader: React.FC<OlympicsCollectionHeaderProps> =
    ({ onEvent, isHomepage }) => {
        return (
            <StyledOlympicsHeader>
                <WebLink to={'/sport/olympics'}>
                    <StyledOlympicsHeaderIcon />
                </WebLink>
                {isHomepage && (
                    <StyledOlympicsHeaderFullCoverage to={'/sport/olympics'}>
                        <StyledOlympicsHeaderFullCoverageText>
                            Full Coverage
                        </StyledOlympicsHeaderFullCoverageText>
                        <StyledOlympicsFullCoverageChevron
                            fillColor={colors.olympics.link}
                        />
                    </StyledOlympicsHeaderFullCoverage>
                )}
            </StyledOlympicsHeader>
        )
    }
