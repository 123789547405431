import styled, { CSSObject } from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { IconPremium } from '../../icons/IconPremium/IconPremium'
import { EditorialLabel } from '../../typography/EditorialLabel/EditorialLabel'
import { Timestamp } from '../../typography/Timestamp/Timestamp'
import { Breakpoints } from '../../__styling/settings/breakpoints'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { FontScales } from '../../__styling/settings/fontScale'
import { ThemeMargins } from '../../__styling/settings/metrics'
import {
    breakpoint,
    breakpointMax,
    getSmallestBreakpointValue,
} from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { KickerBreakpoints, KickerMode } from '../Kicker/Kicker'
import {
    StyledCommentsIcon as KickerStyledCommentsIcon,
    StyledKicker,
} from '../Kicker/Kicker.styled'
import { TeaserBreakpoints, TeaserMode } from './CardTeaser'

export const styledHeadlineClassName = 'Card-Headline'

export interface StyledTextProps {
    cardLoading?: boolean
    hasPadding?: boolean
    verticalSpacing?: keyof ThemeMargins
    cardHasBackground?: boolean
    isLarge?: boolean
}

export const StyledText = styled('div')<StyledTextProps>(
    {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 'auto',
        alignContent: 'flex-start',
        width: '100%',
        overflow: 'visible',
    },
    ({ theme, cardHasBackground }) => ({
        lineHeight: themedValue(theme, {
            sevennews: calcRem(22), // TODO: Remove rem
            perthnow: 1.375,
            fallback: undefined,
        }),
        color: themedValue(theme, {
            sevennews: cardHasBackground ? colors.white : undefined,
            fallback: undefined,
        }),
    }),
)
StyledText.displayName = 'CardText'

export interface StyledHeadlineProps {
    fontScale: FontScales
}

export const StyledHeadline = withClass(styledHeadlineClassName)(
    styled('h2')<StyledHeadlineProps>(
        {
            alignSelf: 'flex-start',
            width: '100%',
            ...transition({ properties: ['color'] }),
        },
        ({ theme, fontScale }) => ({
            fontWeight: themedValue(theme, {
                thewest: 500,
                fallback: 700,
            }),
            fontSize: fontScale && calcRem(Math.ceil(18 * fontScale)),
            fontFamily: theme.fonts.cards.headline,
            margin: `0 0 ${calcRem(theme.cards.textOffset)}`,
            flex: '0 0 100%',
            lineHeight: themedValue(theme, {
                sevennews: 1,
                fallback: 0,
            }),
            color: theme.colors.text.secondary,
        }),
        ({ theme }) =>
            theme.kind === 'sevennews' && {
                fontFamily: fonts.sevennews.sansSerif,
                [`${StyledEditorialLabel} + ${StyledKicker}`]: {
                    display: 'none',
                },
            },
    ),
)
export const StyledHeadlineDiv = withClass(styledHeadlineClassName)(
    styled('div')<StyledHeadlineProps>(
        {
            alignSelf: 'flex-start',
            width: '100%',
            ...transition({ properties: ['color'] }),
        },
        ({ theme, fontScale }) => ({
            fontWeight: themedValue(theme, {
                thewest: 500,
                fallback: 700,
            }),
            fontSize: fontScale && calcRem(Math.ceil(18 * fontScale)),
            fontFamily: theme.fonts.cards.headline,
            margin: `0 0 ${calcRem(theme.cards.textOffset)}`,
            flex: '0 0 100%',
            lineHeight: themedValue(theme, {
                sevennews: 1,
                fallback: 0,
            }),
            color: theme.colors.text.secondary,
        }),
        ({ theme }) =>
            theme.kind === 'sevennews' && {
                fontFamily: fonts.sevennews.sansSerif,
                [`${StyledEditorialLabel} + ${StyledKicker}`]: {
                    display: 'none',
                },
            },
    ),
)

export interface StyledHeadlineTextProps {
    fontScale: FontScales
    cardHasBackground?: boolean
    isLarge?: boolean
}

export const StyledHeadlineText = styled('span')<StyledHeadlineTextProps>(
    {
        display: 'block',

        '&::before': {
            content: `''`,
            display: 'block',
        },
    },
    ({ theme, fontScale, cardHasBackground }) => {
        return {
            lineHeight: getLineHeightForFontScale(fontScale) || 1.154,
            color: themedValue(theme, {
                thewest: theme.colors.text.heading,
                sevennews: colors.sevennews.bauhausBlack,
                fallback: cardHasBackground
                    ? theme.colors.text.backgroundText || colors.white
                    : theme.colors.text.heading,
            }),
            display: themedValue(theme, {
                thewest: undefined,
                fallback: cardHasBackground ? 'inline' : undefined,
            }),
        }
    },
)

export function getLineHeightForFontScale(fontScale: FontScales) {
    switch (fontScale) {
        case 2:
        case 1.4:
        case 1.2:
            return 1.154
        case 0.75:
            return 1.143
        default:
            return
    }
}

export interface StyledTeaserProps {
    cardHasBackground?: boolean
    teaserMode?: TeaserMode
}
type BreakpointKey = keyof Breakpoints

function getTeaserStyles({ teaserMode }: StyledTeaserProps): CSSObject {
    if (!teaserMode) {
        return {}
    }

    if (typeof teaserMode === 'string') {
        return { display: teaserMode === 'hidden' ? 'none' : 'block' }
    }

    return (
        teaserMode &&
        Object.entries(teaserMode)
            .slice(1)
            .reduce<CSSObject>(
                (styles, [breakpointValue, teaserModeForBreakpoint]) => {
                    styles[breakpoint(breakpointValue as BreakpointKey)] = {
                        display:
                            teaserModeForBreakpoint === 'hidden'
                                ? 'none'
                                : 'block',
                    }

                    return styles
                },
                {},
            )
    )
}

export const teaserBreakpoints: TeaserBreakpoints[] = [
    'initial',
    'xs',
    'sm',
    'md',
]

export const StyledTeaser = styled('p')<StyledTeaserProps>(
    {
        margin: 0,
        flexGrow: 1,
        overflow: 'hidden',
    },
    getTeaserStyles,
    ({ theme, cardHasBackground, teaserMode }) => {
        return {
            [breakpointMax('xxs')]: {
                fontSize: theme.kind === 'thewest' ? '0.9rem' : undefined,
            },
            [breakpointMax('xs')]: {
                display:
                    getSmallestBreakpointValue(teaserMode) === 'hidden'
                        ? 'none'
                        : 'block',
            },
            fontFamily: themedValue(theme, {
                thewest: theme.fonts.serif,
                sevennews: theme.fonts.sansSerif,
                fallback: theme.fonts.sansSerif,
            }),
            lineHeight: themedValue(theme, {
                perthnow: 1.375,
                sevennews: cardHasBackground ? calcRem(22) : 1.467, // TODO Remove Rems
                fallback: 1.467,
            }),
            color: themedValue(theme, {
                sevennews: cardHasBackground
                    ? colors.white
                    : colors.sevennews.charade, // due to color being overriden by sectioned elements
                perthnow: theme.colors.text.backgroundText,
                fallback: undefined,
            }),
            fontSize: themedValue(theme, {
                sevennews: cardHasBackground ? calcRem(14) : calcRem(13),
                thewest: calcRem(12),
                fallback: undefined,
            }),
            fontWeight: themedValue(theme, {
                sevennews: 500,
                fallback: 400,
            }),
            marginBottom: themedValue(theme, {
                sevennews: calcRem(5),
                fallback: undefined,
            }),
        }
    },
)

interface StyledTimeStampProps {
    hasBackground?: boolean
    isLarge?: boolean
}

export const StyledTimestamp = styled(Timestamp)<StyledTimeStampProps>(
    ({ theme, hasBackground, isLarge }) => ({
        color: hasBackground ? colors.white : colors.sevennews.stormGrey,
        marginTop: isLarge ? calcRem(theme.margins.xs) : 'auto',
        marginBottom: calcRem(4),
    }),
)

export interface StyledIconProps {
    kickerMode?: KickerMode
}
export interface StyledPremiumIconProps extends StyledIconProps {
    width: number
    height: number
}

// The icons are usually shown within the kicker, however if the kicker is changing at
// different breakpoints, then we need to hide the icons in the headline so it's
// not shown twice.
//
// If the kicker breakpoint is set to visible, we set the icons to hidden
// If the kicker breakpoint is set to hidden, we set icons to 'inline-block'
// It's the reverse of kicker styles
export type IconBreakpointKey = keyof Breakpoints
export const kickerBreakpoints: KickerBreakpoints[] = [
    'initial',
    'xs',
    'sm',
    'md',
]

export function iconVisibilityStyles({
    kickerMode,
}: StyledIconProps): CSSObject {
    if (
        !kickerMode ||
        kickerMode === 'hidden' ||
        kickerMode.kickerVisibility === 'hidden'
    ) {
        return { display: 'inline-block' }
    }

    if (kickerMode.kickerVisibility === 'visible') {
        return { display: 'none' }
    }

    return kickerMode?.kickerVisibility !== undefined
        ? Object.entries(kickerMode?.kickerVisibility)
              .slice(1)
              .reduce<CSSObject>(
                  (styles, [breakpointValue, visibilityForBreakpoint]) => {
                      styles[breakpoint(breakpointValue as IconBreakpointKey)] =
                          {
                              display:
                                  visibilityForBreakpoint === 'hidden'
                                      ? 'inline-block'
                                      : 'none',
                          }
                      return styles
                  },
                  {},
              )
        : { display: undefined }
}

export function getInitialIconStyles({
    kickerMode,
}: StyledIconProps): CSSObject {
    if (kickerMode === 'hidden') {
        return { display: 'inline-block' }
    }

    return {
        [breakpointMax('xs')]: {
            display:
                getSmallestBreakpointValue(kickerMode?.kickerVisibility) ===
                'hidden'
                    ? 'inline-block'
                    : 'none',
        },
    }
}

export const StyledPremiumIcon = styled(IconPremium)<StyledPremiumIconProps>(
    ({ width, height }) => ({
        width,
        height,
        verticalAlign: 'middle',
    }),
    getInitialIconStyles,
    iconVisibilityStyles,
)

export const StyledCommentsIcon = styled(
    KickerStyledCommentsIcon,
)<StyledIconProps>(
    {
        marginRight: calcRem(4),
    },
    getInitialIconStyles,
    iconVisibilityStyles,
)

interface StyledEditorialLabelProps {
    hasKicker: boolean
}

export const StyledEditorialLabel = styled(
    EditorialLabel,
)<StyledEditorialLabelProps>(({ theme, hasKicker }) => [
    theme.kind === 'perthnow' && {
        display: 'block',
        marginBottom: calcRem(theme.margins.xs),
    },
    theme.kind === 'thewest' &&
        (hasKicker
            ? {
                  display: 'none',
              }
            : {
                  display: 'block',
                  marginBottom: calcRem(theme.margins.sm),
                  lineHeight: 1,
              }),
])

export const StyledKickerEditorialLabel = styled(EditorialLabel)<{
    isOlympics?: boolean
}>({ display: 'none' }, ({ theme, isOlympics = false }) => [
    theme.kind === 'thewest' && {
        display: isOlympics ? 'inline-flex' : 'inline-block',
        paddingRight: calcRem(8),
        height: isOlympics ? '100%' : undefined,

        '&::after': {
            content: `''`,
            display: 'inline-block',
            width: 1,
            height: calcRem(13),
            transform: `translate(4px, ${isOlympics ? '0' : '2'}px)`,
            background: colors.thewest.greyElephant,
        },
    },
])

export const StyledHeadlineEditorialLabel = styled(EditorialLabel)(
    { display: 'none' },
    ({ theme }) => [
        theme.kind === 'thewest' && {
            // The display is overridden in Landscape.styled.tsx
            display: 'none',
            paddingRight: calcRem(8),
            '&::after': {
                content: `''`,
                display: 'inline-block',
                width: 1,
                height: calcRem(13),
                transform: 'translate(4px, 2px)',
                background: colors.thewest.greyElephant,
            },
        },
    ],
)
