import {
    EventPostV4DTO,
    RequiredAccessDTO,
} from '@west-australian-newspapers/publication-types'
import { HeroVideo } from '../client-data-types/HeroVideo'
import { Profile } from '../client-data-types/Profile'
import { Topic } from '../client-data-types/topic'
import { PublicationCriticalInfo } from '../navigation/publication-route-types'
import { Block } from './blocks'
import { Classification } from './classification'
import { ImageSourceWithCaptions } from './image-item'

export interface BasePublication {
    _self: string
    id: string
    isLive?: boolean
    isSponsored: boolean
    slug: string
    source: string
    primaryTopic: Topic
    secondaryTopics: Topic[]
    publicationDate: string
    heroVideo?: HeroVideo
    lastUpdated?: string
    headKicker?: string
}

export interface UnknownPublication extends BasePublication {
    kind: 'unknown'
}

export type RequiredAccess = NonNullable<RequiredAccessDTO>

export interface BaseArticlePublication extends BasePublication {
    heading: string
    byline?: string
    profile?: Profile[]

    homepageHead: string | undefined
    homepageTeaser: string
    heroVideo?: HeroVideo
    heroImage?: ImageSourceWithCaptions
    requiredAccess: RequiredAccess
    allowCommenting: boolean
}

export interface ArticlePublication
    extends BaseArticlePublication,
        PublicationCriticalInfo {
    blogIsPresent?: boolean
    content: Block[]
    kind: 'article'

    canonicalUrl: string | undefined
    canonicalTitle: string | undefined

    hasOovvuuRecommendations: boolean
    excludeFeaturedVideo: boolean

    layoutView: 'default' | 'feature' | 'simple'
    classification?: Classification
    allowCommenting: boolean
    readTimeMinutes?: number | null
}

export interface RedirectPublication extends BaseArticlePublication {
    kind: 'redirect'
    redirectUrl: string
}

export interface GalleryPublication
    extends BasePublication,
        PublicationCriticalInfo {
    kind: 'gallery'
    heading: string
    heroImage: ImageSourceWithCaptions | undefined
    images: ImageSourceWithCaptions[]
    shareUrl: string
    byline?: string
    profile?: Profile[]
    publicationDate: string
    source: string
    requiredAccess: RequiredAccess
    content: Block[]
    allowCommenting: boolean
    classification?: Classification
    canonicalUrl: string | undefined
    canonicalTitle: string | undefined
}

export interface VideoPublication extends BaseArticlePublication {
    kind: 'video'
}

export interface EventPublication
    extends BaseArticlePublication,
        PublicationCriticalInfo {
    kind: 'event'
    content: Block[]

    byline?: string

    canonicalUrl: string | undefined
    canonicalTitle: string | undefined

    layoutView: 'default' | 'feature' | 'simple'
    classification?: Classification

    requiredAccess: RequiredAccess
    allowCommenting: boolean
    entries: {
        milestones: EventPostV4DTO[]
        stickies: EventPostV4DTO[]
        documents: EventPostV4DTO[]
        total: number
    }
    entryType?: string
    readTimeMinutes?: number | null
}

export type ArticleLikePublication = ArticlePublication | EventPublication

export type PublicationType =
    | ArticlePublication
    | RedirectPublication
    | UnknownPublication
    | GalleryPublication
    | EventPublication
    | VideoPublication

export function isArticleLikeType(
    publication: PublicationType,
): publication is ArticlePublication | EventPublication {
    return publication.kind === 'article' || publication.kind === 'event'
}

export function isArticle(
    publication: PublicationType,
): publication is ArticlePublication {
    return publication.kind === 'article'
}

export function isGallery(
    publication: PublicationType,
): publication is GalleryPublication {
    return publication.kind === 'gallery'
}

export function isRedirect(
    publication: PublicationType,
): publication is RedirectPublication {
    return publication.kind === 'redirect'
}

export function isUnknown(
    publication: PublicationType,
): publication is UnknownPublication {
    return publication.kind === 'unknown'
}

export function getAllPublicationTopics(publication: PublicationType): Topic[] {
    return [publication.primaryTopic, ...publication.secondaryTopics]
}
