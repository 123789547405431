import { GptLibrary } from '@news-mono/web-common'
import { Logger } from 'typescript-log'
import { getGrapeshotChannels } from './getGrapeshotChannels'

export async function setGrapeshotTargeting(
    cancelled: () => boolean,
    gpt: GptLibrary,
    log: Logger,
) {
    const categories = await getGrapeshotChannels(log, 500)
    // Cancel setting targeting if render key has changed since we made this call
    if (!cancelled()) {
        log.info(
            { categories },
            'GPT Api: Setting gs_cat for gpt pubads() using grapeshot categories',
        )

        // page level targeting across all slots
        gpt.pubads().setTargeting('gs_cat', categories)
    }
}
