import {
    adsDebug,
    GptAdSlotDefinition,
    GptApi,
    usePrevious,
} from '@news-mono/web-common'
import React from 'react'
import {
    addListener,
    removeListener,
    ScrollProps,
} from '../../__helpers/global-dom-events'
import { GptSlotManager } from './gpt-slot-manager'
import { GptSlotManagerContext } from './gpt-slot-manager-context'

let lazyLoadingAlgorithm = (velocity: number) => 300 + Math.abs(velocity)

/**
 * Change the lazy loading algorithm
 * Format: (scrolling velocity in px/s) => max distance to load ads
 * A negative distance can be used to not load the slot at all
 */
export function setLazyLoadingAlgorithm(
    algorithm: (velocity: number) => number,
) {
    lazyLoadingAlgorithm = algorithm
}

export interface AdProviderOptions {
    /** Key to tie ad loads together, pass null when page is not ready to render ads */
    renderKey: string | null

    /** Disable lazy loading and load all slots at once */
    disableLazyLoading?: boolean
}

export interface GptAdSlotRegistration {
    id: string
    disabled: boolean
    onSlotRenderEnded: (event: googletag.events.SlotRenderEndedEvent) => void
    getViewportPosition: () => number | undefined
}

export interface GptAdProviderProps extends AdProviderOptions {
    gptApi: GptApi
    slots: GptAdSlotDefinition[]

    options?: {
        requestIdleCallback?: typeof window.requestIdleCallback
    }
}

const gptAdProviderDebug = adsDebug.extend('gpt-ad-provider')

/**
 * NOTES
 *
 * Conceptually, this is the top level orchestrator.
 *
 * When ad slots render they register with the GptAdProvider, registering does nothing (ie, registered ads do not make ad calls)
 *
 * It then needs to make a single ad call for all ad slots which have been registered and also need to be displayed.
 *
 * When the page changes we need to force a correlator change on the ad call, the correlator allows ad units to coordinate which ads they load so
 * campaigns can still be loaded over different ad calls due to lazy loading
 */
export const GptAdProvider: React.FC<GptAdProviderProps> = ({
    gptApi,
    renderKey,
    slots,
    disableLazyLoading,
    children,
    options,
}) => {
    const gptSlotManager = React.useMemo(
        // We only want this to initialise once
        () => new GptSlotManager(gptApi, shouldDisplaySlot, renderKey, options),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )
    const scrollVelocity = React.useRef(0) // px/s
    const previousRenderKey = usePrevious(renderKey)

    // This is outside of the react lifecycle because there is no guarentee that
    // this components lifecycle will render before the ads which are the children
    if (previousRenderKey !== renderKey && renderKey !== null) {
        gptSlotManager.renderKeyChanged(renderKey)
    }

    React.useEffect(() => {
        function handleResize() {
            scrollVelocity.current = 0
            gptSlotManager.cleanupAndDisplaySlotsWhenIdle('resize')
        }

        function handleScroll({ velocity }: ScrollProps) {
            scrollVelocity.current = velocity
            gptSlotManager.cleanupAndDisplaySlotsWhenIdle('scroll')
        }
        addListener('scroll', handleScroll)
        addListener('resize', handleResize)
        addListener('scrollend', handleScroll)

        if (renderKey !== null) {
            gptSlotManager.defineSlots(renderKey, slots)
        }

        return () => {
            removeListener('scroll', handleScroll)
            removeListener('resize', handleResize)
            removeListener('scrollend', handleScroll)
        }
    }, [gptSlotManager, previousRenderKey, renderKey, slots])

    function shouldDisplaySlot(slot: GptAdSlotRegistration) {
        const { id, disabled } = slot
        const alreadyLoaded = !!gptApi.displayedSlots[id]
        const definition = gptSlotManager.getSlotDefinition(slot.id)

        if (disabled || alreadyLoaded || !definition) {
            return false
        }

        if (
            disableLazyLoading ||
            definition.disableLazyLoading ||
            definition.outOfPage
        ) {
            return true
        }

        const viewportPosition = slot.getViewportPosition()

        if (viewportPosition === undefined) {
            gptAdProviderDebug('Skipped loading ad unit, not visible: %o', {
                id: slot.id,
            })
            // not visible
            return false
        }

        const slotDistance = Math.abs(viewportPosition)
        let loadDistance =
            definition.lazyLoadingDistance !== undefined
                ? definition.lazyLoadingDistance
                : lazyLoadingAlgorithm(scrollVelocity.current)

        // if we are scrolling fast don't load in ads, to retain viewability
        // account for faster % scrolling on handhelds
        const isPortrait = window && window.innerHeight > window.innerWidth
        const minimumScreensAway = isPortrait ? 1 : 0.5
        if (
            window &&
            window.scrollY > 0 &&
            loadDistance > minimumScreensAway * window!.innerHeight
        ) {
            gptAdProviderDebug('turning ad display off, scrolling too fast', {
                'window.innerHeight': window.innerHeight,
                loadDistance,
                slotDistance,
                viewportPosition,
            })
            loadDistance = -1
        }

        // if loadDistance < 0, loading ads can be disabled completely
        // this can be used to disable loading ads when scrolling very fast
        const withinDistance = slotDistance <= loadDistance

        if (!withinDistance) {
            gptAdProviderDebug(
                'Skipped loading ad unit, too far from current viewport: %o',
                { withinDistance, slotDistance, loadDistance, id: slot.id },
            )
            return false
        }

        // only load ads in scroll direction
        const shouldDisplay =
            scrollVelocity.current >= 0
                ? viewportPosition >= 0
                : viewportPosition <= 0

        if (!shouldDisplay) {
            gptAdProviderDebug(
                'Skipped loading ad unit, too far from current viewport: %o',
                { viewportPosition, id: slot.id },
            )
        }
        gptAdProviderDebug('Displaying ad unit, meets conditions to load: %o', {
            withinDistance,
            slotDistance,
            loadDistance,
            id: slot.id,
            shouldDisplay,
            viewportPosition: viewportPosition,
        })

        return shouldDisplay
    }

    return (
        <GptSlotManagerContext.Provider value={gptSlotManager}>
            {React.Children.only(children)}
        </GptSlotManagerContext.Provider>
    )
}
GptAdProvider.displayName = 'GptAdProvider'
