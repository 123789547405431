import {Issue, useFeature} from '@news-mono/web-common'
import React, { RefObject } from 'react'
import { calcRem } from '../../../__styling'
import { ComputedPackageImage } from '../../../user-registration/breach/components/SubscriptionPackage/ComputedPackageImage'
import { InternalMastheadLogo } from '../../TheWestHeader'
import {
    StyledModal,
    StyledModalMain,
    StyledIconCrossSpanWrapper,
    StyledIconCross,
    StyledText,
    StyledSubscribeButton,
    StyledOutlinedButton,
} from './LatestEdition.styled'
import { TheWestSection } from '@news-mono/common'

export interface SubscriberModalProps {
    showModal: boolean
    setShowModal: (showModal: boolean) => void
    handleClickOutsideModal: (e: React.MouseEvent<HTMLDivElement>) => void
    innerModalRef: React.MutableRefObject<null>
    crossIconRef: RefObject<HTMLButtonElement>
    section: TheWestSection
    isLoggedIn: boolean
    onLoginClick: () => void
}

export const SubscriberModal: React.FC<SubscriberModalProps> = ({
    showModal,
    setShowModal,
    handleClickOutsideModal,
    innerModalRef,
    crossIconRef,
    section,
    isLoggedIn,
    onLoginClick,
}) => {
    const pricePoint = useFeature('default-price-increase')
    ? '$8 per week'
        : '$1 per day'
    return (
        <StyledModal showModal={showModal} onClick={handleClickOutsideModal}>
            <StyledModalMain className="modal-main" ref={innerModalRef}>
                <StyledSubscribeButton
                    onClick={() => {
                        setShowModal(false)
                        window.open('/editions/subscribe')
                    }}
                >
                    Subscribe from{' '}
                    <text style={{ textDecoration: 'underline' }}>
                        {pricePoint}
                    </text>
                </StyledSubscribeButton>

            </StyledModalMain>
        </StyledModal>
    )
}
