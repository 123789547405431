import { createRegisterableComponent, RouteStatus } from '@news-mono/web-common'
import React from 'react'
import { TheWestSomethingWentWrong } from '../../../errors/SomethingWentWrong/TheWestSomethingWentWrong/TheWestSomethingWentWrong'

export const TheWestSomethingWentWrongRegistration =
    createRegisterableComponent('west-500-page', () => (
        <RouteStatus statusCode={500}>
            <TheWestSomethingWentWrong />
        </RouteStatus>
    ))
