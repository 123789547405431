import { ThemeConfig } from '../../__styling/themes'
import {
    BreakpointState,
    calcRem,
    PerthNowTheme,
    PerthNowThemeConfig,
    SevenNewsTheme,
    SevenNewsThemeConfig,
    TheNightlyTheme,
    TheNightlyThemeConfig,
    TheWestTheme,
    TheWestThemeConfig,
} from '../../__styling'
import {
    FontWeightAbsolute,
    OverflowWrapProperty,
    TextDecorationProperty,
    TextTransformProperty,
    WordBreakProperty,
} from 'csstype'

// COLOR IS DELIBERATELY EXCLUDED FROM THIS!
// COLOR SHOULD BE GROUPED WITH THE CONTAINER'S BACKGROUND COLOR FOR CONTRAST REASONS!!!!!!
type TextMixin = {
    fontFamily: string
    fontSize: string
    lineHeight: string
    fontWeight: FontWeightAbsolute
    letterSpacing?: string
    textDecoration?: TextDecorationProperty<string>
    textTransform?: TextTransformProperty
    wordBreak?: WordBreakProperty
    overflowWrap?: OverflowWrapProperty
}

export type TextMixinTheme<T extends ThemeConfig> = Record<
    T['textType'],
    BreakpointState<T, TextMixin>
>

/** Refer to https://www.figma.com/file/gLOszYPbdR4A5D1y3wI1SR/%5BTWDS%5D-The-West-Design-System?type=design&node-id=203-5566&mode=dev */
export type TheWestTextTypes =
    | 'head-1'
    | 'head-2'
    | 'head-3'
    | 'head-4'
    | 'head-5'
    | 'head-6'
    | 'head-7'
    | 'head-8'
    | 'head-9'
    | 'bold/32'
    | 'bold/15'
    | 'bold/13'
    | 'semi-bold/24'
    | 'medium/23'
    | 'medium/14'
    | 'medium/13'
    | 'body'

/** Refer to https://www.figma.com/file/gLOszYPbdR4A5D1y3wI1SR/%5BTWDS%5D-The-West-Design-System?type=design&node-id=203-5566&mode=dev */
export const theWestTextMixinTheme = (scheme: TheWestTheme) => {
    const theme: TextMixinTheme<TheWestThemeConfig> = {
        'head-1': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'normal',
                fontSize: calcRem(39),
                lineHeight: calcRem(48),
            },
        },
        'head-2': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'normal',
                fontSize: calcRem(32),
                lineHeight: calcRem(40),
            },
        },
        'head-3': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'bold',
                fontSize: calcRem(22),
                lineHeight: calcRem(24),
            },
        },
        'head-4': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'normal',
                fontSize: calcRem(16),
                lineHeight: calcRem(18),
            },
        },
        'head-5': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'normal',
                fontSize: calcRem(16),
                lineHeight: calcRem(16),
                textTransform: 'uppercase',
            },
        },
        'head-6': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'bold',
                fontSize: calcRem(15),
                lineHeight: calcRem(16),
            },
        },
        'head-7': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'normal',
                fontSize: calcRem(14),
                lineHeight: calcRem(16),
            },
        },
        'head-8': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'bold',
                fontSize: calcRem(12),
                lineHeight: calcRem(16),
            },
        },
        'head-9': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'normal',
                fontSize: calcRem(12),
                lineHeight: calcRem(16),
            },
        },
        'bold/32': {
            default: {
                fontFamily: scheme.fonts.sansSerifCond,
                fontWeight: 'bold',
                fontSize: calcRem(32),
                lineHeight: calcRem(32),
                textTransform: 'uppercase',
            },
        },
        'bold/15': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'bold',
                fontSize: calcRem(15),
                lineHeight: calcRem(16),
            },
        },
        'bold/13': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'bold',
                fontSize: calcRem(13),
                lineHeight: calcRem(16),
            },
        },
        'semi-bold/24': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 600,
                fontSize: calcRem(24),
                lineHeight: calcRem(24),
            },
        },
        'medium/23': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 500,
                fontSize: calcRem(23),
                lineHeight: calcRem(23),
            },
        },
        'medium/14': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 500,
                fontSize: calcRem(14),
                lineHeight: calcRem(16),
            },
        },
        'medium/13': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 500,
                fontSize: calcRem(13),
                lineHeight: calcRem(16),
            },
        },
        body: {
            default: {
                fontFamily: scheme.fonts.body,
                fontWeight: 'normal',
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
            },
        },
    }

    return theme
}

/** Refer to https://www.figma.com/design/aVn9HftV7eysVaVGVROiWv/PerthNow-Design-System-2.0?node-id=506-9783&m=dev */
export type PerthNowTextTypes =
    | 'Heading/H1'
    | 'Heading/H2'
    | 'Heading/H3'
    | 'Heading/H4'
    | 'Heading/H5'
    | 'Heading/H6'
    | 'Heading/H7'
    | 'Label/L/Regular'
    | 'Label/L/Medium'
    | 'Label/L/Semibold'
    | 'Label/L/Bold'
    | 'Label/M/Regular'
    | 'Label/M/Medium'
    | 'Label/M/Semibold'
    | 'Label/M/Bold'
    | 'Label/S/Regular'
    | 'Label/S/Medium'
    | 'Label/S/Semibold'
    | 'Label/S/Bold'
    | 'Label/XS/Regular'
    | 'Label/XS/Medium'
    | 'Label/XS/Semibold'
    | 'Label/XS/Bold'
    | 'Paragraph/M/Serif/Regular'
    | 'Paragraph/M/Serif/Medium'
    | 'Paragraph/M/Serif/Bold'
    | 'Paragraph/M/Sans/Regular'
    | 'Paragraph/M/Sans/Medium'
    | 'Paragraph/M/Sans/Semibold'
    | 'Paragraph/M/Sans/Bold'

export const perthNowTextMixinTheme = function (scheme: PerthNowTheme) {
    const theme: TextMixinTheme<PerthNowThemeConfig> = {
        'Heading/H1': {
            default: {
                fontFamily: scheme.fonts.sansSerifCond,
                fontWeight: 'bold',
                fontSize: calcRem(42),
                lineHeight: calcRem(48),
            },
        },
        'Heading/H2': {
            default: {
                fontFamily: scheme.fonts.sansSerifCond,
                fontWeight: 'bold',
                fontSize: calcRem(36),
                lineHeight: calcRem(40),
            },
        },
        'Heading/H3': {
            default: {
                fontFamily: scheme.fonts.sansSerifCond,
                fontWeight: 'bold',
                fontSize: calcRem(30),
                lineHeight: calcRem(32),
            },
        },
        'Heading/H4': {
            default: {
                fontFamily: scheme.fonts.sansSerifCond,
                fontWeight: 'bold',
                fontSize: calcRem(26),
                lineHeight: calcRem(32),
            },
        },
        'Heading/H5': {
            default: {
                fontFamily: scheme.fonts.sansSerifCond,
                fontWeight: 'bold',
                fontSize: calcRem(24),
                lineHeight: calcRem(24),
            },
        },
        'Heading/H6': {
            default: {
                fontFamily: scheme.fonts.sansSerifCond,
                fontWeight: 'bold',
                fontSize: calcRem(22),
                lineHeight: calcRem(24),
            },
        },
        'Heading/H7': {
            default: {
                fontFamily: scheme.fonts.sansSerifCond,
                fontWeight: 'bold',
                fontSize: calcRem(14),
                lineHeight: calcRem(16),
            },
        },
        'Label/L/Regular': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'normal',
                fontSize: calcRem(16),
                lineHeight: calcRem(16),
            },
        },
        'Label/L/Medium': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 500,
                fontSize: calcRem(16),
                lineHeight: calcRem(16),
            },
        },
        'Label/L/Semibold': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 600,
                fontSize: calcRem(16),
                lineHeight: calcRem(16),
            },
        },
        'Label/L/Bold': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'bold',
                fontSize: calcRem(16),
                lineHeight: calcRem(16),
            },
        },
        'Label/M/Regular': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'normal',
                fontSize: calcRem(14),
                lineHeight: calcRem(16),
            },
        },
        'Label/M/Medium': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 500,
                fontSize: calcRem(14),
                lineHeight: calcRem(16),
            },
        },
        'Label/M/Semibold': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 600,
                fontSize: calcRem(14),
                lineHeight: calcRem(16),
            },
        },
        'Label/M/Bold': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'bold',
                fontSize: calcRem(14),
                lineHeight: calcRem(16),
            },
        },
        'Label/S/Regular': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'normal',
                fontSize: calcRem(13),
                lineHeight: calcRem(16),
            },
        },
        'Label/S/Medium': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 500,
                fontSize: calcRem(13),
                lineHeight: calcRem(16),
            },
        },
        'Label/S/Semibold': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 600,
                fontSize: calcRem(13),
                lineHeight: calcRem(16),
            },
        },
        'Label/S/Bold': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'bold',
                fontSize: calcRem(13),
                lineHeight: calcRem(16),
            },
        },
        'Label/XS/Regular': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'normal',
                fontSize: calcRem(12),
                lineHeight: calcRem(16),
            },
        },
        'Label/XS/Medium': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 500,
                fontSize: calcRem(12),
                lineHeight: calcRem(16),
            },
        },
        'Label/XS/Semibold': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 600,
                fontSize: calcRem(12),
                lineHeight: calcRem(16),
            },
        },
        'Label/XS/Bold': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'bold',
                fontSize: calcRem(12),
                lineHeight: calcRem(16),
            },
        },
        'Paragraph/M/Serif/Regular': {
            default: {
                fontFamily: scheme.fonts.body,
                fontWeight: 'normal',
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
            },
        },
        'Paragraph/M/Serif/Medium': {
            default: {
                fontFamily: scheme.fonts.body,
                fontWeight: 500,
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
            },
        },
        'Paragraph/M/Serif/Bold': {
            default: {
                fontFamily: scheme.fonts.body,
                fontWeight: 'bold',
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
            },
        },
        'Paragraph/M/Sans/Regular': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'normal',
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
            },
        },
        'Paragraph/M/Sans/Medium': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 500,
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
            },
        },
        'Paragraph/M/Sans/Semibold': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 600,
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
            },
        },
        'Paragraph/M/Sans/Bold': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'bold',
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
            },
        },
    }

    return theme
}

/** Source unknown. */
export type SevenNewsTextTypes = 'header'

/** Source unknown. */
export const sevenNewsTextMixinTheme = (scheme: SevenNewsTheme) => {
    const theme: TextMixinTheme<SevenNewsThemeConfig> = {
        header: {
            default: {
                fontFamily: scheme.fonts.sansSerif ?? scheme.fonts.heading,
                fontWeight: 600,
                fontSize: calcRem(20),
                lineHeight: calcRem(30),
            },
        },
    }
    return theme
}

/** Refer to https://www.figma.com/design/3veq2RFYCAfwUqTSUuHln8/PerthNow-Design-System?node-id=1738-178724&m=dev */
/** Remodeled in line with app-mono repo approach */
export const perthNowNGNTextMixin = {
    title: {
        '2XL': {
            regular: {
                fontSize: 48,
                lineHeight: 64,
                fontWeight: 'normal',
            },
            semibold: {
                fontSize: 48,
                lineHeight: 64,
                fontWeight: 600,
            },
            bold: {
                fontSize: 48,
                lineHeight: 64,
                fontWeight: 'bold',
            },
        },
        XL: {
            regular: {
                fontSize: 40,
                lineHeight: 56,
                fontWeight: 'normal',
            },
            semibold: {
                fontSize: 40,
                lineHeight: 56,
                fontWeight: 600,
            },
            bold: {
                fontSize: 40,
                lineHeight: 56,
                fontWeight: 'bold',
            },
        },
        L: {
            regular: {
                fontSize: 32,
                lineHeight: 40,
                fontWeight: 'normal',
            },
            semibold: {
                fontSize: 32,
                lineHeight: 40,
                fontWeight: 600,
            },
            bold: {
                fontSize: 32,
                lineHeight: 40,
                fontWeight: 'bold',
            },
        },
        M: {
            regular: {
                fontSize: 28,
                lineHeight: 36,
                fontWeight: 'normal',
            },
            semibold: {
                fontSize: 28,
                lineHeight: 36,
                fontWeight: 600,
            },
            bold: {
                fontSize: 28,
                lineHeight: 36,
                fontWeight: 'bold',
            },
        },
        S: {
            regular: {
                fontSize: 24,
                lineHeight: 32,
                fontWeight: 'normal',
            },
            semibold: {
                fontSize: 24,
                lineHeight: 32,
                fontWeight: 600,
            },
            bold: {
                fontSize: 24,
                lineHeight: 32,
                fontWeight: 'bold',
            },
        },
        XS: {
            regular: {
                fontSize: 20,
                lineHeight: 24,
                fontWeight: 'normal',
            },
            semibold: {
                fontSize: 20,
                lineHeight: 24,
                fontWeight: 600,
            },
            bold: {
                fontSize: 20,
                lineHeight: 24,
                fontWeight: 'bold',
            },
        },
        '2XS': {
            regular: {
                fontSize: 18,
                lineHeight: 24,
                fontWeight: 'normal',
            },
            semibold: {
                fontSize: 18,
                lineHeight: 24,
                fontWeight: 600,
            },
            bold: {
                fontSize: 18,
                lineHeight: 24,
                fontWeight: 'bold',
            },
        },
    },
    label: {
        L: {
            regular: {
                fontSize: 18,
                lineHeight: 20,
                fontWeight: 'normal',
            },
            medium: {
                fontSize: 18,
                lineHeight: 20,
                fontWeight: 500,
            },
            semibold: {
                fontSize: 18,
                lineHeight: 20,
                fontWeight: 600,
            },
            bold: {
                fontSize: 18,
                lineHeight: 20,
                fontWeight: 'bold',
            },
        },
        M: {
            regular: {
                fontSize: 16,
                lineHeight: 18,
                fontWeight: 'normal',
            },
            medium: {
                fontSize: 16,
                lineHeight: 18,
                fontWeight: 500,
            },
            semibold: {
                fontSize: 16,
                lineHeight: 18,
                fontWeight: 600,
            },
            bold: {
                fontSize: 16,
                lineHeight: 18,
                fontWeight: 'bold',
            },
        },
        S: {
            regular: {
                fontSize: 14,
                lineHeight: 16,
                fontWeight: 'normal',
            },
            medium: {
                fontSize: 14,
                lineHeight: 16,
                fontWeight: 500,
            },
            semibold: {
                fontSize: 14,
                lineHeight: 16,
                fontWeight: 600,
            },
            bold: {
                fontSize: 14,
                lineHeight: 16,
                fontWeight: 'bold',
            },
        },
        XS: {
            regular: {
                fontSize: 12,
                lineHeight: 16,
                fontWeight: 'normal',
            },
            medium: {
                fontSize: 12,
                lineHeight: 16,
                fontWeight: 500,
            },
            semibold: {
                fontSize: 12,
                lineHeight: 16,
                fontWeight: 600,
            },
            bold: {
                fontSize: 12,
                lineHeight: 16,
                fontWeight: 'bold',
            },
        },
        '2XS': {
            regular: {
                fontSize: 11,
                lineHeight: 16,
                fontWeight: 'normal',
            },
            medium: {
                fontSize: 11,
                lineHeight: 16,
                fontWeight: 500,
            },
            semibold: {
                fontSize: 11,
                lineHeight: 16,
                fontWeight: 600,
            },
            bold: {
                fontSize: 11,
                lineHeight: 16,
                fontWeight: 'bold',
            },
        },
    },
    body: {
        L: {
            regular: {
                fontSize: 18,
                lineHeight: 28,
                fontWeight: 'normal',
            },
            regularItalic: {
                fontSize: 18,
                lineHeight: 28,
                fontWeight: 'normal',
                fontStyle: 'italic',
            },
            medium: {
                fontSize: 18,
                lineHeight: 28,
                fontWeight: 500,
            },
            semibold: {
                fontSize: 18,
                lineHeight: 28,
                fontWeight: 600,
            },
            bold: {
                fontSize: 18,
                lineHeight: 28,
                fontWeight: 'bold',
            },
        },
        M: {
            regular: {
                fontSize: 16,
                lineHeight: 24,
                fontWeight: 'normal',
            },
            regularItalic: {
                fontSize: 16,
                lineHeight: 24,
                fontWeight: 'normal',
                fontStyle: 'italic',
            },
            medium: {
                fontSize: 16,
                lineHeight: 24,
                fontWeight: 500,
            },
            semibold: {
                fontSize: 16,
                lineHeight: 24,
                fontWeight: 600,
            },
            bold: {
                fontSize: 16,
                lineHeight: 24,
                fontWeight: 'bold',
            },
        },
        S: {
            regular: {
                fontSize: 14,
                lineHeight: 20,
                fontWeight: 'normal',
            },
            regularItalic: {
                fontSize: 14,
                lineHeight: 20,
                fontWeight: 'normal',
                fontStyle: 'italic',
            },
            medium: {
                fontSize: 14,
                lineHeight: 20,
                fontWeight: 500,
            },
            semibold: {
                fontSize: 14,
                lineHeight: 20,
                fontWeight: 600,
            },
            bold: {
                fontSize: 14,
                lineHeight: 20,
                fontWeight: 'bold',
            },
        },
    },
} as const

/** Refer to https://www.figma.com/file/5sdp8DaWM6vq6yqykGfVe1/The-Nightly-Design-System-(name-TBD)?type=design&node-id=6-404&mode=dev */
export type TheNightlyTextTypes =
    | 'section-header'
    | 'promo-header'
    | 'article-main'
    | 'article'
    | 'subheading'
    | 'item'
    | 'item-small'
    | 'item-small-light'
    | 'body-main'
    | 'body-alt'
    | 'link'
    | 'byline-primary'
    | 'byline-secondary'
    | 'button-label'
    | 'byline-primary-large'
    | 'menu-item'

/** Refer to https://www.figma.com/file/5sdp8DaWM6vq6yqykGfVe1/The-Nightly-Design-System-(name-TBD)?type=design&node-id=6-404&mode=dev */
export const theNightlyTextMixinTheme = (scheme: TheNightlyTheme) => {
    const theme: TextMixinTheme<TheNightlyThemeConfig> = {
        'body-main': {
            default: {
                fontFamily: scheme.fonts.body,
                fontWeight: 'normal',
                fontSize: calcRem(20),
                lineHeight: '150%',
                letterSpacing: '-0.32px',
            },
        },
        link: {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'normal',
                fontSize: calcRem(16),
                lineHeight: '150%',
                textDecoration: 'underline',
                letterSpacing: '-0.32px',
            },
        },
        'section-header': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'bold',
                fontSize: calcRem(40),
                lineHeight: calcRem(48),
                letterSpacing: '-1.6px',
            },
            sm: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'bold',
                fontSize: calcRem(45),
                lineHeight: calcRem(48),
                letterSpacing: '-1.8px',
            },
            lg: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'bold',
                fontSize: calcRem(51),
                lineHeight: calcRem(56),
                letterSpacing: '-2.04px',
            },
        },
        'promo-header': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'normal',
                fontSize: calcRem(32),
                lineHeight: calcRem(40),
            },
            sm: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'normal',
                fontSize: calcRem(36),
                lineHeight: calcRem(40),
            },
            lg: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'normal',
                fontSize: calcRem(40),
                lineHeight: calcRem(52),
            },
        },
        'article-main': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 600,
                fontSize: calcRem(40),
                lineHeight: calcRem(44),
                letterSpacing: '-1.2px',
            },
            sm: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 600,
                fontSize: calcRem(45),
                lineHeight: calcRem(48),
                letterSpacing: '-1.35px',
            },
            lg: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 600,
                fontSize: calcRem(40),
                lineHeight: calcRem(48),
                letterSpacing: '-1.53px',
            },
        },
        article: {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 600,
                fontSize: calcRem(32),
                lineHeight: calcRem(38.4),
                letterSpacing: '-0.48px',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
            },
            sm: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 600,
                fontSize: calcRem(36),
                lineHeight: calcRem(40),
            },
            lg: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 600,
                fontSize: calcRem(40),
                lineHeight: calcRem(48),
            },
        },
        subheading: {
            default: {
                fontFamily: scheme.fonts.subheading,
                fontWeight: 'bold',
                fontSize: calcRem(20),
                lineHeight: calcRem(24),
                letterSpacing: '-0.44px',
            },
            sm: {
                fontFamily: scheme.fonts.subheading,
                fontWeight: 'bold',
                fontSize: calcRem(22),
                lineHeight: calcRem(32),
                letterSpacing: '-0.484px',
            },
            lg: {
                fontFamily: scheme.fonts.subheading,
                fontWeight: 'bold',
                fontSize: calcRem(25),
                lineHeight: calcRem(32),
                letterSpacing: '-0.55px',
            },
        },
        item: {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'bold',
                fontSize: calcRem(20),
                lineHeight: calcRem(20),
                letterSpacing: '-0.44px',
            },
            sm: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'bold',
                fontSize: calcRem(22),
                lineHeight: calcRem(32),
                letterSpacing: '-0.484px',
            },
            lg: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 'bold',
                fontSize: calcRem(25),
                lineHeight: calcRem(32),
                letterSpacing: '-0.55px',
            },
        },
        'item-small': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 600,
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
                letterSpacing: '-0.32px',
            },
            sm: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 600,
                fontSize: calcRem(18),
                lineHeight: calcRem(24),
                letterSpacing: '-0.36px',
            },
            lg: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 600,
                fontSize: calcRem(20),
                lineHeight: calcRem(28),
                letterSpacing: '-0.4px',
            },
        },
        'item-small-light': {
            default: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 400,
                fontSize: calcRem(16),
                lineHeight: calcRem(20),
                letterSpacing: '-0.32px',
            },
            sm: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 400,
                fontSize: calcRem(18),
                lineHeight: calcRem(24),
                letterSpacing: '-0.36px',
            },
            lg: {
                fontFamily: scheme.fonts.heading,
                fontWeight: 400,
                fontSize: calcRem(20),
                lineHeight: calcRem(28),
                letterSpacing: '-0.4px',
            },
        },
        'body-alt': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'normal',
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
                letterSpacing: '-0.32px',
            },
        },
        'byline-primary': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 600,
                fontSize: calcRem(12),
                lineHeight: calcRem(16),
            },
        },
        'byline-secondary': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'normal',
                fontSize: calcRem(12),
                lineHeight: calcRem(16),
            },
        },
        'button-label': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 600,
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
                textTransform: 'uppercase',
            },
        },
        'byline-primary-large': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 500,
                fontSize: calcRem(16),
                lineHeight: calcRem(24),
            },
        },
        'menu-item': {
            default: {
                fontFamily: scheme.fonts.sansSerif,
                fontWeight: 'normal',
                fontSize: calcRem(20),
                lineHeight: calcRem(24),
            },
        },
    }
    return theme
}
