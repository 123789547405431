import { AuthConfigSection, ClientAuthConfigSection } from './authentication'
import { BaseClientConfig } from './client/BaseClientConfig'
import { CommonServerConfig } from './common-server-config'

// TODO We probably need 3 types of config, clientConfig, serverConfig then transferToClientConfig
// Unsure of exact naming, but this concept needs to be made really clear
export function getClientConfig(
    serverConfig: CommonServerConfig,
    useInternalContentApi: boolean,
    useInternalProfileApi?: boolean,
    isPerthNowAuth0Enabled?: boolean,
): BaseClientConfig {
    return {
        auth: getAuthClientConfig(serverConfig.auth),
        auth0: getAuthClientConfig(
            isPerthNowAuth0Enabled === true
                ? serverConfig.auth0PN
                : serverConfig.auth0,
        ),
        contentApi: useInternalContentApi
            ? serverConfig.internalContentApi
            : serverConfig.externalContentApi,
        profileApi: useInternalProfileApi
            ? serverConfig.internalProfileApi
            : serverConfig.externalProfileApi,
        publicUrl: serverConfig.publicUrl,
        publicHostname: serverConfig.publicHostname,
        akamaiImageManagerPolicy: serverConfig.akamaiImageManagerPolicy,
        baseAdUnitPath: serverConfig.baseAdUnitPath,
        baseAdUnits: serverConfig.baseAdUnits,
        launchDarklyClientId: serverConfig.launchDarklyClientId,
        launchDarklyClientTimeout: serverConfig.launchDarklyClientTimeout,
        iasClientId: serverConfig.iasClientId,
        oovvuuUrl: serverConfig.oovvuuUrl,
        oovvuuPath: serverConfig.oovvuuPath,
        snowPlowAppId: serverConfig.snowPlowAppId,
        snowPlowAppName: serverConfig.snowPlowAppName,
        moatVideoPartnerCode: serverConfig.moatVideoPartnerCode,
        brightcoveAccountId: serverConfig.brightcoveAccountId,
        subscribeTheWestUrl: serverConfig.subscribeTheWestUrl,
        packagePath: serverConfig.packagePath,
        integralPlacementLiveId: serverConfig.integralPlacementLiveId,
        integralPlacementVodId: serverConfig.integralPlacementVodId,
        integralAnId: serverConfig.integralAnId,
        parselyApikey: serverConfig.parselyApikey,
        prebidDebug: serverConfig.prebidDebug,
        prebidAppNexusId: serverConfig.prebidAppNexusId,
        prebidIxId: serverConfig.prebidIxId,
        sectionDomainsRedirect: serverConfig.sectionDomainsRedirect,
        hostnameRedirectsEnabled: serverConfig.hostnameRedirectsEnabled,
        ampGtmId: serverConfig.ampGtmId,
        ampgGaFourMeasureId: serverConfig.ampgGaFourMeasureId,
        gtmTrackingId: serverConfig.gtmTrackingId,
        nielsenAmpApid: serverConfig.nielsenAmpApid,
        nielsenApn: serverConfig.nielsenApn,
        nielsenSiteName: serverConfig.nielsenSiteName,
        contentfulStaticSiteEndpoint: serverConfig.contentfulStaticSiteEndpoint,
        firehoseTopicName: serverConfig.firehoseTopicName,
        userPollServiceEndpoint: serverConfig.userPollServiceEndpoint,
        emailServiceApi: serverConfig.emailServiceApi,
        emailServiceApiKey: serverConfig.emailServiceApiKey,
        noAuthEmailVerifyDecryptKey: serverConfig.noAuthEmailVerifyDecryptKey,
        apiCallerHeader: serverConfig.apiCallerHeader,
        sectionAdPathMapping: serverConfig.sectionAdPathMapping,
        cricketWidgetLink: serverConfig.cricketWidgetLink,
        theWestLiveStationId: serverConfig.theWestLiveStationId,
        coralUrl: serverConfig.coralUrl,
        chartbeatId: serverConfig.chartbeatId,
        recaptureSiteKey: serverConfig.recaptureSiteKey,
        sendgridEnv: serverConfig.sendgridEnv,
        datadogEnv: serverConfig.datadogEnv,
        statsPerformToken: serverConfig.statsPerformToken,
        nielsenApids: serverConfig.nielsenApids,
        websocketServiceEndpoint: serverConfig.websocketServiceEndpoint,
        scheduleWidgetURL: serverConfig.scheduleWidgetURL,
        contentful: serverConfig.contentful,
        googleLoginClientIds: serverConfig.googleLoginClientIds,
        smartOctoAmpId: serverConfig.smartOctoAmpId,
        smartOctoToken: serverConfig.smartOctoToken,
        datadogToken: serverConfig.datadogClientToken,
        skimlinksPublisherCode: serverConfig.skimlinksPublisherCode,
        salesforceBeacon: serverConfig.salesforceBeacon,
        appDownloadUrlApple: serverConfig.appDownloadUrlApple,
        appDownloadUrlGoogle: serverConfig.appDownloadUrlGoogle,
    }
}

const getAuthClientConfig = (
    serverConfig: AuthConfigSection | undefined,
): ClientAuthConfigSection | undefined => {
    if (!serverConfig) {
        return undefined
    }

    return {
        id: serverConfig.id,
        issuer: serverConfig.issuer,
        clientId: serverConfig.clientId,
        allowedIssuers: serverConfig.allowedIssuers,
        passEncryptionKey: serverConfig.passEncryptionKey,
    }
}
