import { useTheme } from '@emotion/react'
import {
    ConsentLevel,
    isServerEnvironment,
    Product,
    ScriptLoadResult,
    useScriptWithConsent,
} from '@news-mono/web-common'
import React from 'react'
import { useProduct } from '../../__product/useProduct'

let hasTaboolaLoaded = false

export const TaboolaContext = React.createContext<{
    result: ScriptLoadResult
}>({
    result: ScriptLoadResult.Failed,
})

const taboolaProductName = {
    sevennews: '7news',
    perthnow: 'perthnow',
    thewest: 'thewest',
    thenightly: 'thenightly',
}

export function getTaboolaPublisher(product: Product, section: string) {
    if (product === 'thewest' && section !== 'default') {
        return 'sevenwestmedia-network'
    } else if (section === 'community-news' || section === 'local-news') {
        return 'sevenwestmedia-cng'
    } else {
        return `sevenwestmedia-${taboolaProductName[product]}`
    }
}

export interface TaboolaPropsScript {
    articleId?: string
    ampPosition?: number
}

export const TaboolaScriptProvider: React.FC<TaboolaPropsScript> = ({
    articleId,
    children,
}) => {
    const product = useProduct()
    const { section } = useTheme()

    // Metadata setup

    let publisher: string
    if (isServerEnvironment() || !window.taboolaPublisher) {
        // Assumption: all of the sections on the west besides default are regionals
        publisher = getTaboolaPublisher(product, section)

        if (!isServerEnvironment()) {
            window.taboolaPublisher = publisher
        }
    } else {
        publisher = window.taboolaPublisher
    }

    // Script loading

    const [result, error] = useScriptWithConsent({
        consentRequiredLevel: ConsentLevel.Analytics | ConsentLevel.Advertising,

        consentMet: {
            src: `//cdn.taboola.com/libtrc/${publisher}/loader.js`,
        },
    })

    React.useEffect(() => {
        if (window._taboola === undefined) {
            window._taboola = []
            // window.performance.mark is not a function in tests and unsupported in older browsers
            if (
                process.env.NODE_ENV !== 'test' &&
                typeof window.performance?.mark === 'function'
            ) {
                window.performance.mark('tbl_ic')
            }
        }
    }, [])

    React.useEffect(() => {
        if (result === ScriptLoadResult.Success) {
            if (hasTaboolaLoaded) {
                window._taboola.push({ notify: 'newPageLoad' })
            } else {
                hasTaboolaLoaded = true
            }
        }
    }, [result, error, articleId])

    return (
        <TaboolaContext.Provider value={{ result }}>
            {children}
        </TaboolaContext.Provider>
    )
}
TaboolaScriptProvider.displayName = 'Taboola'

export default TaboolaScriptProvider
