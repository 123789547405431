import React from 'react'
import { CollectionRouteProps } from '../../collections'
import { useOptaWidgetStart } from '../../cricket-match-centre/useOptaWidgetStart'
import { useProduct } from '../../__product/useProduct'
import { ThemeMargins } from '../../__styling'
import {
    FixtureScoreboardSponsorLogo,
    FixturesScoreboardWidgetContainer,
    FixturesScoreboardWidgetLogo,
    FixturesScoreboardWidgetLogoBottom,
    FixturesScoreboardWidgetLogoTop,
    FixturesScoreboardWidgetWrapper,
} from './AflFixturesScoreboardWidget.styled'
import { ArrowIcon } from './assets/ArrowIcon'

const tabTouchLogo = require('./assets/TABtouch_transparent.png')
export interface AflFixturesScoreboardWidgetProps extends CollectionRouteProps {
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    isAflw?: boolean
}

export const AflFixturesScoreboardWidget = ({
    verticalGutters,
    verticalSpacing,
    isAflw,
}: AflFixturesScoreboardWidgetProps) => {
    useOptaWidgetStart()
    const product = useProduct()

    return (
        <FixturesScoreboardWidgetWrapper
            verticalSpacing={verticalSpacing}
            verticalGutters={verticalGutters}
        >
            <FixturesScoreboardWidgetLogo>
                <FixturesScoreboardWidgetLogoTop>
                    {isAflw ? 'AFLW' : 'AFL'}
                </FixturesScoreboardWidgetLogoTop>
                <FixturesScoreboardWidgetLogoBottom
                    to={`/sport/${isAflw ? 'aflw' : 'afl'}/fixtures`}
                >
                    {product === 'sevennews' && 'All '}
                    Fixtures <ArrowIcon />
                </FixturesScoreboardWidgetLogoBottom>
                {product !== 'sevennews' && (
                    <FixtureScoreboardSponsorLogo
                        src={tabTouchLogo}
                        width={102}
                    />
                )}
            </FixturesScoreboardWidgetLogo>
            <FixturesScoreboardWidgetContainer>
                <opta-widget
                    widget="fixtures"
                    template="strip"
                    live={isAflw ? 'true' : 'false'}
                    competition={isAflw ? '12296' : '12425'}
                    season={isAflw ? '2023' : '2024'}
                    match_status="all"
                    start_on_current="true"
                    order_by="date_ascending"
                    show_date="true"
                    show_crests={product === 'sevennews'}
                    date_format="ll"
                    time_format="HH:mm"
                    competition_naming="full"
                    team_naming="full"
                    pre_match={'true'}
                    match_link={`/sport/${
                        isAflw ? 'aflw' : 'afl'
                    }/match-centre`}
                    show_live={isAflw ? 'true' : 'false'}
                    show_logo="false"
                    show_title="false"
                    sport="afl"
                ></opta-widget>
            </FixturesScoreboardWidgetContainer>
        </FixturesScoreboardWidgetWrapper>
    )
}
