import createCache, { EmotionCache } from '@emotion/cache'
import { CacheProvider, ThemeProvider } from '@emotion/react'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { loadableReady } from '@loadable/component'
import {
    AllEvents,
    AllEventTypes,
    AppState,
    BaseClientConfig,
    ConfigurationContext,
    configureStore,
    ConsentLevel,
    createEventObserver,
    DataLayerEventName,
    DataLoaderGlobalParams,
    getABTestingFeatures,
    GptApi,
    hasConsentLevel,
    IRouteCache,
    IsHydrating,
    isIE,
    loadFeatures,
    PageEventContext,
    Product,
    RenderTargetContext,
    resources,
    RouteCacheContext,
    RouteCachePerRoute,
    setImageManagerPolicy,
    setupAuthRefreshCallback,
    Store,
    TogglesReduxState,
    UpdateRouteCachePerLocation,
    useConsentState,
} from '@news-mono/web-common'
import { WatchtowerBrowserRouter } from '@project-watchtower/runtime'
import { get as getCookie } from 'js-cookie'
import {
    PageLifecycleProvider,
    PageLifecycleProviderRenderProps,
} from 'page-lifecycle-provider'
import pino from 'pino'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { DataLoaderState, DataProvider } from 'react-ssr-data-loader'
import {
    BeginLoadingEvent,
    EndLoadingEvent,
} from 'react-ssr-data-loader/dist/cjs/events'
import { Logger } from 'typescript-log'
import { datadogInitialise } from '../../../web-common/src/datadog-initialise'
import { cleanupBonzaiSkins } from '../advertising/bonzai/cleanup-bonzai'
import { loadGptLibrary } from '../advertising/gpt/load-gpt-library'
import { adBlockEnabled } from '../advertising/gpt/utils'
import { setGrapeshotTargeting } from '../advertising/grapeshot/set-grapeshot-targeting'
import { waitUntilMoatYieldIntelligenceIsLoadedForPage } from '../advertising/moat/load-moat-yield-intelligence'
import { setMoatTargetingOnSlots } from '../advertising/moat/set-moat-slot-targeting'
import { LoggerContext } from '../diagnostics/LoggerContext'
import {
    get7NewsDcrMeta,
    getLegacy7NewsDcrMeta,
    getWANDcrMeta,
} from '../tracking'
import { createEventBuffer } from '../tracking/gtm/event-buffer'
import { createSpaInitialisedEvent } from '../tracking/gtm/events'
import { toGtmShape } from '../user-registration/user-analytics/user-analytics'
import {
    handleDataProviderEvent,
    handlePageEvents,
} from '../__App/page-events-handler'
import { Theme } from '../__styling/themes'
import { EventQueue } from './event-queue'

let routeCacheRef: IRouteCache
declare const config: BaseClientConfig
declare const INITIAL_STATE: AppState
declare let PAGE_DATA: DataLoaderState | undefined

export interface AppProps {
    hostname: string | undefined
    protocol: string | undefined
    services: DataLoaderGlobalParams
    onEvent: (event: AllEvents | BeginLoadingEvent | EndLoadingEvent) => void
    gptApi: GptApi
}

const initialState =
    typeof INITIAL_STATE !== 'undefined' ? INITIAL_STATE : undefined
const dataLoaderState = typeof PAGE_DATA !== 'undefined' ? PAGE_DATA : undefined

type AppComponent = React.FC<AppProps>

let cache: EmotionCache
const log = pino({
    level: 'trace',
    prettyPrint: false,
})

const routeCache = new RouteCachePerRoute(log)
export const eventQueue = new EventQueue(log)

export const modifyToggleState = (
    modifiedState: TogglesReduxState,
    isOverrideEnabled: boolean,
    cookieValue: any,
) => {
    if (isOverrideEnabled) {
        if (cookieValue) {
            const override_value = JSON.parse(
                cookieValue,
            ) as Partial<TogglesReduxState>

            log.info(
                {
                    modifiedState,
                    override_value,
                },
                'Overriding Feature Toggles',
            )

            if (override_value) {
                return {
                    ...modifiedState,
                    ...override_value,
                }
            }
        }
    }
    return modifiedState
}

export function initClient({
    version,
    beforeInitialised,
    applicationInitialised,
    theme,
    requireApplication,
}: {
    version: { sha: string; buildNumber: string }
    beforeInitialised?: (services: {
        store: Store
        config: BaseClientConfig
        log: Logger
        onEvent: (event: AllEvents) => void
    }) => Promise<void>
    applicationInitialised?: (services: {
        store: Store
        config: BaseClientConfig
    }) => void
    theme: Theme
    requireApplication: () => AppComponent
}) {
    cache = createCache({ key: 'css' })
    cache.compat = true

    setImageManagerPolicy(config.akamaiImageManagerPolicy)
    // Clear state so it can't be picked up by data load
    PAGE_DATA = undefined

    // Use a feature toggle to control this behaviour, just in case.

    const modifiedState: AppState | undefined =
        initialState && initialState.toggles['feature-toggle-override']
            ? {
                  ...initialState,
                  toggles: modifyToggleState(
                      initialState.toggles,
                      initialState.toggles[
                          'feature-toggle-override'
                      ] as boolean,
                      getCookie('feature_override'),
                  ),
              }
            : initialState

    const store = configureStore(config, {
        initialState: modifiedState,
        syncState: true,
    })

    if (window.location.search.indexOf('ad_test_debug') !== -1) {
        store.dispatch(loadFeatures({ 'debug-ads': true }))
    }
    let currentPageProps: any = {}

    datadogInitialise(config, store.getState().toggles)

    const handlePageLifecycleEvents = (event: AllEvents) => {
        const state = store.getState()
        if (
            event.type === 'page-load-started' ||
            event.type === 'page-load-complete' ||
            event.type === 'page-load-failed'
        ) {
            const { location, ...props } = event.payload

            /**
             * DPO-793: Maps and injects auth state to datalayer
             */
            const authState = state.authentication
            const mappedAuthState = toGtmShape({
                entitlements: authState.entitlements,
                wanUserId: authState.wanUserId,
                auth0UserId: authState.auth0UserId,
                isLoggedIn: authState.isLoggedIn,
                loginProvider: authState.loginProvider,
                occupantId: authState.occupantId,
                socialProviders: authState.socialProviders,
                subscriptionId: authState.subscriptionId,
                subscriptionType: authState.subscriptionType,
            })

            currentPageProps = { ...props, ...mappedAuthState }
        }

        const aBTestingFeatures = getABTestingFeatures({
            toggles: state.toggles,
            anonymousUserId: state.authentication?.anonymousUserIdentifier,
        })
        const toggleState = toFeatureState({
            ...state.toggles,
            ...aBTestingFeatures,
        })

        // DCR meta as follows if WAN product (!7news) then getWANDcrMeta otherwise its either legacy or new 7news dcr meta
        const getDcrMeta =
            theme.kind !== Product.SevenNews
                ? getWANDcrMeta
                : isFeatureEnabled(toggleState, 'nielsen-subbrand')
                ? get7NewsDcrMeta
                : getLegacy7NewsDcrMeta

        // currentPageProps is not available on the types, but contains the
        // current page variables. Because page completed is buffered it contains old
        // page variables, this allows the events to use the current values instead
        handlePageEvents({
            event,
            toggleState,
            actualPageVariables: currentPageProps,
            nielsenSiteName: config.nielsenSiteName,
            getDcrMeta,
            consentState: state.consent,
            publicHostname: config.publicHostname,
            chartbeatId: config.chartbeatId,
            locationState: state.geoLocation,
            authState: state.authentication,
            aBTestingFeatureVariables: aBTestingFeatures,
        })
    }

    const { subscribe, broadcast } = createEventObserver<AllEventTypes>()

    const gptApi = new GptApi({
        loadGptLibrary: () => loadGptLibrary(store.getState()),
        setAdditionalPageTargeting: ({ cancelled, gptLibrary }) => {
            const toggles = toFeatureState(store.getState().toggles)
            const promises = []

            if (isFeatureEnabled(toggles, 'grapeshot')) {
                promises.push(setGrapeshotTargeting(cancelled, gptLibrary, log))
            }
            if (isFeatureEnabled(toggles, 'moat-yield-intelligence')) {
                promises.push(
                    waitUntilMoatYieldIntelligenceIsLoadedForPage(
                        cancelled,
                        log,
                    ),
                )
            }

            return Promise.all(promises).then(() => {})
        },
        setAdditionalSlotTargeting: ({ gptSlots }) => {
            const toggles = toFeatureState(store.getState().toggles)

            if (isFeatureEnabled(toggles, 'moat-yield-intelligence')) {
                setMoatTargetingOnSlots(gptSlots, log)
            }
        },
        performAdditionalCleanup: (gptApi) => {
            cleanupBonzaiSkins(gptApi.displayedSlots, gptApi.mediaQueries)
        },
        enableCompanionAds: isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'companion-ads',
        ),
        isMagniteEnabeled: isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'magnite-header-tag-wrapper',
        ),
        logger: log,
    })

    const fireEvent = createEventBuffer<AllEvents>((event) => {
        broadcast(event.type)
        return handlePageLifecycleEvents(event)
    })
    let isInitialRender = true

    const renderTarget = store.getState().render.renditionType

    if (renderTarget === 'app') {
        // Setup the smedia global callbacks
        setupAuthRefreshCallback(store.dispatch, fireEvent, log)
    }

    const render = () =>
        new Promise<void>((resolve) => {
            const container = document.getElementById('root')
            let renderFn = ReactDOM.hydrate
            // Add ?debug_ssr_mismatch
            if (
                process.env.NODE_ENV !== 'production' &&
                window.location.search.indexOf('debug_ssr_mismatch') !== -1 &&
                isInitialRender &&
                container
            ) {
                console.log('Server-Render Result')
                console.log(container.innerHTML)
                container.innerHTML = ''
                renderFn = ReactDOM.render
            }

            const currentIsInitialRender = isInitialRender

            const App = requireApplication()
            renderFn(
                <CacheProvider value={cache}>
                    <Provider store={store}>
                        <IsHydrating.Provider value={isInitialRender}>
                            <LoggerContext.Provider value={log}>
                                <ConfigurationContext.Provider value={config}>
                                    <ThemeProvider theme={theme}>
                                        <RenderTargetContext.Provider
                                            value={{
                                                renderTarget,
                                                registerWork: () => {},
                                                getWorkResult: () => {},
                                                extensionMounted: () => {},
                                            }}
                                        >
                                            <PageEventContext.Provider
                                                value={subscribe}
                                            >
                                                <ClientApplication
                                                    store={store}
                                                    theme={theme}
                                                    fireEvent={fireEvent}
                                                    App={App}
                                                    gptApi={gptApi}
                                                    appVersion={version.sha}
                                                />
                                            </PageEventContext.Provider>
                                        </RenderTargetContext.Provider>
                                    </ThemeProvider>
                                </ConfigurationContext.Provider>
                            </LoggerContext.Provider>
                        </IsHydrating.Provider>
                    </Provider>
                </CacheProvider>,
                container,
                () => {
                    if (
                        process.env.NODE_ENV !== 'production' &&
                        window.location.search.indexOf('debug_ssr_mismatch') !==
                            -1 &&
                        currentIsInitialRender
                    ) {
                        console.log('Client-Render Result')
                        console.log(container!.innerHTML)
                    }
                    resolve()
                },
            )
            isInitialRender = false
        })

    // This tells @loadable/components to preload the loadable components that were included on the page.
    loadableReady()
        // Once that is done, we are safe to hydrate the client!
        .then(() => render())
        .then(() => {
            // Once hydrated, we should re-render to kick-off any new client
            // only rendering
            return render()
        })
        .then(async () => {
            // This hook allows mastheads to have async initialization
            if (beforeInitialised) {
                await beforeInitialised({
                    config,
                    store,
                    onEvent: fireEvent,
                    log,
                })
            }

            const toggles = store.getState().toggles
            fireEvent(createSpaInitialisedEvent(toggles))

            // Addition of an adBlockEnabled property to window. This can be used for
            // accessing by scripts such as TrackJS which need to be able to mute
            // errors when an AdBlocker is enabled.
            window.adBlockEnabled = adBlockEnabled()
        })
        .then(() => {
            if (applicationInitialised) {
                applicationInitialised({ config, store })
            }
        })

    return { render, routeCacheRef }
}

const ClientApplication: React.FC<{
    store: Store
    theme: Theme
    App: AppComponent
    gptApi: GptApi
    fireEvent: (event: AllEvents) => void
    appVersion: string
}> = ({ store, fireEvent, App, gptApi, appVersion }) => {
    // We need to fire the current consent level event so GTM knows what mode to load in
    const { consentLevel } = useConsentState()
    const consent = consentLevel || ConsentLevel.None

    fireEvent({
        type: DataLayerEventName.cookieConsent,
        originator: 'ClientApplication',
        payload: {
            permissionAdvertising: hasConsentLevel(
                consent,
                ConsentLevel.Advertising,
            ),
            permissionAnalytics: hasConsentLevel(
                consent,
                ConsentLevel.Analytics,
            ),
            permissionEssential: hasConsentLevel(
                consent,
                ConsentLevel.Essential,
            ),
        },
    })

    return (
        <WatchtowerBrowserRouter forceRefresh={isIE(navigator)}>
            <PageLifecycleProvider onEvent={fireEvent}>
                {(pageProps) => {
                    return renderPage(
                        pageProps,
                        store,
                        App,
                        gptApi,
                        fireEvent,
                        appVersion,
                    )
                }}
            </PageLifecycleProvider>
        </WatchtowerBrowserRouter>
    )
}

function renderPage(
    pageProps: PageLifecycleProviderRenderProps,
    store: Store,
    App: AppComponent,
    gptApi: GptApi,
    fireEvent: (event: AllEvents) => void,
    appVersion: string,
) {
    // if page is loaded via Google webcache, prevent re-rendering and display whatever Google is returning
    if (window.location.host.includes('webcache.googleusercontent')) {
        return (
            <div
                dangerouslySetInnerHTML={{ __html: '' }}
                suppressHydrationWarning
            />
        )
    }

    const additionalProps: DataLoaderGlobalParams = {
        log,
        routeCache,
        config,
        store,
        appVersion,
    }

    const handleDataEvent = handleDataProviderEvent(pageProps, log)

    return (
        <RouteCacheContext.Provider value={routeCache}>
            <UpdateRouteCachePerLocation pageData={dataLoaderState} />
            <DataProvider
                initialState={dataLoaderState}
                isServerSideRender={false}
                resources={resources}
                globalProps={additionalProps}
                onEvent={handleDataEvent}
            >
                <App
                    hostname={window.location.host}
                    protocol={window.location.protocol}
                    onEvent={(event) => {
                        if (
                            event.type === 'begin-loading-event' ||
                            event.type === 'end-loading-event'
                        ) {
                            handleDataEvent(event)
                        } else {
                            fireEvent(event)
                        }
                    }}
                    services={additionalProps}
                    gptApi={gptApi}
                />
            </DataProvider>
        </RouteCacheContext.Provider>
    )
}
