import {
    AdDefinition,
    AdState,
    AllEvents,
    AppState,
    createRouteServices,
    DataLoaderGlobalParams,
    getRegisteredRoutes,
    getRouteForResolution,
    getSiteSection,
    GptApi,
    PageError,
    RenderTarget,
    RouteInfoResolverFailedEvent,
    RouteMetaPageTypes,
    RouteResolution,
    TogglesReduxState,
    useRoute,
} from '@news-mono/web-common'
import H from 'history'
import { LayoutApi } from 'json-react-layouts'
import React from 'react'
import { connect } from 'react-redux'
import { renderRouteInfo } from '../../routing/render-route-info'
import { RedirectRenderer } from '../../__App/component-rendering/RedirectRenderer'

export interface PageResolverProps {
    hostname: string | undefined
    protocol: string | undefined
    location: H.Location
    services: DataLoaderGlobalParams
    layout: LayoutApi<any, any, any, any, any>
    /** Ad definitions for ads which are present on all pages (and defined outside route info) */
    siteAds: AdDefinition[]
    gptApi: GptApi
    renderTarget: RenderTarget

    renderPage: (
        pageContents: React.ReactElement<any>,
        ads: AdState | undefined,
        pageType: RouteMetaPageTypes,
        section: string,
        additionalPageProperties: {
            [key: string]: any
        },
    ) => React.ReactElement<any>
    onEvent: (event: AllEvents) => void

    refreshOverride?: () => void
}

interface State {
    error?: PageError
    prevLocation?: H.Location
}

export const PageResolver = connect((state: AppState) => ({
    toggles: state.toggles,
    westLive: state.theWestLive,
}))(
    class PageResolver extends React.Component<
        PageResolverProps & { toggles: TogglesReduxState },
        State
    > {
        state: State = {}

        static getDerivedStateFromProps(
            props: PageResolverProps,
            state: State,
        ): Partial<State> {
            return {
                // If location changed, clear any errors we have
                error:
                    state.prevLocation === props.location
                        ? state.error
                        : undefined,
                prevLocation: props.location,
            }
        }

        static getDerivedStateFromError(err: Error): Partial<State> {
            if (err.message === '404') {
                return { error: { errorType: '404' } }
            }
            const error: PageError = { errorType: 'PageRendererFailed' }
            return { error }
        }

        componentDidCatch(err: Error, info: React.ErrorInfo) {
            const {
                services: { log },
            } = this.props
            // The data loader formats the message, so it looks like this:

            if (err.message === '404') {
                return
            }

            const message = `Failed to render page at location ${this.props.location.pathname}`
            log.error({ err, info }, message)
            const error: PageError = { errorType: 'PageRendererFailed' }
            // raise the event
            this.props.onEvent({
                type: 'page-render.failed',
                originator: 'PageRenderer',
                payload: {
                    error,
                    message,
                },
            })
        }

        handleRouteInfoFailure = (event: RouteInfoResolverFailedEvent) => {
            this.setState({
                error: event.payload.error,
            })
            this.props.onEvent(event)
        }

        render() {
            const props = this.props
            const errorThrown = this.state.error

            const meta = props.services.store.getState().meta
            const { section } = getSiteSection(
                props.location.pathname,
                props.hostname,
                meta.hostnameToSectionLookup,
                meta.sectionMeta,
            )

            // Handle errors from this error boundary
            if (errorThrown && errorThrown.errorType === '404') {
                props.services.log.info({ section }, `Rendering 404 page`)
                const { notFoundRouteInfo } = getRegisteredRoutes()
                const routeServices = createRouteServices(
                    props.services,
                    {
                        type: 'error',
                        error: { errorType: '404' },
                        section,
                    },
                    props.location,
                    props.hostname,
                    props.protocol,
                    props.renderTarget,
                    props.gptApi,
                )
                return renderRouteInfo(
                    routeServices,
                    notFoundRouteInfo(routeServices),
                    this.props,
                )
            } else if (errorThrown) {
                props.services.log.info({ section }, `Rendering error page`)
                const { errorRouteInfo } = getRegisteredRoutes()
                const routeServices = createRouteServices(
                    props.services,
                    {
                        type: 'error',
                        error: errorThrown,
                        section,
                    },
                    props.location,
                    props.hostname,
                    props.protocol,
                    props.renderTarget,
                    props.gptApi,
                )
                return renderRouteInfo(
                    routeServices,
                    errorRouteInfo(routeServices),
                    this.props,
                )
            }

            return <RouteResolver {...props} section={section} />
        }
    },
)

export function RouteResolver(
    props: PageResolverProps & { section: string | undefined },
) {
    const {
        hostname,
        location,
        services,
        section,
        protocol,
        renderTarget,
        gptApi,
    } = props
    const renderProps = useRoute(
        {
            hostname,
            path: location.pathname,
            search: location.search,
            services,
        },
        {},
    )

    const routeResolution: RouteResolution = renderProps.data.hasData
        ? renderProps.data.result
        : // TODO Route hints
          {
              type: 'loading',
              section,
          }

    const routeServices = createRouteServices(
        services,
        routeResolution,
        location,
        hostname,
        protocol,
        renderTarget,
        gptApi,
    )
    const route = getRouteForResolution(routeServices)

    if (route && route.kind === 'redirect') {
        return <RedirectRenderer log={services.log} routeInfo={route} />
    }

    return renderRouteInfo(routeServices, route, props)
}
