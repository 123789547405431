import {
    AppState,
    AuthenticationState,
    THEWEST_ALL,
} from '@news-mono/web-common'
import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
    BreachContext,
    BreachScreenContext,
} from '../../../../web-common/src/context-providers/BreachContextProvider'
import { BreachWallContentType } from './helpers/BreachWallTypes'

export type PackageTheme =
    | 'default'
    | 'red'
    | 'yellow'
    | '190year'
    | 'abtest'
    | 'abtest2'
    | 'vanishing-cousins'
    | 'west-blue'

export type PackageType = 'digital' | 'digitalprint'

export interface ISubscriptionPackage {
    pretitle: string
    originalPrice?: string
    heading: string
    price: string
    priceQualifier?: string
    ribbonTitle?: string
    ribbonOptions?: 'newDesignTag' | 'cornerDesignTag' | 'oldDesignTag'
    packagePath: string
    packageType?: PackageType
    packageTheme?: PackageTheme
    cancellationMessage?: string
    googleSKU?: string
    // Properties for ga4 event - ACQ-17
    rateCode: string
    weeklyPrice: number
    promoPrice?: number
    discount?: number
    offerCode?: string
    benefitOverride?: string[]
}

export type SubscriptionPackages = ISubscriptionPackage[]

// Check if the passed package is a subscription package
export const isSubscriptionPackage = (obj: any): boolean => {
    return (
        typeof obj !== 'undefined' &&
        Object.entries(breachPackages).filter((entry) => entry[1].includes(obj))
            .length > 0
    )
}

// Everyday Digital
export const WEEKLY_DIGITAL_RATE_CODE = 'WST_DIGI_DD'
export const WEEKLY_DIGITAL_RATE_CODE_INCREASE = 'WST_DIGI_8'

// Weekend Papers & Everyday Digital
export const WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE = 'WST_PrSS+DIGI_DD'
export const WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE_INCREASE = 'WST_PrSS+WST_PrSS+DIGI_$10'
export const FIFTY_OFF_WE_PAPER_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE =
    'WST_SS+DIG4.50X8W_DD'
// 50% Off Everyday Digital - 12 weeks
export const FIFTY_OFF_EVERYDAY_DIGITAL_12_WEEKS_RATE_CODE =
    'WST_DIGI$3.50x12_DD'
// 50% Off Friday - Monday & Everyday Digital - 12 weeks
export const FIFTY_OFF_FRI_MON_PAPER_AND_DIGITAL_12_WEEKS_RATE_CODE =
    'WST-M---FSS+DIG_6.5'
// 30% Off Everyday Digital - 8 weeks
const THIRTY_OFF_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE = 'WST_DIGI$4.90x8_DD'
// 50% Off Everyday Digital - 8 weeks
const FIFTY_OFF_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE = 'WST_DIGI_$3.50X8W'
const SPORT_BREACH_DIGITAL_PRINT_RATE_CODE = 'WST-M--TFSS+D7.50_DD'

// COUPON
export const BASE_50_DEAL_OFFERCODE = 'DIGDIS21'

// PRICES
export const EVERYDAY_DIGITAL = 7
export const EVERYDAY_DIGITAL_INCREASE = 8
export const WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL = 9
export const WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL_INCREASE = 10
export const FRI_TO_MON_PAPERS_AND_EVERYDAY_DIGITAL = 13

export const FIFTY_OFF_EVERYDAY_DIGITAL = EVERYDAY_DIGITAL * 0.5
export const FIFTY_OFF_WE_PAPERS_AND_EVERYDAY_DIGITAL =
    WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL * 0.5
const FIFTY_OFF_FRI_TO_MON_PAPERS_AND_EVERYDAY_DIGITAL =
    FRI_TO_MON_PAPERS_AND_EVERYDAY_DIGITAL * 0.5
const THIRTY_OFF_EVERYDAY_DIGITAL = EVERYDAY_DIGITAL * 0.7
const THIRTY_OFF_WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL =
    WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL * 0.7

const base50percentDiscountPackage: ISubscriptionPackage[] = [
    {
        packageTheme: 'red',
        packagePath: 'subscribe/new',
        pretitle: 'Digital',
        heading: 'Everyday Digital',
        price: '50c per day',
        originalPrice: '$1 per day',
        priceQualifier: 'for 8 weeks*',
        packageType: 'digital',
        ribbonTitle: '50% off',
        cancellationMessage: 'Cancel anytime. Min cost $14.',
        rateCode: 'WST_DIGI_$3.50X8W',
        weeklyPrice: EVERYDAY_DIGITAL,
        promoPrice: FIFTY_OFF_EVERYDAY_DIGITAL,
        discount: EVERYDAY_DIGITAL - FIFTY_OFF_EVERYDAY_DIGITAL,
        offerCode: BASE_50_DEAL_OFFERCODE,
    },
    {
        pretitle: 'Digital & Print',
        heading: 'Weekend Papers + Everyday Digital',
        price: '$9 per week',
        packagePath: 'subscribenow/sat-sun+digital',
        packageType: 'digitalprint',
        cancellationMessage: 'Cancel anytime. Min cost $36.',
        weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
        rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
    },
]

export const breachPackages: Record<
    BreachWallContentType,
    ISubscriptionPackage[]
> = {
    default: [
        {
            packagePath: 'subscribe/new',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$1 per day',
            packageType: 'digital',
            cancellationMessage: 'Cancel anytime. Min cost $28.',
            googleSKU: 'everyday_digital_4weeks',
            weeklyPrice: EVERYDAY_DIGITAL,
            rateCode: WEEKLY_DIGITAL_RATE_CODE,
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. Min cost $36.',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    Puzzles: [
        {
            packagePath: 'subscribe/new',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$1 per day',
            packageType: 'digital',
            cancellationMessage: 'Cancel anytime. Min cost $28.',
            googleSKU: 'everyday_digital_4weeks_8',
            weeklyPrice: EVERYDAY_DIGITAL,
            rateCode: WEEKLY_DIGITAL_RATE_CODE,
        },
    ],
    'Puzzles-increase': [
        {
            packagePath: 'subscribe/new',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$8 per week',
            packageType: 'digital',
            cancellationMessage: 'Cancel anytime. Min cost $32.',
            googleSKU: 'everyday_digital_4weeks_8',
            weeklyPrice: EVERYDAY_DIGITAL_INCREASE,
            rateCode: WEEKLY_DIGITAL_RATE_CODE_INCREASE,
        },
    ],
    'default-price-increase': [
        {
            packagePath: 'subscribe/new',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$8 per week',
            packageType: 'digital',
            cancellationMessage: 'Cancel anytime. Min cost $32.',
            googleSKU: 'everyday_digital_4weeks_8',
            weeklyPrice: EVERYDAY_DIGITAL_INCREASE,
            rateCode: WEEKLY_DIGITAL_RATE_CODE_INCREASE,
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$10 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. Min cost $40.',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL_INCREASE,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE_INCREASE,
        },
    ],
    'puzzles-swg-30-off': [
        {
            packagePath: 'subscribe/new',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '70c per day',
            ribbonTitle: '30% OFF',
            packageType: 'digital',
            cancellationMessage: 'Cancel anytime. Min cost $28.',
            offerCode: 'PUZ_2024',
            googleSKU: 'everyday_digital_puzzles_offer',
            weeklyPrice: THIRTY_OFF_EVERYDAY_DIGITAL,
            rateCode: THIRTY_OFF_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE,
        },
    ],
    ePaper: [
        {
            packagePath: 'subscribe/new',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$1 per day',
            packageType: 'digital',
            cancellationMessage: 'Cancel anytime. Min cost $28.',
            googleSKU: 'everyday_digital_4weeks',
            weeklyPrice: EVERYDAY_DIGITAL,
            rateCode: WEEKLY_DIGITAL_RATE_CODE,
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. Min cost $36.',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    'ePaper-price-increase': [
        {
            packagePath: 'subscribe/new',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$8 per week',
            packageType: 'digital',
            cancellationMessage: 'Cancel anytime. Min cost $32.',
            googleSKU: 'everyday_digital_4weeks_8',
            weeklyPrice: EVERYDAY_DIGITAL_INCREASE,
            rateCode: WEEKLY_DIGITAL_RATE_CODE_INCREASE,
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$10 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. Min cost $40.',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL_INCREASE,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE_INCREASE,
        },
    ],
    'digital-only': [
        {
            packagePath: 'subscribe/new',
            pretitle: 'Digital',
            heading: 'Everyday Digitalbbb',
            price: '$1 per day',
            packageType: 'digital',
            cancellationMessage: 'Cancel anytime. Min cost $28.',
            googleSKU: 'everyday_digital_4weeks',
            weeklyPrice: EVERYDAY_DIGITAL,
            rateCode: WEEKLY_DIGITAL_RATE_CODE,
        },
    ],
    'top-50-schools': [
        {
            packageTheme: 'yellow',
            packagePath: 'offercheck/thewest?redirect=true&offerCode=SCHOOL30',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: 'Now $4.90 per week',
            originalPrice: 'Was $7 per week',
            priceQualifier: 'for the first 8 weeks*',
            packageType: 'digital',
            ribbonTitle: '30% off*',
            rateCode: THIRTY_OFF_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE,
            cancellationMessage: 'Cancel anytime. 4 week minimum.',
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: THIRTY_OFF_EVERYDAY_DIGITAL,
            discount: EVERYDAY_DIGITAL - THIRTY_OFF_EVERYDAY_DIGITAL,
            offerCode: 'SCHOOL30',
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. 4 week minimum.',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    'abtest-b-1x4': [
        {
            packageTheme: 'abtest',
            packagePath: 'offercheck/thewest?redirect=true&offerCode=DIG1X4',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$1 for 4 weeks',
            originalPrice: '$7 per week',
            priceQualifier: '*then $7 per week',
            packageType: 'digital',
            ribbonTitle: 'On Sale',
            ribbonOptions: 'cornerDesignTag',
            offerCode: 'DIG1X4',
            googleSKU: 'everyday_digital_1_first_month',
            cancellationMessage: 'Min term 8 weeks. Min cost $29',
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: 0.25,
            discount: 6.75,
            rateCode: 'WST_DIGI_$1X4',
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. 4 week minimum.',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            discount: 0,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    'abtest-c-50cx8': [
        {
            packageTheme: 'abtest',
            packagePath: 'offercheck/thewest?redirect=true&offerCode=DIG50CX8',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$3.50 per week',
            originalPrice: '$7 per week',
            priceQualifier: '*then $7 per week',
            packageType: 'digital',
            ribbonTitle: 'On Sale',
            ribbonOptions: 'cornerDesignTag',
            offerCode: 'DIG50CX8',
            googleSKU: 'everyday_digital_50_cents',
            cancellationMessage: 'Min term 8 weeks. Min cost $28',
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: 3.5,
            discount: 3.5,
            rateCode: 'WST_DIGI_$3.50X8W',
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. 4 week minimum.',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            discount: 0,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    'abtest-d-50px8': [
        {
            packageTheme: 'abtest',
            packagePath: 'offercheck/thewest?redirect=true&offerCode=DIG50X8',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$3.50 per week',
            originalPrice: '$7 per week',
            priceQualifier: '*then $7 per week',
            packageType: 'digital',
            ribbonTitle: 'On Sale',
            ribbonOptions: 'cornerDesignTag',
            offerCode: 'DIG50X8',
            googleSKU: 'everyday_digital_50_off',
            cancellationMessage: 'Min term 8 weeks. Min cost $28',
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: 3.5,
            discount: 3.5,
            rateCode: 'WST_DIGI_$3.50X8W',
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. 4 week minimum.',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            discount: 0,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    'ab-test-promo-20-percent': [
        {
            packageTheme: 'abtest2',
            packagePath: 'offercheck/thewest?redirect=true&offerCode=DIG20X8',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$5.60 per week',
            originalPrice: '$7 per week',
            priceQualifier: 'for the first 8 weeks',
            packageType: 'digital',
            ribbonTitle: 'On Sale',
            ribbonOptions: 'cornerDesignTag',
            offerCode: 'DIG20X8',
            googleSKU: 'everyday_digital_20_percent',
            cancellationMessage: 'Min term 8 weeks. Min cost $44.80',
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: 5.6,
            discount: 1.4,
            rateCode: 'WST_DIGI$5.60x8_DD',
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. Min cost $36',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            discount: 0,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    'ab-test-promo-30-percent': [
        {
            packageTheme: 'abtest2',
            packagePath: 'offercheck/thewest?redirect=true&offerCode=DIG30X8',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$4.90 per week',
            originalPrice: '$7 per week',
            priceQualifier: 'for the first 8 weeks',
            packageType: 'digital',
            ribbonTitle: 'On Sale',
            ribbonOptions: 'cornerDesignTag',
            offerCode: 'DIG30X8',
            googleSKU: 'everyday_digital_30_percent',
            cancellationMessage: 'Min term 8 weeks. Min cost $39.20',
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: 4.9,
            discount: 2.1,
            rateCode: 'WST_DIGI$4.90x8_DD',
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. Min cost $36',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            discount: 0,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    'ab-test-promo-40-percent': [
        {
            packageTheme: 'abtest2',
            packagePath: 'offercheck/thewest?redirect=true&offerCode=DIG40X8',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$4.20 per week',
            originalPrice: '$7 per week',
            priceQualifier: 'for the first 8 weeks',
            packageType: 'digital',
            ribbonTitle: 'On Sale',
            ribbonOptions: 'cornerDesignTag',
            offerCode: 'DIG40X8',
            googleSKU: 'everyday_digital_40_percent',
            cancellationMessage: 'Min term 8 weeks. Min cost $33.60',
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: 4.2,
            discount: 2.8,
            rateCode: 'WST_DIGI_$4.20X8W_DD',
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. Min cost $36',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            discount: 0,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    'vanishing-cousins': [
        {
            packageTheme: 'vanishing-cousins',
            packagePath: 'offercheck/thewest?redirect=true&offerCode=VC23',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$4.20 per week',
            originalPrice: '$7 per week',
            priceQualifier: 'for the first 8 weeks',
            packageType: 'digital',
            ribbonTitle: 'On Sale',
            ribbonOptions: 'cornerDesignTag',
            offerCode: 'VC23',
            googleSKU: 'everyday_digital_40_percent',
            cancellationMessage: 'Cancel anytime. Min cost $33.60',
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: 4.2,
            discount: 2.8,
            rateCode: 'WST_DIGI_$4.20X8W_DD',
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. Min cost $36',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            discount: 0,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
            packageTheme: 'vanishing-cousins',
        },
    ],
    'breach-190-year': [
        {
            packageTheme: '190year',
            packagePath: 'offercheck/thewest?redirect=true&offerCode=190BD23',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$3.50 per week',
            originalPrice: 'Was $7 per week',
            priceQualifier: '*for the first 8 weeks',
            packageType: 'digital',
            ribbonTitle: 'On Sale',
            ribbonOptions: 'cornerDesignTag',
            googleSKU: 'everyday_digital_190year',
            cancellationMessage: 'Cancel anytime. Min cost $14.',
            rateCode: FIFTY_OFF_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE,
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: FIFTY_OFF_EVERYDAY_DIGITAL,
            discount: EVERYDAY_DIGITAL - FIFTY_OFF_EVERYDAY_DIGITAL,
            offerCode: '190BD23',
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. 4 week minimum.',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    '50-off-afl-breach-screen': [
        {
            packagePath: 'offercheck/thewest?redirect=true&offerCode=24FOOTY1',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$3.50 per week',
            originalPrice: '$7 per week',
            priceQualifier: 'for 8 weeks',
            packageType: 'digital',
            packageTheme: 'red',
            ribbonTitle: 'On sale',
            ribbonOptions: 'cornerDesignTag',
            rateCode: FIFTY_OFF_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE,
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: FIFTY_OFF_EVERYDAY_DIGITAL,
            discount: EVERYDAY_DIGITAL - FIFTY_OFF_EVERYDAY_DIGITAL,
            offerCode: '24FOOTY1',
            googleSKU: 'everyday_digital_footy_pack',
            cancellationMessage: `T&C's apply. Min spend $28.`,
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Everyday Digital & Weekend Papers',
            price: '$4.50 per week',
            originalPrice: '$9 per week',
            priceQualifier: 'for 8 weeks',
            benefitOverride: [
                'Access to all digital content',
                'Weekend Papers delivered',
                'Regional online news',
                'The West App',
                'Daily digital edition newspaper',
                'Subscriber rewards',
            ],
            packagePath: 'offercheck/thewest?redirect=true&offerCode=24FOOTY2',
            packageType: 'digitalprint',
            packageTheme: 'red',
            ribbonTitle: 'On sale',
            ribbonOptions: 'cornerDesignTag',
            cancellationMessage: `T&C's apply. Min spend $36.`,
            rateCode: FIFTY_OFF_WE_PAPER_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE,
            weeklyPrice: FRI_TO_MON_PAPERS_AND_EVERYDAY_DIGITAL,
            promoPrice: FIFTY_OFF_FRI_TO_MON_PAPERS_AND_EVERYDAY_DIGITAL,
            discount:
                FRI_TO_MON_PAPERS_AND_EVERYDAY_DIGITAL -
                FIFTY_OFF_FRI_TO_MON_PAPERS_AND_EVERYDAY_DIGITAL,
            offerCode: '24FOOTY2',
        },
    ],
    'subs-campaign-eofy-weekend-digital': [
        {
            packagePath: 'offercheck/thewest?redirect=true&offerCode=24EOFY1',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$3.50 per week',
            originalPrice: '$7 per week',
            priceQualifier: 'for the first 8 weeks',
            packageType: 'digital',
            packageTheme: 'west-blue',
            ribbonTitle: 'On sale',
            ribbonOptions: 'cornerDesignTag',
            offerCode: '24EOFY1',
            googleSKU: 'everyday_digital_eofy',
            cancellationMessage: `T&C's apply. Min spend $28.`,
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: FIFTY_OFF_EVERYDAY_DIGITAL,
            discount: EVERYDAY_DIGITAL - FIFTY_OFF_EVERYDAY_DIGITAL,
            rateCode: FIFTY_OFF_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE,
        },
        {
            packagePath: 'offercheck/thewest?redirect=true&offerCode=24EOFY2',
            pretitle: 'Digital & Print',
            heading: 'Everyday Digital & Weekend Papers',
            price: '$4.50 per week',
            originalPrice: '$9 per week',
            priceQualifier: 'for the first 8 weeks',
            packageType: 'digitalprint',
            packageTheme: 'west-blue',
            ribbonTitle: 'On sale',
            ribbonOptions: 'cornerDesignTag',
            offerCode: '24EOFY2',
            cancellationMessage: `T&C's apply. Min spend $36.`,
            rateCode: FIFTY_OFF_WE_PAPER_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE,
            weeklyPrice: FRI_TO_MON_PAPERS_AND_EVERYDAY_DIGITAL,
            promoPrice: FIFTY_OFF_FRI_TO_MON_PAPERS_AND_EVERYDAY_DIGITAL,
            discount:
                FRI_TO_MON_PAPERS_AND_EVERYDAY_DIGITAL -
                FIFTY_OFF_FRI_TO_MON_PAPERS_AND_EVERYDAY_DIGITAL,
        },
    ],
    'black-friday': [
        {
            packagePath: 'offercheck/thewest?redirect=true&offerCode=BLKFRI1',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            price: '$3.50 per week',
            originalPrice: '$7 per week',
            priceQualifier: 'for 8 weeks',
            packageType: 'digital',
            ribbonTitle: 'On Sale',
            ribbonOptions: 'cornerDesignTag',
            offerCode: 'BLKFRI1',
            googleSKU: 'everyday_digital_50_off',
            cancellationMessage: 'Cancel anytime. Min cost $28.00',
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: FIFTY_OFF_EVERYDAY_DIGITAL,
            discount: EVERYDAY_DIGITAL - FIFTY_OFF_EVERYDAY_DIGITAL,
            rateCode: 'WST_DIGI_$3.50X8W',
        },
        {
            packagePath: 'offercheck/thewest?redirect=true&offerCode=BLKFRI2',
            pretitle: 'Digital & Print',
            heading: 'Everyday Digital & Weekend Papers',
            price: '$4.50 per week',
            originalPrice: '$9 per week',
            priceQualifier: 'for 8 weeks',
            packageType: 'digitalprint',
            ribbonTitle: 'On Sale',
            ribbonOptions: 'cornerDesignTag',
            offerCode: 'BLKFRI2',
            cancellationMessage: 'Cancel anytime. Min cost $36.00',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            promoPrice: FIFTY_OFF_WE_PAPERS_AND_EVERYDAY_DIGITAL,
            discount:
                WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL -
                FIFTY_OFF_WE_PAPERS_AND_EVERYDAY_DIGITAL,
            rateCode: 'WST_SS+DIG4.50X8W_DD',
        },
    ],
    '50-off-breach-discount': base50percentDiscountPackage,
    'business-pack': [
        {
            packagePath: 'offercheck/thewest?redirect=true&offerCode=BUSN2',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            originalPrice: '$1 per day',
            price: '$0.70  per day',
            packageType: 'digital',
            cancellationMessage: 'Cancel anytime. Min cost $28.',
            ribbonTitle: '30% OFF*',
            rateCode: THIRTY_OFF_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE,
            priceQualifier: 'for the first 8 weeks*',
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: THIRTY_OFF_EVERYDAY_DIGITAL,
            discount: EVERYDAY_DIGITAL - THIRTY_OFF_EVERYDAY_DIGITAL,
            offerCode: 'BUSN2',
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. Min cost $36.',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    'sport-pack': [
        {
            packagePath: 'offercheck/thewest?redirect=true&offerCode=SP3022',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            originalPrice: '$1 per day',
            price: '$0.70  per day',
            packageType: 'digital',
            cancellationMessage: 'Cancel anytime. Min cost $28.',
            ribbonTitle: '30% OFF*',
            priceQualifier: 'for the first 8 weeks*',
            rateCode: THIRTY_OFF_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE,
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: THIRTY_OFF_EVERYDAY_DIGITAL,
            discount: EVERYDAY_DIGITAL - THIRTY_OFF_EVERYDAY_DIGITAL,
            offerCode: 'SP3022',
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. Min cost $36.',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    'bali-bombing': [
        {
            packagePath: 'offercheck/thewest?redirect=true&offerCode=BB2022',
            pretitle: 'Digital',
            heading: 'Everyday Digital',
            originalPrice: '$1 per day',
            price: '$0.70  per day',
            packageType: 'digital',
            cancellationMessage: 'Cancel anytime. Min cost $28.',
            ribbonTitle: '30% OFF*',
            priceQualifier: 'for the first 8 weeks*',
            weeklyPrice: EVERYDAY_DIGITAL,
            promoPrice: THIRTY_OFF_EVERYDAY_DIGITAL,
            discount: EVERYDAY_DIGITAL - THIRTY_OFF_EVERYDAY_DIGITAL,
            offerCode: 'BB2022',
            rateCode: THIRTY_OFF_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE,
        },
        {
            pretitle: 'Digital & Print',
            heading: 'Weekend Papers + Everyday Digital',
            price: '$9 per week',
            packagePath: 'subscribenow/sat-sun+digital',
            packageType: 'digitalprint',
            cancellationMessage: 'Cancel anytime. Min cost $36.',
            weeklyPrice: WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL,
            rateCode: WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE,
        },
    ],
    sport: [
        {
            pretitle: 'The Footy Pack',
            heading: 'Digital + Print',
            originalPrice: '$13 per week',
            price: '$7.50 per week',
            priceQualifier: 'for the first 8 weeks*',
            weeklyPrice: 7.5,
            discount: 5.5,
            rateCode: SPORT_BREACH_DIGITAL_PRINT_RATE_CODE,
            packagePath: '',
            offerCode: 'FOOTYPAK',
        },
        {
            packagePath: 'subscribe/new',
            pretitle: 'Everyday Digital',
            heading: 'Digital Only',
            price: '$3.50 per week',
            priceQualifier: 'for the first 8 weeks*',
            packageType: 'digital',
            weeklyPrice: FIFTY_OFF_EVERYDAY_DIGITAL,
            discount: EVERYDAY_DIGITAL - FIFTY_OFF_EVERYDAY_DIGITAL,
            rateCode: FIFTY_OFF_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE,
        },
    ],
}

export function packageOfferDetails(
    type: BreachWallContentType,
): SubscriptionPackages {
    const packages: SubscriptionPackages = []

    switch (type) {
        case '50-off-breach-discount':
            breachPackages['50-off-breach-discount'].forEach((pack) => {
                packages.push(pack)
            })
            break

        case '50-off-afl-breach-screen':
            breachPackages['50-off-afl-breach-screen'].forEach((pack) => {
                packages.push(pack)
            })
            break

        default:
            breachPackages[type].forEach((pack) => {
                packages.push(pack)
            })
            break
    }

    return packages
}

export function getWeeklyPrice(rateCode: string): number {
    switch (rateCode) {
        case WEEKLY_DIGITAL_RATE_CODE:
            return EVERYDAY_DIGITAL
        case WE_PAPER_EVERYDAY_DIGITAL_RATE_CODE:
            return WEEKEND_PAPERS_AND_EVERYDAY_DIGITAL
        case FIFTY_OFF_EVERYDAY_DIGITAL_12_WEEKS_RATE_CODE:
            return FIFTY_OFF_EVERYDAY_DIGITAL
        case FIFTY_OFF_FRI_MON_PAPER_AND_DIGITAL_12_WEEKS_RATE_CODE:
            return FIFTY_OFF_FRI_TO_MON_PAPERS_AND_EVERYDAY_DIGITAL
        case FIFTY_OFF_EVERYDAY_DIGITAL_8_WEEKS_RATE_CODE:
            return FIFTY_OFF_EVERYDAY_DIGITAL
        default:
            return 0
    }
}

export function useUpdateBreachWallContext({
    publication,
    breachCreativeName,
    breachDeal,
    leadDepth,
    offerCode,
    packagePath,
    requiredAccessLevel,
}: BreachContext) {
    const breachScreenContext = useContext(BreachScreenContext)

    const authentication = useSelector<AppState, AuthenticationState>(
        (state) => state.authentication,
    )

    const { entitlements, isLoggedIn } = authentication

    // a returning user is a user who has registered, logged in, and has not completed a purchase
    const returningUser = isLoggedIn && !entitlements?.includes(THEWEST_ALL)

    useEffect(() => {
        breachScreenContext.setContext({
            publication,
            returningUser,
            breachCreativeName,
            breachDeal,
            leadDepth,
            offerCode,
            packagePath,
            requiredAccessLevel,
        })
        return () => {
            breachScreenContext.clearContext()
        }

        // We can ignore the breachScreenContext dependency because the setContext and clearContext functions aren't going to change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        returningUser,
        publication,
        breachCreativeName,
        breachDeal,
        leadDepth,
        offerCode,
        packagePath,
        requiredAccessLevel,
    ])
}
