import { Logger } from 'typescript-log'

// This should be called right before a pubads().refresh(...)
export function setMoatTargetingOnSlots(
    gptSlots: googletag.Slot[],
    log: Logger,
) {
    // This function should not be called unless moat targeting has been done at the
    // page level. The gpt-api ensures this happens
    if (typeof window !== undefined && window.moatPrebidApi) {
        for (let i = 0; i < gptSlots.length; i++) {
            window.moatPrebidApi.setMoatTargetingForSlot(gptSlots[i])
        }
        log.debug(
            'GPT API: Set targeting across all slots using Moat YI Targeting',
        )
    }
}
