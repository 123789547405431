import { ArticleCardList, ArticleCardListProps } from '../ArticleCardList'
import { NewsletterSignup } from '../../../banners/NewsletterSignup/NewsletterSignup'
import React from 'react'
import { AdUnitWrapper } from '../../../advertising'
import {
    AdDefinition,
    AdEvent,
    AdState,
    BaseClientConfig,
    TogglesReduxState,
} from '@news-mono/web-common'
import { CollectionEvent } from '../../../../../web-common/tsc-out/events/collection-events'
import { AdContainer, MedalTallyWidgetWrapper } from '../ArticleCardList.styled'
import { TNNewsletterSignup } from '../../../banners/NewsletterSignup/TNNewsletterSignup'
import { RecaptchaProvider } from '../../../recaptcha/Recaptcha'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useLocation } from 'react-router'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { MedalTally } from '../../../olympics'

const queryClient = new QueryClient()

export type TopicSectionArticleCardListProps = Omit<
    ArticleCardListProps,
    'interlacedComponents'
> & {
    adUnits: number
    api: string
    adState: AdState
    onEvent: (event: CollectionEvent | AdEvent) => void
    isStandalonePage?: boolean
    config?: BaseClientConfig
    impressionRef?: React.MutableRefObject<HTMLDivElement | null>
    toggles: TogglesReduxState
}

const INITIAL_AD_OFFSET = 4
const CARDS_BETWEEN_ADS = 4
const getAdUnitId = (index: number) => `article-list-${index}`

// Builds an array of the AdUnitDefinitions.
export const getTopicSectionAdUnitDefinitions = (adUnitAmount: number) =>
    Array.from(
        { length: adUnitAmount },
        (_, i) => INITIAL_AD_OFFSET + i * CARDS_BETWEEN_ADS,
    ).map<AdDefinition>((index) => ({
        id: getAdUnitId(index),
        size: 'leaderboardAbove768MrecBelow',
    }))

/**
 * An ArticleCardList configured specifically for the Topic Section page on the nightly.
 */
export const TopicSectionArticleCardList = ({
    adUnits,
    api,
    adState,
    onEvent,
    config,
    toggles,
    ...props
}: TopicSectionArticleCardListProps) => {
    const location = useLocation()
    const featureState = toggles && toFeatureState(toggles)

    const olympicsMedalWidgetEnabled = Boolean(
        featureState &&
            isFeatureEnabled(featureState, 'paris-olympics-widgets', false),
    )

    return (
        <ArticleCardList
            {...props}
            onEvent={onEvent}
            interlacedComponents={{
                'newsletter-promo': {
                    component: () => (
                        <>
                            {config && config.recaptureSiteKey ? (
                                <RecaptchaProvider
                                    siteKey={config.recaptureSiteKey}
                                >
                                    <QueryClientProvider client={queryClient}>
                                        <TNNewsletterSignup
                                            text={`Sign up to The Nightly's newsletters.`}
                                            subText={
                                                'Get the first look at the digital newspaper, curated daily stories and breaking headlines delivered to your inbox.'
                                            }
                                            buttonLabel={'Get the Newsletter'}
                                            mode={'inline-article'}
                                            onEvent={onEvent}
                                        />
                                    </QueryClientProvider>
                                </RecaptchaProvider>
                            ) : (
                                <></>
                            )}
                        </>
                    ),
                    initialOffset: 3,
                },
                'olympics-medal-tally-widget': {
                    component: () => (
                        <>
                            {location.pathname.includes('sport/olympics') &&
                            olympicsMedalWidgetEnabled ? (
                                <MedalTallyWidgetWrapper>
                                    <MedalTally
                                        size="small"
                                        widgetTitle="medal-tally"
                                        svgColourOverride="#06671e"
                                    />
                                </MedalTallyWidgetWrapper>
                            ) : (
                                <></>
                            )}
                        </>
                    ),
                    initialOffset: 1,
                },
                adverts: {
                    component: (index) => (
                        <AdContainer
                            margin={{
                                default: '8px 0',
                                sm: '16px 0',
                                lg: '40px 0',
                            }}
                        >
                            <AdUnitWrapper
                                adState={adState}
                                onEvent={onEvent}
                                noticePosition={'above-center'}
                                adType={'inline'}
                                unitId={getAdUnitId(index)}
                            />
                        </AdContainer>
                    ),
                    initialOffset: INITIAL_AD_OFFSET,
                    cardsBetween: CARDS_BETWEEN_ADS,
                    repetitions: adUnits,
                },
            }}
            adTargeting={props.adTargeting}
        />
    )
}
