import React from 'react'
import { PaginationContent } from './components/PaginationContent'
import { useLocation, useParams } from 'react-router-dom'
import { Paging } from './usePagination'

export interface PaginationProps {
    setPage: (page: number) => void
    itemCount: number
    paging: Paging
    maxPages?: number
    params?: string
    onEvent: (event: any) => void
}

/** A pagination component for The Nightly. */
export const Pagination = ({
    itemCount,
    paging,
    setPage,
    maxPages,
    params,
    onEvent,
}: PaginationProps) => {
    const { pathname } = useLocation()

    return (
        <PaginationContent
            currentPage={paging.page}
            pageSize={paging.pageSize}
            maxPages={maxPages}
            publicationCount={itemCount}
            setPage={setPage}
            path={pathname}
            params={params}
            onEvent={onEvent}
        />
    )
}
