import {
    AddToCartEvent,
    AppState,
    authCheckAccessToken,
    BaseClientConfig,
    ExtendedAccessEvent,
    SubscribeWithGoogleEvent,
    UserAuthEvents,
} from '@news-mono/web-common'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
    BeginLoadingEvent,
    EndLoadingEvent,
} from 'react-ssr-data-loader/dist/cjs/events'
import { Logger } from 'typescript-log'

interface AuthenticationHandlerProps {
    log: Logger
    config: BaseClientConfig
    onEvent: (
        authEvent:
            | UserAuthEvents
            | BeginLoadingEvent
            | EndLoadingEvent
            | ExtendedAccessEvent
            | SubscribeWithGoogleEvent
            | AddToCartEvent,
    ) => void
}

export const AuthenticationHandler: React.FC<AuthenticationHandlerProps> = ({
    log,
    config,
    onEvent,
}) => {
    const dispatch = useDispatch()
    const authentication = useSelector(
        (state: AppState) => state.authentication,
    )
    const location = useLocation()

    useEffect(() => {
        if (authentication.hasAttemptedValidation) {
            dispatch(
                authCheckAccessToken({
                    onEvent,
                    log,
                    config,
                    invocation: 'auto',
                }),
            )
        }
    }, [
        location.pathname,
        onEvent,
        log,
        dispatch,
        authentication.hasAttemptedValidation,
        config,
    ])

    return null
}
