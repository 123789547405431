import { tokens } from '@news-mono/design-tokens'
import {
    AppName,
    CommonSections,
    FavIconMeta,
    OrganizationAddress,
    OrganizationStructuredData,
    perthNowFallbackImages,
    PerthNowSections,
    Section,
    SectionMetaDTO,
    SectionMetaInfoDTO,
    sevenNewsFallbackImages,
    SevenNewsSections,
    ShareImage,
    theWestFallbackImages,
    TheWestSections,
    TheNightlySections,
} from '@west-australian-newspapers/publication-types'
import { ImageObject } from 'schema-dts'
import { MetadataConfig, SectionHostnames } from './config'

interface SectionStructuredData {
    mastheadTopic: string
    regionalTopic: string
    title: string
    shortTitle?: string
    description: string
    socialDescription: string
    publicationSource: string
    socialLinks?: string[]
    publicUrl: string
    hasMatchingFirstLevelTopic?: boolean
    hostname: string
    contact: {
        address: {
            street: string
            city: string
            state: 'WA' | string
            postcode: number
        }
        email: string
        phone: string
    }
    publisherLogo: {
        path: string
        width: number
        height: number
    }
    pinnedColor: string

    facebookIds: {
        pagesId?: string

        appId: string
        clientToken: string
    }
}
export type TheWestSection = keyof typeof TheWestSections
export type TheNightlySection = keyof typeof TheNightlySections

export type TheWestRegionalSections = Exclude<
    TheWestSection,
    'default' | 'genwest'
>

function generatefavIconPaths(
    publicUrl: string,
    color: string,
    subFolder = '',
): FavIconMeta {
    return {
        appleTouch: `${publicUrl}/static/favicons/${subFolder}apple-touch-icon.png`,
        browserConfig: `${publicUrl}/static/favicons/${subFolder}browserconfig.xml`,
        favicon16: `${publicUrl}/static/favicons/${subFolder}favicon-16x16.png`,
        favicon32: `${publicUrl}/static/favicons/${subFolder}favicon-32x32.png`,
        favicon: `${publicUrl}/static/favicons/${subFolder}favicon.ico`,
        safariPinned: `${publicUrl}/static/favicons/${subFolder}safari-pinned-tab.svg`,
        pinnedColor: color,
        webmanifest: `${publicUrl}/static/favicons/${subFolder}site.webmanifest`,
    }
}

export function resolveSection(slug: string, appName: AppName): string {
    const firstSegment = slug.split('/')[1]
    function pickSection(sections: any) {
        return Object.keys(sections).includes(firstSegment)
            ? firstSegment
            : CommonSections.default
    }

    switch (appName) {
        case 'perthnow':
            return pickSection(PerthNowSections)
        case 'sevennews':
            return pickSection(SevenNewsSections)
        case 'thewest':
            return pickSection(TheWestSections)
        case 'thenightly':
            return pickSection(TheNightlySections)
        case 'neutral':
        default:
            return CommonSections.default
    }
}

export function getSectionInfo(
    section: string,
    config: MetadataConfig,
): SectionMetaInfoDTO {
    const siteSections = getSiteSectionInfo(config)

    return {
        ...siteSections.defaultSectionMeta,
        ...(siteSections.sectionOverrides as any)[section],
    }
}

const publisherLogo = (publicUrl: string) => {
    return {
        image: `${publicUrl}/static/publisher-logos/publisher-logo-60px-high.png`,
        width: 575,
        height: 60,
    }
}

export const publisherLogoLdJson = (publicUrl: string): ImageObject => {
    return {
        '@type': 'ImageObject',
        contentUrl: publisherLogo(publicUrl).image,
        height: publisherLogo(publicUrl).height.toString(),
        width: publisherLogo(publicUrl).width.toString(),
    }
}

export function getSiteSectionInfo({
    appName,
    publicUrl,
    facebookAppId,
    facebookClientToken,
    sectionHostnames,
}: MetadataConfig): SectionMetaDTO {
    function getHostnameForSection(
        section: Exclude<Section, typeof CommonSections.default>,
    ) {
        if (!sectionHostnames) {
            return publicUrl
        }
        return sectionHostnames[section]
    }

    if (appName === 'perthnow') {
        return {
            defaultSectionMeta: {
                Title: 'PerthNow',
                ShortTitle: 'PerthNow',
                Share: {
                    image: `${publicUrl}/static/social-images/share-400x400.png`,
                    width: 400,
                    height: 400,
                },
                TwitterImage: `${publicUrl}/static/social-images/share-twitter-664x332.png`,
                OgImage: {
                    image: `${publicUrl}/static/social-images/share-og-1200x630.png`,
                    width: 1200,
                    height: 630,
                },
                PublisherLogo: publisherLogo(publicUrl),
                FallbackImages: perthNowFallbackImages,
                favicons: generatefavIconPaths(publicUrl, '#d22220'),
                FacebookAppId: facebookAppId,
                FacebookClientToken: facebookClientToken,
                FacebookPagesId: '92409946191',
                organisationLd: getOrganizationLd(appName, publicUrl),
            },
            sectionOverrides: {
                'community-news': {
                    Title: 'Community News',
                    ShortTitle: 'Community News',
                    Hostname: getHostnameForSection('community-news'),
                },
            },
        }
    }
    if (appName === 'thewest') {
        return {
            defaultSectionMeta: {
                Title: 'The West Australian',
                ShortTitle: 'The West',
                Share: {
                    image: `${publicUrl}/static/share-images/share-400x400.png`,
                    width: 400,
                    height: 400,
                },
                TwitterImage: `${publicUrl}/static/share-images/share-400x400.png`,
                OgImage: {
                    image: `${publicUrl}/static/share-images/share-400x400.png`,
                    width: 400,
                    height: 400,
                },
                PublisherLogo: {
                    image: `${publicUrl}/static/publisher-logos/publisher-logo-60px-high.png`,
                    width: 600,
                    height: 60,
                },
                FallbackImages: theWestFallbackImages,
                favicons: generatefavIconPaths(publicUrl, '#043de2'),
                FacebookAppId: facebookAppId,
                FacebookClientToken: facebookClientToken,
                FacebookPagesId: '290190314438',
                organisationLd: getOrganizationLd(appName, publicUrl),
            },
            sectionOverrides: {
                genwest: {
                    Title: 'GenWest',
                },
                'albany-advertiser': getRegionalMetadata(
                    'albany-advertiser',
                    sectionHostnames,
                ),
                'augusta-margaret-river-times': getRegionalMetadata(
                    'augusta-margaret-river-times',
                    sectionHostnames,
                ),
                'broome-advertiser': getRegionalMetadata(
                    'broome-advertiser',
                    sectionHostnames,
                ),
                'bunbury-herald': getRegionalMetadata(
                    'bunbury-herald',
                    sectionHostnames,
                ),
                'busselton-dunsborough-times': getRegionalMetadata(
                    'busselton-dunsborough-times',
                    sectionHostnames,
                ),
                countryman: getRegionalMetadata('countryman', sectionHostnames),
                'geraldton-guardian': getRegionalMetadata(
                    'geraldton-guardian',
                    sectionHostnames,
                ),
                'great-southern-herald': getRegionalMetadata(
                    'great-southern-herald',
                    sectionHostnames,
                ),
                'harvey-waroona-reporter': getRegionalMetadata(
                    'harvey-waroona-reporter',
                    sectionHostnames,
                ),
                'kalgoorlie-miner': getRegionalMetadata(
                    'kalgoorlie-miner',
                    sectionHostnames,
                ),
                'manjimup-bridgetown-times': getRegionalMetadata(
                    'manjimup-bridgetown-times',
                    sectionHostnames,
                ),
                'midwest-times': getRegionalMetadata(
                    'midwest-times',
                    sectionHostnames,
                ),
                'narrogin-observer': getRegionalMetadata(
                    'narrogin-observer',
                    sectionHostnames,
                ),
                'north-west-telegraph': getRegionalMetadata(
                    'north-west-telegraph',
                    sectionHostnames,
                ),
                'pilbara-news': getRegionalMetadata(
                    'pilbara-news',
                    sectionHostnames,
                ),
                'sound-telegraph': getRegionalMetadata(
                    'sound-telegraph',
                    sectionHostnames,
                ),
                'south-western-times': getRegionalMetadata(
                    'south-western-times',
                    sectionHostnames,
                ),
                'the-kimberley-echo': getRegionalMetadata(
                    'the-kimberley-echo',
                    sectionHostnames,
                ),
            },
        }
    }

    if (appName === 'sevennews') {
        return {
            defaultSectionMeta: {
                Title: '7NEWS',
                ShortTitle: '7NEWS.com.au',
                Share: {
                    image: `${publicUrl}/static/social-images/share-400x400.png`,
                    width: 400,
                    height: 400,
                },
                TwitterImage: `${publicUrl}/static/social-images/share-400x400.png`,
                OgImage: {
                    image: `${publicUrl}/static/social-images/share-400x400.png`,
                    width: 400,
                    height: 400,
                },
                PublisherLogo: publisherLogo(publicUrl),
                FallbackImages: sevenNewsFallbackImages,
                FacebookAppId: facebookAppId,
                FacebookPagesId: '114503341893201',
                FacebookClientToken: facebookClientToken,
                favicons: generatefavIconPaths(publicUrl, '#c73839'),
                organisationLd: getOrganizationLd(appName, publicUrl),
            },
            sectionOverrides: {},
        }
    }

    if (appName === 'thenightly') {
        return {
            defaultSectionMeta: {
                Title: 'The Nightly',
                ShortTitle: 'The Nightly',
                Share: {
                    image: `${publicUrl}/static/share-images/share-400x400.png`,
                    width: 400,
                    height: 400,
                },
                TwitterImage: `${publicUrl}/static/social-images/share-og-1200x630.png`,
                OgImage: {
                    image: `${publicUrl}/static/social-images/share-og-1200x630.png`,
                    width: 1200,
                    height: 630,
                },
                PublisherLogo: {
                    image: `${publicUrl}/static/publisher-logos/publisher-logo-60px-high.png`,
                    width: 600,
                    height: 60,
                },
                FallbackImages: theWestFallbackImages,
                favicons: generatefavIconPaths(publicUrl, '#043de2'),
                FacebookAppId: facebookAppId, //TBD
                FacebookClientToken: facebookClientToken, //TBD
                FacebookPagesId: '130685420123061', //TBD
                organisationLd: getOrganizationLd(appName, publicUrl),
            },
            sectionOverrides: {
                politics: {
                    Title: 'Politics',
                    ShortTitle: 'Politics',
                },
                australia: {
                    Title: 'Australia',
                    ShortTitle: 'Australia',
                },
                business: {
                    Title: 'Business',
                    ShortTitle: 'Business',
                },
                opinion: {
                    Title: 'Opinion',
                    ShortTitle: 'Opinion',
                },
                culture: {
                    Title: 'Culture',
                    ShortTitle: 'Culture',
                },
                society: {
                    Title: 'Society',
                    ShortTitle: 'Society',
                },
                lifestyle: {
                    Title: 'Lifestyle',
                    ShortTitle: 'Lifestyle',
                },
                sport: {
                    Title: 'Sport',
                    ShortTitle: 'Sport',
                },
                world: {
                    Title: 'World',
                    ShortTitle: 'World',
                },
                podcasts: {
                    Title: 'Podcasts',
                    ShortTitle: 'Podcasts',
                },
                video: {
                    Title: 'Video',
                    ShortTitle: 'Video',
                },
            },
        }
    }

    if (appName === 'neutral' && process.env.NODE_ENV === 'test') {
        return {
            defaultSectionMeta: {
                Title: 'Neutral',
                ShortTitle: 'Neutral short',
                Share: {
                    image: `${publicUrl}/static/share-images/share-400x400.png`,
                    width: 400,
                    height: 400,
                },
                TwitterImage: `${publicUrl}/static/social-images/share-twitter-664x332.png`,
                OgImage: {
                    image: `${publicUrl}/static/social-images/share-og-1200x630.png`,
                    width: 1200,
                    height: 630,
                },
                PublisherLogo: publisherLogo(publicUrl),
                favicons: generatefavIconPaths(publicUrl, '#043de2'),
                FacebookAppId: 'FB-APPID',
                FacebookClientToken: 'FB-CLID',
                FallbackImages: {
                    altText: 'Neutral fallback alt',
                    crops: {
                        '16:9': {
                            reference: 'https://neutral.site/fallback/16x9.jpg',
                            dimensions: '16x9',
                            width: 16,
                            height: 9,
                            ratio: '16:9',
                        },
                        '4:3': {
                            reference: 'https://neutral.site/fallback/4x3.jpg',
                            dimensions: '4x3',
                            width: 4,
                            height: 3,
                            ratio: '4:3',
                        },
                        '161:229': {
                            reference:
                                'https://neutral.site/fallback/161x229.jpg',
                            dimensions: '161x229',
                            width: 161,
                            height: 229,
                            ratio: '161:229',
                        },
                        '316:421': {
                            reference:
                                'https://neutral.site/fallback/316x421.jpg',
                            dimensions: '316x421',
                            width: 316,
                            height: 421,
                            ratio: '316:421',
                        },
                    },
                },
                organisationLd: {
                    '@context': 'http://schema.org',
                    '@id': `${publicUrl}/#/schema/Organization`,
                    '@type': 'NewsMediaOrganization',
                    address: {
                        postalCode: 2015,
                        streetAddress: '8 Central Avenue',
                        addressLocality: 'Eveleigh',
                        addressRegion: 'NSW',
                        addressCountry: 'AU',
                    },
                    logo: publisherLogoLdJson(publicUrl),
                    name: 'Neutral',
                    url: publicUrl,
                    alternateName: 'Neutral short',
                    sameAs: ['https://www.facebook.com/neutral-pub'],
                    description: 'Neutral',
                },
            },
            sectionOverrides: {},
        }
    }

    throw new Error(`Invalid app name: ${appName}`)
}

export function getOrganizationLd(
    appName: AppName,
    publicUrl: string,
): OrganizationStructuredData {
    switch (appName) {
        case 'thewest':
            return {
                '@context': 'http://schema.org',
                '@id': `${publicUrl}/#/schema/Organization`,
                '@type': 'NewsMediaOrganization',
                address: {
                    streetAddress: '50 Hasler Road',
                    addressLocality: 'Osborne Park',
                    addressRegion: 'WA',
                    postalCode: 6017,
                    addressCountry: 'AU',
                },
                logo: publisherLogoLdJson(publicUrl),
                name: 'The West Australian',
                url: publicUrl,
                alternateName: 'The West',
                sameAs: [
                    'https://www.facebook.com/thewestaustralian',
                    'https://en.wikipedia.org/wiki/The_West_Australian',
                    'https://twitter.com/westaustralian',
                    'https://www.instagram.com/thewestaustralian/',
                    'https://www.youtube.com/channel/UCby2ufdAO3PbD7x33hc8VvQ',
                ],
                description:
                    'The West Australian is a leading news source in Perth and WA. Breaking local and world news from sport and business to lifestyle and current affairs.',
            }

        case 'perthnow':
            return {
                '@context': 'http://schema.org',
                '@id': `${publicUrl}/#/schema/Organization`,
                '@type': 'NewsMediaOrganization',
                address: {
                    streetAddress: '50 Hasler Road',
                    addressLocality: 'Osborne Park',
                    addressRegion: 'WA',
                    postalCode: 6017,
                    addressCountry: 'AU',
                },
                logo: publisherLogoLdJson(publicUrl),
                name: 'PerthNow',
                url: publicUrl,
                alternateName: 'PerthNow',
                sameAs: [
                    'https://www.facebook.com/perthnow',
                    'https://twitter.com/perthnow',
                    'https://www.instagram.com/perthnow/',
                    'https://www.youtube.com/channel/UCstu39MWmFTe76_BvyBIubQ',
                ],
                description:
                    "PerthNow, Western Australia's latest news and stories including business, sport, entertainment, international and more.",
            }

        case 'sevennews':
            return {
                '@context': 'http://schema.org',
                '@id': `${publicUrl}/#/schema/Organization`,
                '@type': 'NewsMediaOrganization',
                url: publicUrl,
                name: '7NEWS',
                alternateName: ['7NEWS.com.au', '7 NEWS', 'Seven News'],
                sameAs: [
                    'https://www.facebook.com/7NewsAustralia',
                    'https://en.wikipedia.org/wiki/Seven_News',
                    'https://twitter.com/7newsaustralia',
                    'https://www.instagram.com/7newsaustralia',
                    'https://www.tiktok.com/discover/7-news-australia/',
                    'https://www.youtube.com/channel/UC5T7D-Dh1eDGtsAFCuwv_Sw',
                ],
                logo: publisherLogoLdJson(publicUrl),
                address: {
                    addressCountry: 'AU',
                    addressLocality: 'Eveleigh',
                    addressRegion: 'NSW',
                    postalCode: 2015,
                    streetAddress: '8 Central Avenue',
                },
                description:
                    "7NEWS brings you the latest local news from Australia and around the world. Stay up to date with all of the breaking sport, politics, entertainment, finance, weather and business headlines. Today's news, live updates & all the latest breaking stories from 7NEWS.",
            }

        case 'thenightly':
            return {
                '@context': 'http://schema.org',
                '@id': `${publicUrl}/#/schema/Organization`,
                '@type': 'NewsMediaOrganization',
                url: publicUrl,
                name: 'The Nightly',
                alternateName: 'The Nightly',
                sameAs: [
                    'https://facebook.com/thenightlyau',
                    'https://twitter.com/thenightlyau',
                    'https://www.instagram.com/thenightlyau/',
                    'https://www.tiktok.com/@thenightlyau',
                    'https://www.youtube.com/@thenightlyau',
                ],
                logo: publisherLogoLdJson(publicUrl),
                address: {
                    addressCountry: 'AU',
                    addressLocality: 'Eveleigh',
                    addressRegion: 'NSW',
                    postalCode: 2015,
                    streetAddress: '8 Central Avenue',
                },
                description:
                    'The Nightly brings the best local, national and world news and current affairs to your fingertips. Get in front of tomorrow’s news tonight with the latest in politics, opinion, business, entertainment, culture and sport, including exclusive content from The New York Times, The Economist and CNBC.',
            }

        default:
            return {
                '@context': 'http://schema.org',
                '@id': `${publicUrl}/#/schema/Organization`,
                '@type': 'NewsMediaOrganization',
                address: {
                    streetAddress: '50 Hasler Road',
                    addressLocality: 'Osborne Park',
                    addressRegion: 'WA',
                    postalCode: 6017,
                    addressCountry: 'AU',
                },
                logo: publisherLogoLdJson(publicUrl),
                name: 'Neutral',
                url: publicUrl,
                alternateName: 'Neutral short',
                sameAs: ['https://www.facebook.com/neutral-pub'],
                description: 'Neutral',
            }
    }
}

function getRegionalOrganisationLd(
    section: TheWestRegionalSections,
): OrganizationStructuredData {
    const region = regionalSiteConfig[section]
    const logoUrl = `${region.publicUrl}${region.publisherLogo.path}`
    return {
        '@context': 'http://schema.org',
        '@type': 'NewsMediaOrganization',
        '@id': `https://thewest.com.au/#/schema/Organization`,
        url: region.publicUrl,
        name: region.title,
        alternateName: region.shortTitle ? region.shortTitle : undefined,
        sameAs: region.socialLinks,
        logo: {
            '@type': 'ImageObject',
            url: logoUrl,
            contentUrl: logoUrl,
            width: String(region.publisherLogo.width),
            height: String(region.publisherLogo.height),
        },
        address: mapAddressStructuredData(region.contact.address),
        description: region.description,
    }
}

export function getRegionalMetadata(
    section: TheWestRegionalSections,
    sectionHostnames?: SectionHostnames,
): SectionMetaInfoDTO {
    const Hostname = sectionHostnames
        ? sectionHostnames[section]
        : regionalSiteConfig[section].publicUrl

    return {
        Title: regionalSiteConfig[section].title,
        ShortTitle:
            regionalSiteConfig[section].shortTitle ||
            regionalSiteConfig[section].title,
        PublisherLogo: getRegionalPublisherLogo(section),
        FallbackImages: theWestFallbackImages,
        Share: {
            image: `${regionalSiteConfig[section].publicUrl}/static/share-images/share-albany-advertiser-1200x700.jpg`,
            width: 1200,
            height: 700,
        },
        OgImage: {
            image: `${regionalSiteConfig[section].publicUrl}/static/share-images/share-albany-advertiser-400x400.png`,
            width: 400,
            height: 400,
        },
        TwitterImage: `${regionalSiteConfig[section].publicUrl}/static/share-images/share-albany-advertiser-400x400.png`,
        hasMatchingFirstLevelTopic:
            regionalSiteConfig[section].hasMatchingFirstLevelTopic ?? false,
        Hostname,
        DedicatedDomain: true,
        defaultTopic: regionalSiteConfig[section].mastheadTopic,
        favicons: generatefavIconPaths(
            regionalSiteConfig[section].publicUrl,
            regionalSiteConfig[section].pinnedColor,
            `regionals/${section}/`,
        ),
        FacebookPagesId: regionalSiteConfig[section].facebookIds.pagesId,
        FacebookAppId: regionalSiteConfig[section].facebookIds.appId,
        FacebookClientToken:
            regionalSiteConfig[section].facebookIds.clientToken,
        organisationLd: getRegionalOrganisationLd(section),
        contact: {
            phone: regionalSiteConfig[section].contact.phone,
            email: regionalSiteConfig[section].contact.email,
            page: regionalSiteConfig[section].publicUrl,
        },
    }
}

export const regionalSectionLiveUrls = (section: TheWestRegionalSections) => {
    return regionalSiteConfig[section].publicUrl
}

export const regionalSiteConfig: Record<
    TheWestRegionalSections,
    SectionStructuredData
> = {
    'albany-advertiser': {
        mastheadTopic: 'news/albany-advertiser',
        regionalTopic: 'news/great-southern',
        title: 'The Albany Advertiser',
        description:
            'The Albany Advertiser is a bi-weekly publication with editions on Tuesday and Thursday, and weekly comprehensive local Motoring and Real Estate liftouts. With an average readership of more than 20,000 per issue, it covers Albany and the surrounding Great Southern region. The newspaper was founded in 1888 and has been publishing continuously since, delivering local news, sport, entertainment, weather and opinion to the community',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the Great Southern region.',
        publicationSource: 'Albany Advertiser',
        publicUrl: 'https://www.albanyadvertiser.com.au',
        hostname: 'albanyadvertiser.com.au',
        socialLinks: [
            'https://www.facebook.com/albanyadvertiser/',
            'https://en.wikipedia.org/wiki/Albany_Advertiser',
            'https://twitter.com/albanyad',
        ],
        contact: {
            phone: '(08) 9892 8300',
            email: 'mailto:greatsouthern@wanews.com.au', 
            address: {
                street: '165 York Street',
                city: 'Albany',
                state: 'WA',
                postcode: 6330,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/albany-advertiser-publisher-logo-60px-high.png`,
            width: 352,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals['albanyadvertiserBlue'],
        facebookIds: {
            pagesId: '199250546797509',
            appId: '435599799975871',
            clientToken: '922c822b43f26e343356e2152c7641b2',
        },
    },
    'great-southern-herald': {
        mastheadTopic: 'news/great-southern-herald',
        regionalTopic: 'news/great-southern',
        title: 'The Great Southern Herald',
        description:
            'The Great Southern Herald is a weekly publication serving the Katanning community and surrounding Upper Great Southern region. It publishes local news, sport and social pictures and has an average readership of 3400 per issue',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the Great Southern region',
        publicationSource: 'Great Southern Herald',
        publicUrl: 'https://www.gsherald.com.au',
        hostname: 'gsherald.com.au',
        socialLinks: [
            'https://www.facebook.com/GreatSouthernHerald/',
            'https://en.wikipedia.org/wiki/Great_Southern_Herald',
        ],
        contact: {
            phone: '(08) 6332 1120',
            email: 'mailto:greatsouthern@wanews.com.au',
            address: {
                street: '49 Clive Street',
                city: 'Katanning',
                state: 'WA',
                postcode: 6317,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/great-southern-herald-publisher-logo-60px-high.png`,
            width: 458,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals['greatsouthernheraldBlue'],
        facebookIds: {
            pagesId: '1642054669364201',
            appId: '834271163360028',
            clientToken: '922c822b43f26e343356e2152c7641b2',
        },
    },
    'augusta-margaret-river-times': {
        mastheadTopic: 'news/augusta-margaret-river-times',
        regionalTopic: 'news/south-west',
        title: 'Augusta-Margaret River Times',
        description:
            "The Augusta Margaret River Times is a free newspaper published on Friday and covers Augusta, Margaret River and Cowaramup. The Times is the first source for quality news, community content and sports coverage in Western Australia's favoured wine and surfing destination.",
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the Margaret River region.',
        publicationSource: 'Augusta Margaret River Times',
        publicUrl: 'https://www.amrtimes.com.au',
        hostname: 'amrtimes.com.au',
        socialLinks: ['https://www.facebook.com/AMRTimes/'],
        contact: {
            phone: '(08) 6332 1130',
            email: 'mailto:editor@amrtimes.com.au',
            address: {
                street: '7B, 119 Bussell Hwy',
                city: 'Margaret River',
                state: 'WA',
                postcode: 6285,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/augusta-margaret-river-times-publisher-logo-60px-high.png`,
            width: 308,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals[
                'augustamargaretrivertimesOrange'
            ],
        facebookIds: {
            pagesId: '112724865468394',
            appId: '1677247572490292',
            clientToken: 'a7a2ae396eb401aab2b9cb2019b85e40',
        },
    },
    'busselton-dunsborough-times': {
        mastheadTopic: 'news/busselton-dunsborough-times',
        regionalTopic: 'news/south-west',
        title: 'Busselton-Dunsborough Times',
        description:
            'The Busselton Dunsborough Times serves the residents of the Geographe Bay region of Western Australia, in Busselton, Dunsborough and Yallingup. The free newspaper has quality news, a community focus and comprehensive sport coverage from the events capital of WA. It is the first choice for media event sponsorship. The Times has the highest readership and circulation of any local newspaper.',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the Geographe Bay region.',
        publicationSource: 'Busselton Dunsborough Times',
        publicUrl: 'https://www.bdtimes.com.au',
        hostname: 'bdtimes.com.au',
        socialLinks: ['https://www.facebook.com/BusseltonDunsboroughTimes/'],
        contact: {
            phone: '(08) 9752 5000',
            email: 'mailto:editor@bdtimes.com.au',
            address: {
                street: 'Unit 9, 8-10 Prince Street',
                city: 'Busselton',
                state: 'WA',
                postcode: 6280,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/busselton-dunsborough-times-publisher-logo-60px-high.png`,
            width: 272,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals[
                'busseltondunsboroughtimesTeal'
            ],
        facebookIds: {
            pagesId: '158185447581873',
            appId: '152593261757016',
            clientToken: 'd0806ac44130017eb9b3ee84e45d2304',
        },
    },
    'broome-advertiser': {
        mastheadTopic: 'news/broome-advertiser',
        regionalTopic: 'news/kimberley',
        title: 'Broome Advertiser',
        description:
            'The Broome Advertiser serves the communities in Broome, Derby and Fitzroy Crossing, among many others.  For news and sport from your local area, pick up your copy of the Broome Advertiser each Thursday.',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the West Kimberley region.',
        publicationSource: 'Broome Advertiser',
        publicUrl: 'https://www.broomead.com.au',
        hostname: 'broomead.com.au',
        socialLinks: [
            'https://www.facebook.com/broomeadvertiser/',
            'https://twitter.com/brmadvertiser',
        ],
        contact: {
            phone: '(08) 9191 9700',
            email: 'mailto:news@broomead.com.au',
            address: {
                street: '27-29 Dampier Terrace, Chinatown',
                city: 'Broome',
                state: 'WA',
                postcode: 6725,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/broome-advertiser-publisher-logo-60px-high.png`,
            width: 374,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals['broomeadvertiserGreen'],
        facebookIds: {
            pagesId: '170825106303442',
            appId: '730744960389504',
            clientToken: '10355fb5f751bc8c19d175b872259ad0',
        },
    },
    'bunbury-herald': {
        mastheadTopic: 'news/bunbury-herald',
        regionalTopic: 'news/south-west',
        title: 'Bunbury Herald',
        description:
            'The Bunbury Herald serves the residents of the Bunbury region of Western Australia. The newspaper has quality news, a community focus and comprehensive sport coverage from the tourism capital of WA. It is the first choice for media event sponsorship.',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the Bunbury region.',
        publicationSource: 'Bunbury Herald',
        publicUrl: 'https://www.bunburyherald.com.au',
        hostname: 'bunburyherald.com.au',
        socialLinks: ['https://www.facebook.com/BunburyHerald/'],
        contact: {
            phone: '(08) 99780 0800',
            email: 'mailto:editor@swtimes.com.au', // same newsroom as SW Time
            address: {
                street: '19 Proffit Street',
                city: 'Bunbury',
                state: 'WA',
                postcode: 6230,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/bunbury-herald-publisher-logo-60px-high.png`,
            width: 380,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals['bunburyheraldBlue'],
        facebookIds: {
            pagesId: '196958470375414',
            appId: '466308333548667',
            clientToken: '487cd218c792ee73ee120352a1701d81',
        },
    },
    'geraldton-guardian': {
        mastheadTopic: 'news/geraldton-guardian',
        regionalTopic: 'news/mid-west',
        title: 'Geraldton Guardian',
        description:
            'The best source for local news, sport, entertainment, weather and opinion in the Mid West region.',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the Mid West region.',
        publicationSource: 'Geraldton Guardian',
        publicUrl: 'https://www.geraldtonguardian.com.au',
        hostname: 'geraldtonguardian.com.au',
        socialLinks: [
            'https://www.facebook.com/geraldtonguardian/',
            'https://www.instagram.com/geraldton_guardian/',
            'https://en.wikipedia.org/wiki/Geraldton_Guardian',
        ],
        contact: {
            phone: '(08) 9956 1000',
            email: 'mailto:editor@geraldtonnewspapers.com.au',
            address: {
                street: '72 Chapman Road',
                city: 'Geraldton',
                state: 'WA',
                postcode: 6530,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/geraldton-guardian-publisher-logo-60px-high.png`,
            width: 312,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals['geraldtonguardianRed'],
        facebookIds: {
            pagesId: '136281783061957',
            appId: '1048562215176154',
            clientToken: '78caf44887046c4c2a500deb26d06985',
        },
    },
    'harvey-waroona-reporter': {
        mastheadTopic: 'news/harvey-waroona-reporter',
        regionalTopic: 'news/south-west',
        title: 'Harvey Waroona Reporter',
        description:
            'The Harvey Waroona Reporter is a community newspaper that provides readers with latest news, current events, sporting results and community information across the Shire of Harvey and Shire of Waroona in Western Australia.',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the Australind, Harvey and upper SW regions.',
        publicationSource: 'Harvey-Waroona Reporter',
        publicUrl: 'https://www.harveyreporter.com.au',
        hostname: 'harveyreporter.com.au',
        socialLinks: ['https://www.facebook.com/HarveyWaroonaReporter/'],
        contact: {
            phone: '(08) 9780 0800',
            email: 'mailto:editor@harveyreporter.com.au',
            address: {
                street: '19 Proffit Street', // same newsroom as Bunbury
                city: 'Bunbury',
                state: 'WA',
                postcode: 6230,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/harvey-waroona-reporter-publisher-logo-60px-high.png`,
            width: 246,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals[
                'harveywaroonareporterGreen'
            ],
        facebookIds: {
            pagesId: '123731461053787',
            appId: '789524757836150',
            clientToken: '0d377593e0b8d6199e056d703bb0ff65',
        },
    },
    'kalgoorlie-miner': {
        mastheadTopic: 'news/kalgoorlie-miner',
        regionalTopic: 'news/goldfields',
        title: 'Kalgoorlie Miner',
        description:
            'The best source for local news, sport, entertainment, weather and opinion in the Goldfields region.',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the Goldfields region.',
        publicationSource: 'Kalgoorlie Miner',
        publicUrl: 'https://www.kalminer.com.au',
        hostname: 'kalminer.com.au',
        socialLinks: [
            'https://www.facebook.com/kalgoorlieminer/',
            'https://en.wikipedia.org/wiki/Kalgoorlie_Miner',
        ],
        contact: {
            phone: '(08) 9022 0555',
            email: 'mailto:news@kalminer.com.au',
            address: {
                street: '127 Hannan Street',
                city: 'Kalgoorlie',
                state: 'WA',
                postcode: 6330,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/kalgoorlie-miner-publisher-logo-60px-high.png`,
            width: 384,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals['kalgoorlieminerRed'],
        facebookIds: {
            pagesId: '180076361744',
            appId: '1503883339935105',
            clientToken: 'cc964e44505880796f501b96294d2453',
        },
    },
    'the-kimberley-echo': {
        mastheadTopic: 'news/the-kimberley-echo',
        regionalTopic: 'news/kimberley',
        title: 'The Kimberley Echo',
        description:
            'The Kimberley Echo serves the communities in the areas of Kununurra, Wyndham, Halls Creek, Warmun, Fitzroy Crossing, Timber Creek, Kalumburu and numerous others. For news and sport from your local area, pick up your copy of The Kimberley Echo each Thursday',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the East Kimberley region.',
        publicationSource: 'The Kimberley Echo',
        publicUrl: 'https://www.kimberleyecho.com.au',
        hostname: 'kimberleyecho.com.au',
        socialLinks: [
            'https://www.facebook.com/KimberleyEcho/',
            'https://twitter.com/kimberleyecho',
        ],
        contact: {
            phone: '(08) 6332 1170',
            email: 'mailto:news@kimberleyecho.com',
            address: {
                street: '2 Pruinosa Street',
                city: 'Kununurra',
                state: 'WA',
                postcode: 6743,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/the-kimberley-echo-publisher-logo-60px-high.png`,
            width: 208,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals['thekimberleyechoRed'],
        facebookIds: {
            pagesId: '403824792978666',
            appId: '898117536909394',
            clientToken: 'b1175acb944aea5a738f6a8710ec3d30',
        },
    },
    'manjimup-bridgetown-times': {
        mastheadTopic: 'news/manjimup-bridgetown-times',
        regionalTopic: 'news/south-west',
        title: 'Manjimup Bridgetown Times',
        description:
            'The Manjimup Bridgetown Times serves the residents of the Blackwood region of Western Australia (from Bridgetown to Walpole, including Manjimup, Pemberton and Northcliffe). The free newspaper has quality news, a community focus and comprehensive sport coverage from the fruit and tall timber capital of WA. It is the first choice for media event sponsorship.',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the Warren Blackwood region.',
        publicationSource: 'Manjimup-Bridgetown Times',
        publicUrl: 'https://www.mbtimes.com.au',
        hostname: 'mbtimes.com.au',
        socialLinks: ['https://www.facebook.com/mbtimes/'],
        contact: {
            phone: '(08) 6332 1640',
            email: 'mailto:editor@mbtimes.com.au',
            address: {
                street: '108-110 Giblett St',
                city: 'Manjimup',
                state: 'WA',
                postcode: 6258,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/manjimup-bridgetown-times-publisher-logo-60px-high.png`,
            width: 588,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals[
                'manjimupbridgetowntimesBlue'
            ],
        facebookIds: {
            pagesId: '131160286960715',
            appId: '112737735748973',
            clientToken: '1e876d7395797e6f754e9c16e88f99af',
        },
    },
    'midwest-times': {
        mastheadTopic: 'news/midwest-times',
        regionalTopic: 'news/mid-west',
        title: 'Midwest Times',
        description:
            'The best source for local news, sport, entertainment, weather and opinion in the broader Midwest and Gascoyne regions.',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the broader Midwest and Gascoyne regions.',
        publicationSource: 'Midwest Times',
        publicUrl: 'https://www.midwesttimes.com.au',
        hostname: 'midwesttimes.com.au',
        socialLinks: ['https://www.facebook.com/MidwestTimesNorthernGuardian/'],
        contact: {
            phone: '(08) 9956 1000',
            email: 'mailto:news@geraldtonnewspapers.com.au', // same newsroom as Geraldton
            address: {
                street: '72 Chapman Road',
                city: 'Geraldton',
                state: 'WA',
                postcode: 6530,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/midwest-times-publisher-logo-60px-high.png`,
            width: 180,
            height: 60,
        },
        pinnedColor: tokens.thewest.colors.palette.regionals['midwesttimesRed'],
        facebookIds: {
            pagesId: '177405299005741',
            appId: '1072560342787396',
            clientToken: 'fc1373e3d2fe495f24477a7a7338352e',
        },
    },
    'narrogin-observer': {
        mastheadTopic: 'news/narrogin-observer',
        regionalTopic: 'news/south-west',
        title: 'Narrogin Observer',
        description:
            'The Narrogin Observer is a weekly publication serving the Narrogin community and surrounding lower Wheatbelt region. It publishes local news, sport and social pictures and has an average readership of 5000 per issue.',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the Great Southern region.',
        publicationSource: 'Narrogin Observer',
        publicUrl: 'https://www.narroginobserver.com.au',
        hostname: 'narroginobserver.com.au',
        socialLinks: ['https://www.facebook.com/narroginobserver/'],
        contact: {
            phone: '(08) 6332 1141',
            email: 'mailto:greatsouthern@wanews.com.au',
            address: {
                street: '49 Clive Street',
                city: 'Katanning',
                state: 'WA',
                postcode: 6317,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/narrogin-observer-publisher-logo-60px-high.png`,
            width: 262,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals['narroginobserverBlue'],
        facebookIds: {
            pagesId: '728505747215994',
            appId: '994674820555572',
            clientToken: '31c65758d045bceda82a56fec46171e3',
        },
    },
    'north-west-telegraph': {
        mastheadTopic: 'news/north-west-telegraph',
        regionalTopic: 'news/pilbara',
        title: 'North West Telegraph',
        description:
            'The Pilbara News and North West Telegraph serve the communities throughout the Pilbara, including Port Hedland, Karratha, Roebourne, Dampier, Tom Price, Paraburdoo, Onslow, Pannawonica, Point Samson, Wickham, South Hedland, Marble Bar, Newman, Nullagine and Wedgefield and points in between. For news and sport from your local area, pick up your copy of the Pilbara News or the North West Telegraph each Wednesday',
        socialDescription:
            'Delivering quality local news, sport, entertainment, weather and opinion in the Pilbara region',
        publicationSource: 'North West Telegraph',
        publicUrl: 'https://www.northwesttelegraph.com.au',
        hostname: 'northwesttelegraph.com.au',
        socialLinks: ['https://www.facebook.com/NorthWestTelegraph/'],
        contact: {
            phone: '(08) 6332 1180',
            email: 'mailto:news@northwesttelegraph.com.au',
            address: {
                street: 'Court Place',
                city: 'South Hedland',
                state: 'WA',
                postcode: 6722,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/north-west-telegraph-publisher-logo-60px-high.png`,
            width: 308,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals['northwesttelegraphOcre'],
        facebookIds: {
            pagesId: '243447092355341',
            appId: '854048564710306',
            clientToken: 'a26e17ba7f0e140b20eb286f9e1a5586',
        },
    },
    'pilbara-news': {
        mastheadTopic: 'news/pilbara-news',
        regionalTopic: 'news/pilbara',
        title: 'Pilbara News',
        description:
            'The Pilbara News and North West Telegraph serve the communities throughout the Pilbara, including Port Hedland, Karratha, Roebourne, Dampier, Tom Price, Paraburdoo, Onslow, Pannawonica, Point Samson, Wickham, South Hedland, Marble Bar, Newman, Nullagine and Wedgefield and points in between. For news and sport from your local area, pick up your copy of the Pilbara News or the North West Telegraph each Wednesday',
        socialDescription:
            'Delivering quality local news, sport, entertainment, weather and opinion in the Pilbara region',
        publicationSource: 'Pilbara News',
        publicUrl: 'https://www.pilbaranews.com.au',
        hostname: 'pilbaranews.com.au',
        socialLinks: [
            'https://www.facebook.com/PilbaraNews/',
            'https://twitter.com/pilbaranews',
        ],
        contact: {
            phone: '(08) 6332 1400',
            email: 'mailto:news@pilbaramedia.com.au',
            address: {
                street: '31 Bond Place',
                city: 'Karratha',
                state: 'WA',
                postcode: 6714,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/pilbara-news-publisher-logo-60px-high.png`,
            width: 306,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals['pilbaranewsOrange'],
        facebookIds: {
            pagesId: '191365300926857',
            appId: '862935553817720',
            clientToken: '244f5ae775073b635902842a0203cbb3',
        },
    },
    'sound-telegraph': {
        mastheadTopic: 'news/sound-telegraph',
        regionalTopic: 'news/peel-rockingham',
        title: 'Sound Telegraph',
        description:
            'The Sound Telegraph is the leading source of breaking news and shares the stories of people in Rockingham, Kwinana, Safety Bay, Baldivis, Mandurah and Peel.  Keep abreast with the important issues in your community, be part of region-wide discussions and share your thoughts on local government mergers, the development of Mangles Bay Marina and Point Peron, the highs and lows of Kwinana’s industries and other topics.',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the Peel Rockingham region.',
        publicationSource: 'Sound Telegraph',
        publicUrl: 'https://www.soundtelegraph.com.au',
        hostname: 'soundtelegraph.com.au',
        socialLinks: ['https://www.facebook.com/SoundTelegraph/'],
        contact: {
            phone: '(08) 9592 0200',
            email: 'mailto:editor@soundtelegraph.com.au',
            address: {
                street: '2/96 Pinjarra Rd',
                city: 'Mandurah',
                state: 'WA',
                postcode: 6210,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/sound-southern-telegraph-publisher-logo-60px-high.png`,
            width: 224,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals['soundtelegraphBlue'],
        facebookIds: {
            pagesId: '339816112704144',
            appId: '1646576942280716',
            clientToken: 'bae9a5b18093cd619a6d6a6b9566240e',
        },
    },
    'south-western-times': {
        mastheadTopic: 'news/south-western-times',
        regionalTopic: 'news/south-west',
        title: 'South Western Times',
        description:
            'The Southwestern Times serves the residents of the Bunbury and South West regions of Western Australia (from Harvey to Augusta, including Collie, Bunbury, Busselton, Dunsborough, Margaret River, Nannup, Manjimup, Pemberton, Northcliffe and Walpole). The newspaper has quality news, a community focus and comprehensive sport coverage from the fruit, tourism, wine and surfing capital of WA. It is the first choice for media event sponsorship.',
        socialDescription:
            'The best source for local news, sport, entertainment, weather and opinion in the Bunbury and SW regions.',
        publicationSource: 'South Western Times',
        publicUrl: 'https://www.swtimes.com.au',
        hostname: 'swtimes.com.au',
        socialLinks: [
            'https://www.facebook.com/SouthWesternTimes/',
            'https://twitter.com/swtimes',
            'https://www.instagram.com/swtimesnews/',
            'https://en.wikipedia.org/wiki/South_Western_Times',
        ],
        contact: {
            phone: '(08) 9780 0800',
            email: 'mailto:editor@swtimes.com.au',
            address: {
                street: '19 Proffit Street', // same newsroom as Bunbury
                city: 'Bunbury',
                state: 'WA',
                postcode: 6230,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/south-western-times-publisher-logo-60px-high.png`,
            width: 590,
            height: 60,
        },
        pinnedColor:
            tokens.thewest.colors.palette.regionals['southwesterntimesRed'],
        facebookIds: {
            pagesId: '127899237254997',
            appId: '498285150334591',
            clientToken: '73f143ca9b1d743d302d8b9c46ffb63b',
        },
    },
    countryman: {
        mastheadTopic: 'countryman',
        regionalTopic: 'countryman',
        title: 'Countryman',
        description:
            'The voice of the bush since 1885, Countryman provides WA’s best and most comprehensive agricultural news, with dedicated sections for news, livestock, wool, opinion, machinery, real estate, and social pictures. Our weekly newspaper offers up-to-date coverage of news and events, providing a powerful voice for those living in country WA.',
        socialDescription:
            'Countryman is the best source for agricultural news in WA.',
        publicationSource: 'Countryman',
        publicUrl: 'https://www.countryman.com.au',
        hasMatchingFirstLevelTopic: true,
        hostname: 'countryman.com.au',
        socialLinks: [
            'https://www.facebook.com/CountrymanAU',
            'https://en.wikipedia.org/wiki/Western_Mail_(Western_Australia)',
        ],
        contact: {
            phone: '(08) 9482 9708',
            email: 'mailto:countryman@wanews.com.au',
            address: {
                street: 'Newspaper House, 50 Hasler Road',
                city: 'Osborne Park',
                state: 'WA',
                postcode: 6017,
            },
        },
        publisherLogo: {
            path: `/static/publisher-logos/countryman-publisher-logo-60px-high.png`,
            width: 348,
            height: 60,
        },
        pinnedColor: tokens.thewest.colors.palette.regionals['countrymanRed'],
        facebookIds: {
            pagesId: '201006956589815',
            appId: '4371644609572464',
            clientToken: 'be97c8c061c16f03ee835f0643e6026f',
        },
    },
}

function getRegionalPublisherLogo(
    section: TheWestRegionalSections,
): ShareImage {
    const img = regionalSiteConfig[section].publisherLogo

    return {
        height: img.height,
        width: img.width,
        image: `${regionalSiteConfig[section].publicUrl}${regionalSiteConfig[section].publisherLogo.path}`,
    }
}

export function mapAddressStructuredData(address: {
    street: string
    city: string
    state: string
    postcode: number
}): OrganizationAddress {
    return {
        addressCountry: 'AU',
        addressLocality: address.city,
        addressRegion: address.state as 'WA' | 'NSW',
        postalCode: address.postcode,
        streetAddress: address.street,
    }
}

export function shortOrganisationLd(
    sectionInfo: SectionMetaInfoDTO,
    publicUrl: string,
) {
    return {
        '@type': 'Organization',
        name: sectionInfo.Title,
        url: publicUrl,
        logo: {
            '@type': 'ImageObject',
            url: sectionInfo.PublisherLogo.image,
            width: String(sectionInfo.PublisherLogo.width),
            height: String(sectionInfo.PublisherLogo.height),
        },
    }
}
