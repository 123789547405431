import {
    AppState,
    AuthenticationState,
    THEWEST_ALL,
} from '@news-mono/web-common'
import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../../buttons/Button/Button'
import { useAuthActions } from '../../user-registration/authentication/AuthenticationProvider'
import { ThemeMargins } from '../../__styling/settings/metrics'
import {
    StyledIconPremium,
    StyledMessageBannerSmall,
    StyledMessageBannerGroup,
    StyledMessageBannerWrapper,
    StyledMessageCTA,
    StyledSubscriberMessage,
    StyledButton,
    StyleMessageCTAIconBannerGroup,
} from './MessageBannerSmall.styled'

export type ArrowStyle = 'solid-arrow'

export interface MessageBannerSmallProps {
    icon?: 'comment' | 'subscriber'
    id?: string
    verticalSpacing?: keyof ThemeMargins | undefined
    onEvent: (event: any) => void
    connector?: ArrowStyle
}

export const MessageBannerSmall: React.FC<MessageBannerSmallProps> = ({
    icon,
    id,
    children,
    verticalSpacing,
    onEvent,
    connector,
}) => {
    const { onLoginClick } = useAuthActions()
    const { entitlements, isLoggedIn } = useSelector<
        AppState,
        AuthenticationState
    >((state) => state.authentication)

    const returningUser = isLoggedIn && !entitlements?.includes(THEWEST_ALL)

    return (
        <StyledMessageBannerSmall
            id={id}
            verticalSpacing={verticalSpacing}
            connector={connector}
        >
            <StyledMessageBannerWrapper>
                <StyleMessageCTAIconBannerGroup>
                    {icon === 'subscriber' && <StyledIconPremium />}
                    <StyledMessageCTA>{children}</StyledMessageCTA>
                </StyleMessageCTAIconBannerGroup>
                {!returningUser && (
                    <StyledMessageBannerGroup>
                        <StyledSubscriberMessage>
                            Already a subscriber?
                        </StyledSubscriberMessage>
                        <StyledButton
                            onClick={onLoginClick}
                        >
                            Login here.
                        </StyledButton>
                    </StyledMessageBannerGroup>
                )}
            </StyledMessageBannerWrapper>
        </StyledMessageBannerSmall>
    )
}

MessageBannerSmall.displayName = 'MessageBannerSmall'
