import styled from '@emotion/styled'
import { breakpoint, calcRem, fonts, metrics } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'

export interface StyledButtonProps {
    forceUppercase?: boolean
    useBorder?: boolean
    backgroundColors?: { default: string; hover: string }
    roundEdges?: boolean
    marginBottom?: number
    useWidePadding?: boolean
    fullWidthButton?: boolean
}
export const StyledWardenButton = styled('button')<StyledButtonProps>(
    ({
        forceUppercase = true,
        useBorder = false,
        backgroundColors = undefined,
        roundEdges = false,
        marginBottom,

        useWidePadding = false,
        fullWidthButton = false,
    }) => ({
        fontFamily: fonts.thenightly.sansSerif,
        fontWeight: 600,
        fontSize: calcRem(16),
        lineHeight: calcRem(24),
        color: tokens.thenightly.colors.palette.neutral[0],
        textTransform: forceUppercase ? 'uppercase' : undefined,

        display: 'flex',
        alignItems: 'center', // handle up-down center
        justifyContent: 'center', // handle left-right center
        cursor: 'pointer',

        // Handles use of wide padding
        padding: calcRem(
            metrics.thenightly.margins.sm2l,
            useWidePadding
                ? metrics.thenightly.margins.m2l
                : metrics.thenightly.margins.sm2l,
        ),
        margin: useWidePadding
            ? `${calcRem(metrics.thenightly.margins.m2l)} auto ${calcRem(
                  metrics.thenightly.margins.md,
              )} auto`
            : 0,

        // handle of specific margin bottom values
        marginBottom: marginBottom ? calcRem(marginBottom) : undefined,

        border: useBorder
            ? `${calcRem(1)} solid ${
                  tokens.thenightly.colors.palette.neutral[0]
              }`
            : 'none',
        borderRadius: roundEdges ? calcRem(8) : undefined,

        // Normal background color
        backgroundColor: backgroundColors
            ? backgroundColors.default
            : tokens.thenightly.colors.actions.button.primary.background
                  .default,

        // Active/Focus/Hover background color
        '&:hover, &:focus, &:active': {
            backgroundColor: backgroundColors
                ? backgroundColors.hover
                : tokens.thenightly.colors.actions.button.primary.background
                      .hover,
        },

        // Anything small than xxs
        width: useWidePadding || fullWidthButton ? '100%' : undefined,

        // Anything bigger than or equal to Surface Duo
        [breakpoint('xxs')]: {
            width: fullWidthButton
                ? '100%'
                : useWidePadding
                ? 'fit-content'
                : undefined,
        },
    }),
)

StyledWardenButton.displayName = 'StyledWardenButton'
export default StyledWardenButton
