import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    AppState,
    BaseClientConfig,
    clipStringToLength,
    GetRouteAdTargeting,
    PageType,
} from '@news-mono/web-common'
import { ProfileV4DTO } from '@west-australian-newspapers/publication-types'
import H from 'history'
import { AnyAction, Store } from 'redux'
import { layout } from '../../App.routing'
import { billboardLeaderboardMrec } from '../ad-units'

export interface Props {
    profile: ProfileV4DTO
    config: BaseClientConfig
    getAdTargeting: GetRouteAdTargeting
    location: H.Location<any>
    section: TheWestSection
    store: Store<AppState, AnyAction>
}

export const getProfilePageRouteInfo = ({
    profile,
    section,
    config,
    getAdTargeting,
    location,
}: Props): PageType<TheWestSection> => {
    const adTargeting = getAdTargeting('profile', section, profile.slug)
    const profileUrl = `${config.publicUrl}${location.pathname}`

    // Profiles need point back to the author's canonical site
    const canonicalUrl = profile.canonicalSite
        ? `${profile.canonicalSite}${location.pathname}`
        : profileUrl

    return {
        kind: 'page',
        heading: 'Profiles',
        hideHeading: true,
        pageType: 'profile',
        pageMeta: {
            title: `${profile.name} | ${profile.position}`,
            description: profile.biography
                ? clipStringToLength(profile.biography, 160)
                : 'Journalist profile from The West Australian',
            link: [
                {
                    rel: 'canonical',
                    href: canonicalUrl,
                },
            ],
            ...(profile.mainPhoto && {
                meta: [
                    {
                        property: 'og:image',
                        content: profile.mainPhoto,
                    },
                    {
                        name: 'twitter:image',
                        content: profile.mainPhoto,
                    },
                    {
                        property: 'og:image:height',
                        content: '861',
                    },
                    {
                        property: 'og:image:width',
                        content: '1200',
                    },
                ],
            }),
        },
        socialMeta: {
            title: `${profile.name} | ${profile.position}`,
            description: profile.biography
                ? clipStringToLength(profile.biography, 160)
                : 'Journalist profile from The West Australian',
        },
        adTargeting,
        section,
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: 'outerMargin',
                    verticalGutters: ['outerMargin', 'outerMargin'],
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'profile',
                            props: { profile, profileUrl },
                        }),
                        layout.component({
                            type: 'lima',
                            props: {
                                fixedRatios: ['16:9'],
                                verticalSpacing: 'md',
                                hasBackground: true,
                                /*
                                 * ITSD-50441: This is a specific tweak to Matt Birney's profile
                                 * so that Bulls N Bears articles don't appear as sponosred items.
                                 * When they appear on the collection it mentions that they're sponsored
                                 * and the article itself appears as sponsored.
                                 */
                                isSponsoredCollection:
                                    profile.slug === 'matt-birney',

                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: [],
                                    authors: [profile.slug],
                                    paging: {
                                        page: 1,
                                        pageSize: 12,
                                    },
                                },
                            },
                        }),
                        ...billboardLeaderboardMrec(
                            'one',
                            metrics.thewest.margins.md,
                        ),
                    ],
                },
            }),
        ],
    }
}
