import { VideoConfig } from '@news-mono/web-common'
import { VideoOptions } from '../PlayerInterface'
import { RuntimeVideoConfig } from '../providers/PlayerProps'

/**
 * Converts the required client config into a base64 encoded stringified json object
 * @param config a BaseClientConfig object
 */
export function encodePlayerConfigToJSON(params: {
    posterFrameImageSrc: string | undefined
    videoOptions: VideoOptions
    config: VideoConfig & RuntimeVideoConfig
}): string {
    const { posterFrameImageSrc, config, videoOptions } = params
    const { enableNielsenVideoReporting, enableSnowplow, enablePrebid } =
        videoOptions
    const {
        moatVideoPartnerCode,
        integralPlacementLiveId,
        integralPlacementVodId,
        integralAnId,
        parselyApikey,
        prebidDebug,
        prebidAppNexusId,
        prebidIxId,
    } = config

    // Swm player team needs capitialized snake case
    const swmConfig = {
        MOAT_PARTNER_CODE: moatVideoPartnerCode,
        NIELSEN_API_KEY: config.nielsenApid,
        DISABLE_NIELSEN: !enableNielsenVideoReporting,
        INTEGRAL_PLACEMENT_LIVE_ID: integralPlacementLiveId,
        INTEGRAL_PLACEMENT_VOD_ID: integralPlacementVodId,
        INTEGRAL_AN_ID: integralAnId,
        DISABLE_POSTCODE_FETCH: true, // Hard coded just like the Web SwmPlayer
        [`DISABLE_SNOWPLOW`]: !enableSnowplow,
        DISABLE_PREBID: !enablePrebid,
        PARSELY_API_KEY: parselyApikey,
        POSTER_IMAGE: posterFrameImageSrc,
        PREBID_DEBUG: prebidDebug,
        PREBID_APPNEXUS_PLACEMENT_ID: prebidAppNexusId,
        PREBID_IX_SITE_ID: prebidIxId,
    }

    const swmConfigJsonString = JSON.stringify(swmConfig)

    // Amp pages are typically rendered server side, btoa() only exists in the browser so we'll use a Buffer to encode to base64
    if (typeof window === 'undefined') {
        return Buffer.from(swmConfigJsonString).toString('base64')
    }
    return btoa(swmConfigJsonString)
}
