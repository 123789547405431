import { differenceInHours, differenceInMinutes, format } from 'date-fns'

// Time to display 'Just Now' in minutes
const JUST_NOW_DELTA = 15
const JUST_NOW_MESSAGE = 'Just now'

export const getReadableTimeSince = (
    publicationDateString: string,
    comparisonDateString: string,
): string => {
    const comparisonDate = new Date(comparisonDateString)
    const publicationDate = new Date(publicationDateString)
    const deltaInMinutes = differenceInMinutes(comparisonDate, publicationDate)

    // Don't display anything if the published date is in the future
    if (deltaInMinutes < 0) return ''

    if (deltaInMinutes <= JUST_NOW_DELTA) {
        return JUST_NOW_MESSAGE
    }

    // Display message if time since published is under an hour
    if (deltaInMinutes < 60) {
        return `${deltaInMinutes} mins ago`
    }

    // Display message if time since published is under a day
    const deltaInHours = differenceInHours(comparisonDate, publicationDate)

    if (deltaInHours === 1) {
        return `${deltaInHours} hour ago`
    }

    if (deltaInHours <= 24) {
        return `${deltaInHours} hours ago`
    }

    // Display date if time since published is more than a day
    return format(publicationDate, 'dd MMM yyyy')
}
