import { EventV4DTO, ListEventV4DTO, ListEventWithEntriesV4DTO } from '.'
import { CopyrightCaption, CropDTO } from '../asset'
import { ArticleV4DTO, ListArticleV4DTO } from './article'
import { GalleryV4DTO, ListGalleryV4DTO } from './gallery'

export type CanonicalPublication =
    | 'thewest'
    | 'perthnow'
    | 'sevennews'
    | 'thenightly'
    | 'neutral'

export type LayoutViewV4DTO = 'default' | 'feature' | 'simple'

export interface TopicV4DTO {
    id: string
    title: string
    parent?: TopicV4DTO
    metadata: { [key: string]: string }
    seoTitle: string
    seoDescription?: string
}

export type AllPublicationsV4DTO =
    | ListArticleV4DTO
    | ArticleV4DTO
    | ListGalleryV4DTO
    | GalleryV4DTO
    | EventV4DTO
    | ListEventV4DTO

export type PublicationV4DTO = ArticleV4DTO | GalleryV4DTO | EventV4DTO

export type ListPublicationV4DTO =
    | ListArticleV4DTO
    | ListGalleryV4DTO
    | ListEventV4DTO

export type ListPublicationWithEntriesV4DTO =
    | ListArticleV4DTO
    | ListGalleryV4DTO
    | ListEventWithEntriesV4DTO

export interface ImageV4DTO extends CropDTO, Partial<CopyrightCaption> {
    src: string
}

export interface ImageWithCropsV4DTO extends Partial<CopyrightCaption> {
    original: CropDTO
    crops: CropDTO[]
}

export interface RelatedLinkV4DTO {
    reference: string
    text: string
    publication: ListPublicationV4DTO
}

export interface ProfileV4DTO {
    name: string
    slug: string
    email: string | undefined
    position: string | undefined
    biography: string | undefined
    mainPhoto: string | undefined
    thumbnailPhoto: string | undefined
    twitter: string | undefined
    facebook: string | undefined
    instagram: string | undefined
    canonicalSite: string | undefined
}

export interface ClassificationV4DTO {
    kind: string
    label: string
}

export type RequiredAccessLevel = 'anonymous' | 'registered' | 'subscriber'

export interface RequiredAccessDTO {
    level: RequiredAccessLevel
}

export function isArticleLikeList(
    document: ListPublicationV4DTO,
): document is ListArticleV4DTO | ListEventWithEntriesV4DTO {
    return document.kind === 'article' || document.kind === 'event'
}

export function isArticleLikePublication(
    publication: PublicationV4DTO,
): publication is ArticleV4DTO | EventV4DTO {
    return publication.kind === 'article' || publication.kind === 'event'
}
