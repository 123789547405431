import React from 'react'
import { UserSettingProps } from '../../../user-setting'
import { CreateAccountCTA } from '../../warden/account-modify'
import { LoginContent } from './LoginContent/LoginContent'
import { SaveNameContent } from './SaveNameContent/SaveNameContent'
import { VerifyEmailContent } from './VerifyEmailContent/VerifyEmailContent'

export type PNCommentsCTAProps = {
    userSettings: UserSettingProps
    isFeatureEnabled: boolean
}

export const PNCommentsCTA = ({
    userSettings,
    isFeatureEnabled,
}: PNCommentsCTAProps) => (
    <CreateAccountCTA
        userSettings={userSettings}
        isFeatureEnabled={isFeatureEnabled}
        LoginContent={LoginContent}
        VerifyEmailContent={VerifyEmailContent}
        SaveNameContent={({ userId }) => (
            <SaveNameContent userId={userId} userSettings={userSettings} />
        )}
    />
)
