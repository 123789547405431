import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import {
    LimaRouteProps,
    metrics,
    ResponsivePictureLayout,
    SierraRouteProps,
    ThorRouteProps,
} from '@news-mono/component-library'
import {
    AdTargeting,
    BaseClientConfig,
    ContentDataTypes,
    getResolvedTopicPageMeta,
    GetRouteAdTargeting,
    PageType,
    StaticRoutes,
    Store,
    Topic,
} from '@news-mono/web-common'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../App.routing'
import { getAFLDraftRouteInfo } from './custom/get-afl-draft-route'
import { getAFLRouteInfo } from './custom/get-afl-route-info'
import { getBreakfastBrunchRouteInfo } from './custom/get-breakfast-brunch-route'
import { getBusinessRouteInfo } from './custom/get-business-route-info'
import { getCarOfTheYear2019RouteInfo } from './custom/get-car-of-the-year-2019-route-info'
import { getCarOfTheYear2020RouteInfo } from './custom/get-car-of-the-year-2020-route-info'
import { getClaremontSerialKillingsRouteInfo } from './custom/get-claremont-serial-killings-route-info'
import { getCleoRouteInfo } from './custom/get-cleo-route-info'
import { getCommonwealthGamesRouteInfo } from './custom/get-commonwealth-games-route-info'
import { getCricketRouteInfo } from './custom/get-cricket-route-info'
import { getEntertainmentRouteInfo } from './custom/get-entertainment-route-info'
import { getFoodRouteInfo } from './custom/get-food-route-info'
import { getGenwestRouteInfo } from './custom/get-genwest-route-info'
import { getGerardRossRouteInfo } from './custom/get-gerard-ross-route-info'
import { getIndigenousAustraliansRouteInfo } from './custom/get-indigenous-australians-route-info'
import { getInsideCoverRouteInfo } from './custom/get-insidecover-route-info'
import { getKerryStokesCollectionRouteInfo } from './custom/get-kerry-stokes-collection-route-info'
import { getKingsCoronationRouteInfo } from './custom/get-kings-coronation-route-info'
import { getLatestRouteInfo } from './custom/get-latest-route-info'
import { getLifestyleNewHomesRouteInfo } from './custom/get-lifestyle-new-homes-route-info'
import { getLifestyleRouteInfo } from './custom/get-lifestyle-route-info'
import { getLiveSportRouteInfo } from './custom/get-live-sport-route-info'
import { getNewsRouteInfo } from './custom/get-news-route-info'
import { getOpinionRouteInfo } from './custom/get-opinion-route-info'
import { getPoliticsRoute } from './custom/get-politics-route'
import { getRegionalRouteInfo } from './custom/get-regional-route-info'
import { getRTS2019RouteInfo } from './custom/get-rts-2019-route'
import { getSportRouteInfo } from './custom/get-sport-route-info'
import { getSubscriberFeaturesRouteInfo } from './custom/get-subscriber-features-route-info'
import { getTechnologyRouteInfo } from './custom/get-technology-route-info'
import { getTravelRouteInfo } from './custom/get-travel-route-info'
import { getWAFLRouteInfo } from './custom/get-wafl-route-info'
import { getWorldRouteInfo } from './custom/get-world-route-info'
import { getYourMoneyRouteInfo } from './custom/get-your-money-route-info'
import { getTopicPageRouteInfo } from './get-topic-page-route-info'
import { getTopicSponsor } from './get-topic-sponsor'
import { topicParallax } from './topic-parallax'
import { getOlympicsRouteInfo } from './custom/get-olympics-route-info'

const assetFolder = './custom/assets/subscriber-exclusive/'
const desktopBanner = require<string>(`${assetFolder}Subscriber-Exclusive-Desktop-Banner-2440x300px.jpg`)
const tabletBanner = require<string>(`${assetFolder}Subscriber-Exclusive-Tablet-Banner1476x114px.jpg`)
const mobileBanner = require<string>(`${assetFolder}Subscriber-Exclusive-Mobile-Banner-822x186px.jpg`)
const bannerDesktopX2 = require<string>(`${assetFolder}2023-Sub-Exclusive-Feature-Banner-lg@2x.jpg`)
const bannerDesktopX1 = require<string>(`${assetFolder}2023-Sub-Exclusive-Feature-Banner-lg@1x.jpg`)
const bannerTabletX2 = require<string>(`${assetFolder}2023-Sub-Exclusive-Feature-Banner-md@2x.jpg`)
const bannerTabletX1 = require<string>(`${assetFolder}2023-Sub-Exclusive-Feature-Banner-md@1x.jpg`)
const bannerMobileX2 = require<string>(`${assetFolder}2023-Sub-Exclusive-Feature-Banner-sm@2x.jpg`)
const bannerMobileX1 = require<string>(`${assetFolder}2023-Sub-Exclusive-Feature-Banner-sm@1x.jpg`)

const getSocialImageMeta = (src: string, height: string, width: string) => {
    return [
        {
            property: 'og:image',
            content: src,
        },
        { name: 'twitter:image', content: src },
        {
            property: 'og:image:height',
            content: height,
        },
        {
            property: 'og:image:width',
            content: width,
        },
    ]
}

interface TopicSectionBuilderHooks {
    sierra?: SierraRouteProps & { dataDefinitionArgs: ContentDataTypes }
    lima?: LimaRouteProps & { dataDefinitionArgs: ContentDataTypes }
}

export interface TopicSectionBuilder {
    getCuratedSection: (
        hooks?: TopicSectionBuilderHooks,
        isGenwest?: Boolean,
    ) => Array<CheckedComponentInformation>
}

export type GetMainSectionFn = (
    topicSectionBuilder: TopicSectionBuilder,
    store?: Store,
) => Array<CheckedComponentInformation>

const createPage = ({
    topic,
    pageTitle,
    getMainSection,
    curationName,
    section = 'default',
    getAdTargeting,
    hideBreadcrumb,
    thorPropOverrides,
    removeBottomMarginBreakingNews,
    store,
    hideDefaultComponents = false,
    showFixtureScoreboard,
}: {
    config: BaseClientConfig
    topic: Topic
    pageTitle: string
    getMainSection: GetMainSectionFn
    curationName?: string
    section?: TheWestSection
    getAdTargeting: GetRouteAdTargeting
    hideBreadcrumb?: boolean
    thorPropOverrides?: ThorRouteProps
    removeBottomMarginBreakingNews?: boolean
    store?: Store
    hideDefaultComponents?: boolean
    showFixtureScoreboard?: boolean
}): PageType<TheWestSection> => {
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        section,
        topic,
    )

    const adTargeting: AdTargeting = {
        pageId: topic.title,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    const getCuratedSection: TopicSectionBuilder['getCuratedSection'] = (
        hooks,
        isGenwest,
    ) => {
        const sierraHookProps = hooks ? hooks.sierra || {} : {}
        const limaHookProps = hooks ? hooks.lima || {} : {}

        return [
            layout.component({
                type: 'sierra',
                props: {
                    verticalSpacing: 'gridGap',
                    fixedRatios: ['16:9'],
                    heroImageLayout: ResponsivePictureLayout.ObjectFitContain,
                    cardOrientation: {
                        type: 'Landscape-Portrait',
                        maxBreakpoint: 'md',
                    },
                    cardLayout: ['hero', 'teaser-xsVisible-mdHidden-Image'],

                    dataDefinitionArgs: {
                        type: 'curation',
                        name: curationName || topic.id,
                        offset: 0,
                        pageSize: 2,
                    },
                    ...sierraHookProps,
                },
            }),
            layout.component({
                type: 'lima',
                props: {
                    verticalSpacing: 'xl',
                    fontScale: 1.2,
                    fixedRatios: ['16:9'],
                    hasBackground: true,
                    initialColumns: 1,
                    intermediateColumns: 2,
                    finalColumns: 4,
                    teaserMode: 'visible',
                    cardOrientation: { type: 'Landscape-Portrait' },

                    dataDefinitionArgs: {
                        type: 'curation',
                        name: curationName || topic.id,
                        offset: 2,
                        pageSize: 4,
                    },
                    ...limaHookProps,
                },
            }),
            ...(isGenwest ? [] : topicParallax()),
        ]
    }

    const defaultComponents: CheckedComponentInformation[] =
        hideDefaultComponents
            ? []
            : [
                  layout.component({
                      type: 'breaking-news',
                      props: {
                          removeBottomMargin: removeBottomMarginBreakingNews,
                          dataDefinitionArgs: {
                              type: 'curation',
                              name: 'breaking-news',
                              offset: 0,
                              pageSize: 1,
                          },
                      },
                  }),
                  layout.component({
                      type: 'breaking-news-promo-strap',
                      props: {
                          dataDefinitionArgs: {
                              type: 'curation',
                              name: 'breaking-news-promo-strap',
                              offset: 0,
                              pageSize: 1,
                          },
                      },
                  }),
              ]

    if (!hideBreadcrumb) {
        defaultComponents.push(
            layout.component({
                type: 'breadcrumb',
                props: {
                    topic,
                    sponsor: getTopicSponsor(topic),
                    enableHeaderTag: true,
                },
            }),
        )
    }

    if (showFixtureScoreboard) {
        defaultComponents.push(
            layout.component({
                type: 'afl-fixtures-scoreboard',
                feature: 'the-west-afl-fixture-scoreboard',
                props: {},
            }),
        )
    }

    const socialImageUrl = topic?.metadata?.socialImageUrl
    const socialImageHeight = topic?.metadata?.socialImageHeight
    const socialImageWidth = topic?.metadata?.socialImageWidth

    return {
        kind: 'page',
        heading: pageTitle,
        pageType: 'topic',
        adTargeting,
        hideHeading: true,
        includeFromSectionInMetaDescription: !topic.seoDescription,
        pageMeta: {
            description:
                topic.seoDescription || `The Latest in ${topic.seoTitle}`,
            title: topic.seoTitle || topic.title,
            meta: getSocialImageMeta(
                socialImageUrl ||
                    '/static/share-images/share-400x400.png?imwidth=1024',
                socialImageHeight || '400',
                socialImageWidth || '400',
            ),
        },
        socialMeta: {
            title: topic.seoTitle || topic.title,
            description:
                topic.seoDescription || `The Latest in ${topic.seoTitle}`,
        },
        section,
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: 'outerMargin',
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: defaultComponents,
                },
            }),
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                    ...thorPropOverrides,
                },
                contentAreas: {
                    main: getMainSection({ getCuratedSection }, store),
                },
            }),
        ],
    }
}

export const customTopicRoutes: StaticRoutes<TheWestSection> = {
    '/latest': getLatestRouteInfo({
        id: 'latest',
        title: 'Latest News',
        seoTitle: 'Latest News',
        metadata: {},
    }),
    '/features': ({ config, getAdTargeting, store }) =>
        createCustomPage({
            config,
            topic: {
                id: 'features',
                title: 'Subscriber Exclusive',
                seoTitle: 'Subscriber Exclusive',
                metadata: {},
            },
            hideBreadcrumb: true,
            pageTitle: 'Subscriber Exclusive',
            getMainSection: getSubscriberFeaturesRouteInfo,
            curationName: 'features',
            getAdTargeting,
            store,
        }),
    '/business': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'business',
                title: 'Business',
                seoTitle: 'Business',
                metadata: {},
            },
            pageTitle: 'Business',
            getMainSection: getBusinessRouteInfo,
            getAdTargeting,
        }),
    '/entertainment': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'entertainment',
                title: 'Entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
            pageTitle: 'Entertainment',
            getMainSection: getEntertainmentRouteInfo,
            getAdTargeting,
        }),
    '/genwest': ({ config, getAdTargeting, store, resolution }) => {
        const genWestEnabled = store.getState().toggles['genwest']

        const genWestTopic: Topic = {
            id: getResolvedTopicPageMeta(resolution)?.id || 'genwest',
            metadata: {
                socialImageUrl: `${config.publicUrl}/static/share-images/share-genwest-1920x1080.jpg`,
                socialImageHeight: '1080',
                socialImageWidth: '1920',
            },
            title:
                getResolvedTopicPageMeta(resolution)?.title ||
                getResolvedTopicPageMeta(resolution)?.seoTitle ||
                '',
            seoTitle: getResolvedTopicPageMeta(resolution)?.seoTitle || '',
            seoDescription:
                getResolvedTopicPageMeta(resolution)?.seoDescription || ``,
        }

        return genWestEnabled
            ? createPage({
                  config,
                  topic: genWestTopic,
                  pageTitle: 'GenWest',
                  getMainSection: getGenwestRouteInfo,
                  getAdTargeting,
                  store,
                  section: 'genwest',
                  hideDefaultComponents: true,
              })
            : null
    },
    '/lifestyle': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'lifestyle',
                title: 'Lifestyle',
                seoTitle: 'Lifestyle',
                metadata: {},
            },
            pageTitle: 'Lifestyle',
            getMainSection: getLifestyleRouteInfo,
            getAdTargeting,
        }),
    '/lifestyle/food': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'lifestyle/food',
                title: 'Food',
                seoTitle: 'Food',
                metadata: {
                    socialImageUrl: `${config.publicUrl}/static/share-images/Food-and-Wine-Social-Banner-1200x630.jpg`,
                    socialImageHeight: '630',
                    socialImageWidth: '1200',
                },
            },
            pageTitle: 'Food',
            getMainSection: getFoodRouteInfo,
            curationName: 'food',
            getAdTargeting,
            hideBreadcrumb: true,
        }),
    '/news': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'news',
                title: 'News',
                seoTitle: 'News',
                metadata: {},
            },
            pageTitle: 'News',
            getMainSection: getNewsRouteInfo,
            getAdTargeting,
        }),
    '/opinion': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'opinion',
                title: 'Opinion',
                seoTitle: 'Opinion',
                metadata: {},
            },
            pageTitle: 'Opinion',
            getMainSection: getOpinionRouteInfo,
            getAdTargeting,
        }),
    '/opinion/inside-cover': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'opinion/inside-cover',
                title: 'Inside Cover',
                seoTitle: 'Inside Cover',
                metadata: {},
            },
            pageTitle: 'Inside Cover',
            getMainSection: getInsideCoverRouteInfo,
            getAdTargeting,
        }),
    '/lifestyle/new-homes': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'lifestyle/new-homes',
                title: 'New Homes',
                seoTitle: 'New Homes',
                metadata: {},
            },
            pageTitle: 'New Homes',
            getMainSection: getLifestyleNewHomesRouteInfo,
            getAdTargeting,
        }),
    '/lifestyle/perth-breakfast-brunch': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'lifestyle/perth-breakfast-brunch',
                title: 'Perth Breakfast and Brunch',
                seoTitle: 'Perth Breakfast and Brunch',
                metadata: {},
            },
            pageTitle: 'Perth Breakfast and Brunch',
            getMainSection: getBreakfastBrunchRouteInfo,
            getAdTargeting,
        }),
    '/politics': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'politics',
                title: 'Politics',
                seoTitle: 'Politics',
                metadata: {},
            },
            pageTitle: 'Politics',
            getMainSection: getPoliticsRoute,
            getAdTargeting,
        }),
    '/sport': ({ config, getAdTargeting, store }) => {
        return createPage({
            config,
            topic: {
                id: 'sport',
                title: 'Sport',
                seoTitle: 'Sport',
                metadata: {},
            },
            pageTitle: 'Sport',
            getMainSection: getSportRouteInfo,
            getAdTargeting,
            hideBreadcrumb: true,
            thorPropOverrides: {
                verticalGutters: 'unset',
            },
            store,
            showFixtureScoreboard: true,
        })
    },
    '/technology': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'technology',
                title: 'Technology',
                seoTitle: 'Technology',
                metadata: {},
            },
            pageTitle: 'Technology',
            getMainSection: getTechnologyRouteInfo,
            getAdTargeting,
        }),
    '/travel': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'travel',
                title: 'Travel',
                seoTitle: 'Travel',
                metadata: {},
            },
            pageTitle: 'Travel',
            getMainSection: getTravelRouteInfo,
            getAdTargeting,
        }),
    '/news/regional': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'news/regional',
                title: 'Regional',
                seoTitle: 'Regional',
                metadata: {},
            },
            pageTitle: 'Regional',
            getMainSection: getRegionalRouteInfo,
            curationName: 'regional',
            getAdTargeting,
        }),
    '/news/world': ({ config, getAdTargeting, store }) => {
        if (
            !isFeatureEnabled(
                toFeatureState(store.getState().toggles),
                'world-collection',
            )
        ) {
            return null
        }
        return createPage({
            config,
            topic: {
                id: 'news/world',
                title: 'World',
                seoTitle: 'World',
                metadata: {},
            },
            pageTitle: 'World',
            getMainSection: getWorldRouteInfo,
            curationName: 'world',
            getAdTargeting,
        })
    },
    '/news/claremont-serial-killings': getClaremontSerialKillingsRouteInfo({
        id: 'news/claremont-serial-killings',
        title: 'Claremont Serial Killings',
        seoTitle: 'Claremont Serial Killings',
        metadata: {},
        parent: { id: 'news', title: 'News', seoTitle: 'News', metadata: {} },
    }),
    '/news/cleo-smith': getCleoRouteInfo({
        id: 'news/cleo-smith',
        title: 'Cleo Smith',
        seoTitle: 'Cleo Smith Search',
        metadata: {},
        parent: { id: 'news', title: 'News', seoTitle: 'News', metadata: {} },
    }),
    '/sport/afl': ({ config, getAdTargeting, store }) =>
        createPage({
            config,
            topic: { id: 'afl', title: 'AFL', seoTitle: 'AFL', metadata: {} },
            pageTitle: 'AFL',
            hideBreadcrumb: false,
            getMainSection: getAFLRouteInfo,
            getAdTargeting,
            store,
            showFixtureScoreboard: false,
        }),
    '/sport/afl-draft': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: '/sport/afl-draft',
                title: 'AFL Draft',
                seoTitle: 'AFL Draft',
                metadata: {},
            },
            pageTitle: 'AFL Draft',
            getMainSection: getAFLDraftRouteInfo,
            getAdTargeting,
        }),
    '/sport/wafl': ({ config, getAdTargeting, store }) => {
        const waflLandingEnabled =
            store.getState().toggles['the-west-wafl-landing']

        const section: TheWestSection = 'default'

        const waflTopicProps = {
            topic: {
                id: 'sport/wafl',
                title: 'WAFL',
                seoTitle: 'WAFL',
                metadata: {},
                parent: {
                    id: 'sport',
                    title: 'Sport',
                    metadata: {},
                    seoTitle: 'Sport',
                },
            },
            config,
            getAdTargeting,
            section,
        }

        return waflLandingEnabled
            ? createPage({
                  config,
                  topic: {
                      id: 'sport/wafl',
                      title: 'WAFL',
                      seoTitle: 'WAFL',
                      metadata: {},
                      parent: {
                          id: 'sport',
                          title: 'Sport',
                          metadata: {},
                          seoTitle: 'Sport',
                      },
                  },
                  pageTitle: 'WAFL',
                  hideBreadcrumb: false,
                  getMainSection: getWAFLRouteInfo,
                  getAdTargeting,
                  curationName: 'wafl',
                  store,
              })
            : getTopicPageRouteInfo(waflTopicProps)
    },
    '/sport/commonwealth-games': ({ config, getAdTargeting, store }) => {
        const isCustomCommenwealthGamesEnabled =
            store.getState().toggles['commonwealth-games-sports-page-curation']

        const commonwealthGamesTopic: Topic = {
            id: 'sport/commonwealth-games',
            title: 'Commonwealth Games',
            seoTitle: 'Commonwealth Games',
            metadata: {},
        }

        return isCustomCommenwealthGamesEnabled
            ? createPage({
                  config,
                  topic: commonwealthGamesTopic,
                  pageTitle: 'Commonwealth Games',
                  getMainSection: getCommonwealthGamesRouteInfo,
                  getAdTargeting,
                  hideBreadcrumb: true,
                  thorPropOverrides: {
                      verticalGutters: 'unset',
                  },
                  removeBottomMarginBreakingNews: true,
              })
            : getTopicPageRouteInfo({
                  topic: commonwealthGamesTopic,
                  config,
                  section: 'default',
                  getAdTargeting,
              })
    },
    '/sport/live': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'sport/live',
                title: 'Live Streams & Replays',
                seoTitle: 'The West Australian Sports Live Streams',
                seoDescription:
                    'Watch live matches and replays from across WA sport, including PSA, WAFL, netball, hockey and more.',
                metadata: {},
                parent: {
                    id: 'sport',
                    title: 'Sport',
                    metadata: {},
                    seoTitle: 'Sport',
                },
            },
            pageTitle: 'Live Streams & Replays',
            getMainSection: getLiveSportRouteInfo,
            getAdTargeting,
        }),
    '/sport/olympics': ({ config, getAdTargeting, store }) => {
        const isCustomParisOlympicsRouteEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'paris-olympics-2024',
        )

        return isCustomParisOlympicsRouteEnabled
            ? createPage({
                  config,
                  topic: {
                      id: 'olympics',
                      title: 'Olympics',
                      seoTitle: 'Olympics',
                      metadata: {},
                  },
                  pageTitle: 'Olympics',
                  getMainSection: getOlympicsRouteInfo,
                  getAdTargeting,
                  hideBreadcrumb: true,
                  thorPropOverrides: {
                      verticalGutters: 'unset',
                  },
                  store,
                  showFixtureScoreboard: true,
              })
            : getTopicPageRouteInfo({
                  topic: {
                      id: 'sport/olympics',
                      title: 'Olympics',
                      seoTitle: 'Olympics',
                      metadata: {},
                      parent: {
                          id: 'sport',
                          title: 'Sport',
                          metadata: {},
                          seoTitle: 'Sport',
                      },
                  },
                  config,
                  section: 'default',
                  getAdTargeting,
              })
    },
    '/features/kerry-stokes-collection': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'features/kerry-stokes-collection',
                title: 'The Kerry Stokes Collection',
                seoTitle: 'The Kerry Stokes Collection',
                metadata: {},
                parent: {
                    id: 'features',
                    title: 'Features',
                    metadata: {},
                    seoTitle: 'Features',
                },
            },
            pageTitle: 'The Kerry Stokes Collection',
            getMainSection: getKerryStokesCollectionRouteInfo,
            getAdTargeting,
        }),
    '/business/your-money': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'your-money',
                title: 'Your Money',
                seoTitle: 'Your Money',
                metadata: {},
                parent: {
                    id: 'business',
                    title: 'Business',
                    seoTitle: 'Business',
                    metadata: {},
                },
            },
            pageTitle: 'Your Money',
            getMainSection: getYourMoneyRouteInfo,
            getAdTargeting,
        }),
    '/business/cnbc': ({ config, getAdTargeting, store }) => {
        const isCNBCEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'the-west-cnbc',
            false,
        )
        const section: TheWestSection = 'default'
        const cnbcTopicProps = {
            topic: {
                id: 'business/cnbc',
                title: 'CNBC',
                seoTitle: 'CNBC',
                metadata: {},
                parent: {
                    id: 'business',
                    title: 'Business',
                    metadata: {},
                    seoTitle: 'Business',
                },
                sponsorLogo: isCNBCEnabled ? 'cnbc' : undefined,
            },
            config,
            getAdTargeting,
            section,
        }

        return getTopicPageRouteInfo(cnbcTopicProps)
    },
    '/sport/cricket': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'sport/cricket',
                title: 'Cricket',
                seoTitle: 'Cricket',
                metadata: {},
                parent: {
                    id: 'sport',
                    title: 'Sport',
                    seoTitle: 'Sport',
                    metadata: {},
                },
            },
            pageTitle: 'Cricket',
            getMainSection: getCricketRouteInfo,
            curationName: 'cricket',
            getAdTargeting,
        }),
    '/features/gerard-ross': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'features/gerard-ross',
                title: 'Gerard Ross',
                seoTitle: 'Gerard Ross',
                metadata: {},
                parent: {
                    id: 'features',
                    title: 'Features',
                    metadata: {},
                    seoTitle: 'Features',
                },
            },
            pageTitle: 'Gerard Ross',
            getMainSection: getGerardRossRouteInfo,
            getAdTargeting,
        }),
    '/features/2019-cars-of-the-year': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'features/2019-cars-of-the-year',
                title: '2019 Cars of the Year',
                seoTitle: '2019 Cars of the Year',
                metadata: {},
                parent: {
                    id: 'features',
                    title: 'Features',
                    metadata: {},
                    seoTitle: 'Features',
                },
            },
            pageTitle: '2019 Cars of the Year',
            getMainSection: getCarOfTheYear2019RouteInfo,
            getAdTargeting,
        }),
    '/features/2020-cars-of-the-year': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'features/2020-cars-of-the-year',
                title: '2020 Cars of the Year',
                seoTitle: '2020 Cars of the Year',
                metadata: {},
                parent: {
                    id: 'features',
                    title: 'Features',
                    metadata: {},
                    seoTitle: 'Features',
                },
            },
            pageTitle: '2020 Cars of the Year',
            getMainSection: getCarOfTheYear2020RouteInfo,
            getAdTargeting,
        }),
    '/business/rts2019': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'rts2019',
                title: 'Resources Technology Showcase 2019',
                seoTitle: 'Resources Technology Showcase 2019',
                metadata: {},
                parent: {
                    id: 'business',
                    title: 'Business',
                    seoTitle: 'Business',
                    metadata: {},
                },
            },
            pageTitle: 'Resources Technology Showcase 2019',
            getMainSection: getRTS2019RouteInfo,
            getAdTargeting,
        }),
    '/news/indigenous-australians': ({ config, getAdTargeting }) =>
        createPage({
            config,
            topic: {
                id: 'news/indigenous-australians',
                title: 'Indigenous Australians',
                seoTitle: 'Indigenous Australians',
                metadata: {},
                parent: {
                    id: 'news',
                    title: 'News',
                    metadata: {},
                    seoTitle: 'News',
                },
            },
            pageTitle: 'Indigenous Australians',
            getMainSection: getIndigenousAustraliansRouteInfo,
            curationName: 'indigenous-affairs',
            getAdTargeting,
        }),
    '/news/king-charles-iii': ({ config, getAdTargeting, store }) => {
        const isKingsCoronationEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'kings-coronation-topic-page',
            false,
        )

        const kingsCoronationTopic: Topic = {
            id: 'news/king-charles-iii',
            title: 'King Charles III',
            seoTitle: 'King Charles III',
            metadata: {},
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
        }

        if (isKingsCoronationEnabled) {
            return createPage({
                config,
                topic: kingsCoronationTopic,
                pageTitle: 'King Charles III',
                getMainSection: getKingsCoronationRouteInfo,
                getAdTargeting,
            })
        } else {
            return getTopicPageRouteInfo({
                topic: kingsCoronationTopic,
                config,
                section: 'default',
                getAdTargeting,
            })
        }
    },
}

const createCustomPage = ({
    topic,
    pageTitle,
    getMainSection,
    curationName,
    section = 'default',
    getAdTargeting,
    hideBreadcrumb,
    thorPropOverrides,
    removeBottomMarginBreakingNews,
    store,
}: {
    config: BaseClientConfig
    topic: Topic
    pageTitle: string
    getMainSection: GetMainSectionFn
    curationName?: string
    section?: TheWestSection
    getAdTargeting: GetRouteAdTargeting
    hideBreadcrumb?: boolean
    thorPropOverrides?: ThorRouteProps
    removeBottomMarginBreakingNews?: boolean
    store?: Store
}): PageType<TheWestSection> => {
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        section,
        topic,
    )

    const isExclusiveContentEnabled =
        store &&
        isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'exclusive_content',
        )
    const isVanishingCousinsContentEnabled =
        store &&
        isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'vanishing-cousins-sub-exclusive-banner',
        )

    const adTargeting: AdTargeting = {
        pageId: topic.title,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    const getCuratedSection: TopicSectionBuilder['getCuratedSection'] = (
        hooks,
    ) => {
        const sierraHookProps = hooks ? hooks.sierra || {} : {}
        const limaHookProps = hooks ? hooks.lima || {} : {}

        return [
            layout.component({
                type: 'sierra',
                props: {
                    verticalSpacing: 'gridGap',
                    fixedRatios: ['16:9'],
                    heroImageLayout: ResponsivePictureLayout.ObjectFitContain,
                    cardOrientation: {
                        type: 'Landscape-Portrait',
                        maxBreakpoint: 'md',
                    },
                    cardLayout: ['hero', 'teaser-xsVisible-mdHidden-Image'],

                    dataDefinitionArgs: {
                        type: 'curation',
                        name: curationName || topic.id,
                        offset: 0,
                        pageSize: 2,
                    },
                    ...sierraHookProps,
                },
            }),
            layout.component({
                type: 'lima',
                props: {
                    verticalSpacing: 'xl',
                    fontScale: 1.2,
                    fixedRatios: ['16:9'],
                    hasBackground: true,
                    initialColumns: 1,
                    intermediateColumns: 2,
                    finalColumns: 4,
                    teaserMode: 'visible',
                    cardOrientation: { type: 'Landscape-Portrait' },

                    dataDefinitionArgs: {
                        type: 'curation',
                        name: curationName || topic.id,
                        offset: 2,
                        pageSize: 4,
                    },
                    ...limaHookProps,
                },
            }),
            ...topicParallax(),
        ]
    }

    const defaultComponents: CheckedComponentInformation[] = [
        layout.component({
            type: 'breaking-news',
            props: {
                removeBottomMargin: removeBottomMarginBreakingNews,
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'breaking-news',
                    offset: 0,
                    pageSize: 1,
                },
            },
        }),
        layout.component({
            type: 'breaking-news-promo-strap',
            props: {
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'breaking-news-promo-strap',
                    offset: 0,
                    pageSize: 1,
                },
            },
        }),
    ]

    if (!hideBreadcrumb) {
        defaultComponents.push(
            layout.component({
                type: 'breadcrumb',
                props: {
                    topic,
                    sponsor: getTopicSponsor(topic),
                    enableHeaderTag: true,
                },
            }),
        )
    }

    return {
        kind: 'page',
        heading: pageTitle,
        pageType: 'topic',
        adTargeting,
        hideHeading: true,
        includeFromSectionInMetaDescription: !topic.seoDescription,
        pageMeta: {
            description:
                topic.seoDescription || `The Latest in ${topic.seoTitle}`,
            title: topic.seoTitle || topic.title,
        },
        section,
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: 'outerMargin',
                    verticalGutters: ['outerMargin', 'unset'],
                },
                contentAreas: {
                    main: defaultComponents,
                },
            }),
            //Banner + 2 card curation
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                    ...thorPropOverrides,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'image-banner',
                            props: {
                                defaultSrc: isVanishingCousinsContentEnabled
                                    ? bannerMobileX2
                                    : mobileBanner,
                                verticalGutters: ['unset', 'md'],
                                altText: 'Subscriber Exclusive Banner',
                                sources: [
                                    {
                                        breakpoint: 'md',
                                        src: [
                                            {
                                                src: isVanishingCousinsContentEnabled
                                                    ? bannerDesktopX2
                                                    : desktopBanner,
                                                density: '2x',
                                            },
                                            {
                                                src: isVanishingCousinsContentEnabled
                                                    ? bannerDesktopX1
                                                    : desktopBanner,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        breakpoint: 'xs',
                                        src: [
                                            {
                                                src: isVanishingCousinsContentEnabled
                                                    ? bannerTabletX2
                                                    : tabletBanner,
                                                density: '2x',
                                            },
                                            {
                                                src: isVanishingCousinsContentEnabled
                                                    ? bannerTabletX1
                                                    : tabletBanner,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                    {
                                        src: [
                                            {
                                                src: isVanishingCousinsContentEnabled
                                                    ? bannerMobileX2
                                                    : mobileBanner,
                                                density: '2x',
                                            },
                                            {
                                                src: isVanishingCousinsContentEnabled
                                                    ? bannerMobileX1
                                                    : mobileBanner,
                                                density: '1x',
                                            },
                                        ],
                                    },
                                ],
                            },
                        }),
                        layout.component({
                            type: 'sierra',
                            props: {
                                verticalSpacing: 'gridGap',
                                fixedRatios: ['16:9'],
                                heroImageLayout:
                                    ResponsivePictureLayout.ObjectFitContain,
                                cardOrientation: {
                                    type: 'Landscape-Portrait',
                                    maxBreakpoint: 'md',
                                },
                                cardLayout: [
                                    'hero',
                                    'teaser-xsVisible-mdHidden-Image',
                                ],
                                hasHeroBorder: true,
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'features',
                                    offset: 0,
                                    pageSize: 2,
                                },
                                sectionHeader: {
                                    heading: `Exclusive Content`,
                                },
                            },
                        }),

                        layout.component({
                            type: 'lima',
                            props: {
                                verticalSpacing: 'xl',
                                fontScale: 1.2,
                                fixedRatios: ['16:9'],
                                hasBackground: true,
                                initialColumns: 1,
                                intermediateColumns: 2,
                                finalColumns: 4,
                                teaserMode: 'visible',
                                marginBottom: 0,
                                cardOrientation: { type: 'Landscape-Portrait' },
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'features',
                                    // return an index greater than the length of curation(7) to render nothing when toggled off
                                    offset: isExclusiveContentEnabled ? 2 : 8,
                                    pageSize: 4,
                                },
                            },
                        }),
                    ],
                },
            }),
            // banner end

            // Coming soon curation
            layout.composition({
                type: 'box',
                props: {
                    containerWidth: '100%',
                    padding: '0',
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'coming-soon',
                            props: {
                                verticalSpacing: 'gridGap',
                                fixedRatios: ['16:9'],
                                heroImageLayout:
                                    ResponsivePictureLayout.ObjectFitContain,
                                cardOrientation: {
                                    type: 'Landscape-Portrait',
                                    maxBreakpoint: 'md',
                                },
                                cardLayout: ['hero'],

                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'subscriber-exclusive-coming-soon',
                                    // return an index greater than the length of curation(1) to render nothing when toggled off
                                    offset: isExclusiveContentEnabled ? 1 : 0,
                                    pageSize: 1,
                                },
                            },
                        }),
                    ],
                },
            }),

            // Rest of the page
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    horizontalGutters: true,
                    ...thorPropOverrides,
                },
                contentAreas: {
                    main: getMainSection({ getCuratedSection }, store),
                },
            }),
        ],
    }
}
