import { useTheme, withTheme } from '@emotion/react'
import { handleUnknownError } from '@news-mono/common'
import React, { useEffect, useState } from 'react'
import {
    StyledButton,
    StyledContainer,
    StyledContentContainer,
    StyledForm,
    StyledFormContainer,
    StyledInput,
    StyledInputWrapper,
    StyledLabel,
    StyledSubText,
    StyledTermsText,
    StyledText,
    StyledTextLink,
} from './NewsletterSignup.styled'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { StyledNightlyIconEmail } from '../../buttons/ProfileEmailButton/ProfileEmailButton.styled'
import { NewsletterVariants } from './NewsletterSignup.routing'
import { useRecaptcha } from '../../recaptcha'
import {
    AppState,
    AuthenticationState,
    useFeature,
} from '@news-mono/web-common'
import { useSelector } from 'react-redux'

export interface InternalNewsletterSignupProps {
    text: string
    buttonLabel: string
    onEvent: (event: any) => void
    verticalSpacing?: keyof ThemeMargins
    subText?: string
    mode: NewsletterVariants
    persistent?: boolean
}

interface ListIdName {
    listId?: string
    listName: string
}

interface NewsletterSubscriptionFormState {
    firstName: string
    lastName: string
    email: string
    optInLists: ListIdName[]
    source: string
}

const NewsletterTNSignup: React.FC<InternalNewsletterSignupProps> = (props) => {
    const [state, setState] = useState({
        email: '',
        honeypot: '',
        error: false,
        success: false,
        isSubmitting: false,
    })

    const [formData, setFormData] = useState<NewsletterSubscriptionFormState>({
        email: '',
        firstName: '',
        lastName: '',
        optInLists: [
            {
                listId: 'thenightly_newsletter-news',
                listName: 'thenightly_newsletter',
            },
        ],
        source: 'The Nightly Newsletters CTA',
    })

    const { emailVerified, isLoading, hasSignedupToNewsletter } = useSelector<
        AppState,
        AuthenticationState
    >((state) => state.authentication)

    const TIME_INTERVAL = 30
    const [seconds, setSeconds] = useState<number>(TIME_INTERVAL)
    const [isCounting, setIsCounting] = useState<boolean>(false)

    function resetTimer() {
        setSeconds(TIME_INTERVAL)
        setIsCounting(true)
    }

    // Countdown timer
    useEffect((): (() => void) => {
        if (localStorage.getItem('newsletter-signup')) {
            setIsCounting(true)
        }
        // `setInterval` Type would be number if uses window.setInterval()
        let interval: null | NodeJS.Timer = null
        if (isCounting) {
            interval = setInterval(() => {
                setSeconds((seconds) => {
                    if (seconds > 1) {
                        return seconds - 1
                    } else {
                        interval && clearInterval(interval)
                        setIsCounting(false)
                        return TIME_INTERVAL
                    }
                })
            }, 1000)
        } else {
            interval && clearInterval(interval)
            localStorage.removeItem('newsletter-signup')
        }
        return () => interval && clearInterval(interval)
    }, [isCounting, seconds])

    const theme = useTheme()

    const emailSubscribeLink = '/' //'/manage-email-preferences' Need to add a epc subscribe link when it is created

    const subscribeUrl = `https://thenightly.com.au${emailSubscribeLink}`

    const contactUrl = 'https://thenightly.com.au/contact'

    const { executeRecaptcha } = useRecaptcha()

    const handleSubmit = async (e: React.FormEvent<any>) => {
        e.preventDefault()
        setState({ ...state, isSubmitting: true })
        resetTimer()

        const token = await executeRecaptcha({ action: 'subscribe' })
        try {
            const response = await fetch('/newsletter-subscribe', {
                method: 'POST',
                body: JSON.stringify({
                    token,
                    email: formData.email,
                    firstName: formData.firstName ?? formData.email,
                    lastName: formData.lastName,
                    optOutLists: formData.optInLists.map((item) => item.listId),
                    source: formData.source,
                    requestToken: true,
                    createContactOnly: true,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            localStorage.setItem('newsletter-signup', 'submitted')

            if (response.ok) {
                setState({ ...state, success: true, isSubmitting: false }),
                    () => {
                        props.onEvent({
                            type: 'newsletterSignup.success',
                            originator: 'NewsletterSignupBanner',
                            timeStamp: Date.now(),
                            payload: {
                                creativeName: 'Default',
                                buttonText: props.buttonLabel,
                                newsletterDatabase: 'daily_news',
                            },
                        })
                    }
            } else {
                setState({ ...state, isSubmitting: false, error: true }),
                    () => {
                        props.onEvent({
                            type: 'newsletterSignup.fail',
                            originator: 'NewsletterSignupBanner',
                            timeStamp: Date.now(),
                            payload: {
                                creativeName: 'Default',
                                buttonText: props.buttonLabel,
                                newsletterDatabase: 'daily_news',
                            },
                        })
                    }

                throw new Error(
                    `NewsletterSignupBanner submission error: ${response.status} ${response.statusText}`,
                )
            }
        } catch (error) {
            const err = handleUnknownError(error)
            console.error(err)
        }
    }

    const handleFocus = () => {
        props.onEvent({
            type: 'newsletterSignup.interact',
            originator: 'NewsletterSignupBanner',
            timeStamp: Date.now(),
            payload: {
                creativeName: 'Default',
                buttonText: props.buttonLabel,
                newsletterDatabase: 'daily_news',
            },
        })
    }

    // Toggled off newsletter cta banners everywhere its used
    if (!useFeature('newsletter-subscription-banner')) {
        return <></>
    }

    if (
        !isLoading &&
        emailVerified &&
        hasSignedupToNewsletter === true &&
        !props.persistent
    ) {
        return <></>
    }

    return (
        <StyledContainer verticalSpacing={props.verticalSpacing}>
            <StyledContentContainer>
                <StyledFormContainer mode={props.mode}>
                    {state.success && !state.isSubmitting && (
                        <StyledText>Thank you for subscribing!</StyledText>
                    )}

                    {state.isSubmitting && (
                        <StyledText>Submitting...</StyledText>
                    )}

                    {!state.success && state.error && (
                        <StyledText>
                            Something went wrong, please{' '}
                            <StyledTextLink href={subscribeUrl}>
                                try again
                            </StyledTextLink>{' '}
                            or{' '}
                            <StyledTextLink href={contactUrl}>
                                contact us
                            </StyledTextLink>
                        </StyledText>
                    )}

                    {!state.success && !state.error && !state.isSubmitting && (
                        <React.Fragment>
                            <StyledText>{props.text}</StyledText>
                            {props.subText && (
                                <StyledSubText>{props.subText}</StyledSubText>
                            )}
                            <StyledForm
                                method="POST"
                                onSubmit={(e) => handleSubmit(e)}
                                mode={props.mode}
                            >
                                <StyledLabel htmlFor="newsletterSignup-emailAddress">
                                    Your email address:
                                </StyledLabel>
                                <StyledInputWrapper mode={props.mode}>
                                    <StyledNightlyIconEmail />
                                    <StyledInput
                                        name="email"
                                        id="newsletterSignup-email"
                                        value={formData.email}
                                        type="email"
                                        required
                                        placeholder="Email address"
                                        onFocus={() => handleFocus()}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                email: e.currentTarget.value,
                                            })
                                        }
                                    />
                                </StyledInputWrapper>

                                {/* Honeypot field, will be rejected by API if a value is entered */}
                                <StyledInput
                                    hidden
                                    name="address"
                                    id="newsletterSignup-address"
                                    value={state.honeypot}
                                    type="text"
                                    onChange={(e) =>
                                        setState({
                                            ...state,
                                            honeypot: e.currentTarget.value,
                                        })
                                    }
                                />

                                <StyledButton
                                    type="submit"
                                    disabled={state.isSubmitting || isCounting}
                                    mode={props.mode}
                                >
                                    {isCounting
                                        ? `Resubscribe in ${seconds}s`
                                        : props.buttonLabel}
                                </StyledButton>
                            </StyledForm>
                            <StyledTermsText>
                                By continuing you agree to our{' '}
                                <a href="/subscription-terms">Terms</a> and{' '}
                                <a href="https://www.sevenwestmedia.com.au/privacy-policies/privacy">
                                    Privacy Policy
                                </a>
                                .
                            </StyledTermsText>
                        </React.Fragment>
                    )}
                </StyledFormContainer>
            </StyledContentContainer>
        </StyledContainer>
    )
}

export const TNNewsletterSignup = withTheme(NewsletterTNSignup)
