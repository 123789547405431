import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics, sectionThemeOverrider } from '@news-mono/component-library'
import { StaticRoutes, Topic } from '@news-mono/web-common'
import { layout } from '../App.routing'
import { getPuzzleRoute } from './puzzles/getPuzzleRoute'
import { getTopicSponsor } from './topic/get-topic-sponsor'
const socialImage = require('./topic/custom/assets/puzzles/socialImage-v2.png')

export const puzzles: StaticRoutes<TheWestSection> = {
    '/puzzles': ({ getAdTargeting, store, config }) => {
        if (
            !isFeatureEnabled(
                toFeatureState(store.getState().toggles),
                'puzzles',
            )
        ) {
            return null
        }

        const swgCampaignEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'breach-puzzles-swg-30',
        )

        const canonicalUrl = config.publicUrl + '/puzzles'
        const renditionType = store.getState().render.renditionType
        const topic: Topic = {
            id: 'puzzles',
            metadata: {},
            title: 'Puzzles',
            seoTitle: 'Fun Daily Online Puzzles | The West Australian',
            seoDescription: `Put your brain to the test and challenge yourself to The West Australian's daily Word Search, Sudoku, Crossword and Target puzzles. Play now.`,
        }
        const getSocialImageMeta = [
            {
                property: 'og:image',
                content: socialImage,
            },
            { name: 'twitter:image', content: socialImage },
            {
                property: 'og:image:height',
                content: '643',
            },
            {
                property: 'og:image:width',
                content: '1224',
            },
            {
                property: 'og:description',
                content: topic.seoDescription,
            },
            {
                property: 'twitter:description',
                content: topic.seoDescription,
            },
        ]

        return {
            kind: 'page',
            heading: 'Puzzles',
            pageType: 'static',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.title,
                description: topic.seoDescription,
                seoTitle: topic.seoTitle,
                seoDescription: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: getSocialImageMeta,
            },
            adTargeting: getAdTargeting('home', 'default', topic),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'thor',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        elementType: 'div',
                        sidebarOptions: 'visibleMobile',
                    },
                    contentAreas: {
                        main:
                            renditionType !== 'app'
                                ? [
                                      layout.component({
                                          type: 'breadcrumb',
                                          props: {
                                              topic,
                                              sponsor: getTopicSponsor(topic),
                                              enableHeaderTag: true,
                                              verticalSpacing: 'lg',
                                          },
                                      }),
                                      layout.component({
                                          type: 'puzzles-listing',
                                          requiresAccess: {
                                              level: 'subscriber',
                                              notAuthorized: layout.composition(
                                                  {
                                                      type: 'box',
                                                      props: {
                                                          containerWidth:
                                                              metrics.thewest
                                                                  .siteMetrics
                                                                  .mainContentWidth,
                                                          hasBackgroundFill:
                                                              true,
                                                          verticalSpacing: 'lg',
                                                          horizontalGutters:
                                                              'unset',
                                                      },
                                                      contentAreas: {
                                                          main: [
                                                              layout.component({
                                                                  type: 'breachscreen',
                                                                  props: {
                                                                      title: isFeatureEnabled(
                                                                          toFeatureState(store.getState().toggles),
                                                                          'default-price-increase',
                                                                      )
                                                                          ? 'Access new puzzles every day'
                                                                          : 'Access puzzles for only $1 a day',
                                                                      breachwall:
                                                                          'puzzles',
                                                                      breachDeal:
                                                                          'Puzzles',
                                                                      breachType:
                                                                          'Puzzles',
                                                                      breachCreativeName:
                                                                          'Puzzles',
                                                                      terms: 'Some terms',
                                                                      renditionType:
                                                                          renditionType,
                                                                      enableSwgCampaign:
                                                                          swgCampaignEnabled,
                                                                  },
                                                              }),
                                                          ],
                                                      },
                                                  },
                                              ),
                                          },
                                          props: {},
                                      }),
                                      layout.component({
                                          type: 'ad-unit',
                                          props: {
                                              noticePosition: 'below-center',
                                              padding: [
                                                  0,
                                                  0,
                                                  metrics.thewest.margins.md,
                                                  0,
                                              ],
                                              slot: {
                                                  id: 'mobile-mrec',
                                                  size: 'mobileMrec',
                                                  pageType: 'static',
                                              },
                                              adType: 'inline',
                                          },
                                      }),
                                      layout.nestedComposition({
                                          type: 'box',
                                          props: {},
                                          contentAreas: {
                                              main: [
                                                  layout.component({
                                                      type: 'ad-unit',
                                                      props: {
                                                          noticePosition:
                                                              'above-center',
                                                          hiddenUntilLoaded:
                                                              false,
                                                          padding: [
                                                              0,
                                                              0,
                                                              metrics.sevennews
                                                                  .margins.lg,
                                                              0,
                                                          ],
                                                          slot: {
                                                              id: 'puzzles-leaderboard-one',
                                                              size: 'leaderboard',
                                                          },
                                                          adType: 'inline',
                                                      },
                                                  }),
                                              ],
                                          },
                                      }),
                                  ]
                                : [
                                      layout.component({
                                          type: 'puzzles-listing',
                                          requiresAccess: {
                                              level: 'anonymous',
                                              notAuthorized: layout.composition(
                                                  {
                                                      type: 'thor',
                                                      props: {
                                                          containerWidth:
                                                              metrics.thewest
                                                                  .siteMetrics
                                                                  .mainContentWidth,
                                                          horizontalGutters:
                                                              true,
                                                          hasBackgroundFill:
                                                              true,
                                                          stretchSelf: true,
                                                      },
                                                      contentAreas: {
                                                          main: [
                                                              layout.component({
                                                                  type: 'breachscreen',
                                                                  props: {
                                                                      title: 'Subscribe to access puzzles',
                                                                      breachDeal:
                                                                          'Puzzles',
                                                                      breachType:
                                                                          'Puzzles',
                                                                      breachCreativeName:
                                                                          'Puzzles',
                                                                      terms: 'Some terms',
                                                                  },
                                                              }),
                                                          ],
                                                      },
                                                  },
                                              ),
                                          },
                                          props: {},
                                      }),
                                  ],

                        // SIDEBAR
                        sidebar: [],
                    },
                }),
            ],
        }
    },

    '/puzzles/sudoku': getPuzzleRoute(
        {
            id: 'puzzles/sudoku',
            title: 'Sudoku',
            seoTitle: 'Sudoku - Puzzles',
            seoDescription:
                "Practice your problem solving skills with The West Australian's latest Sudoku challenge or play our Crossword, Word Search or Target puzzles now.",
            metadata: {},
            parent: {
                id: 'puzzles',
                title: 'Puzzles',
                seoTitle: 'Puzzles',
                metadata: {},
            },
        },
        'auspac-per610-sud200',
        'https://cdn2.amuselabs.com/pmm/date-picker?set=auspac-per610-sud200&embed=1',
    ),
    '/puzzles/word-search': getPuzzleRoute(
        {
            id: 'puzzles/word-search',
            title: 'Word Search',
            seoTitle: 'Word Search - Puzzles',
            seoDescription:
                "Put your word finding skills to the test with The West Australian's latest Word Search challenge or play our Crossword, Sudoku or Target puzzles now.",
            metadata: {},
            parent: {
                id: 'puzzles',
                title: 'Puzzles',
                seoTitle: 'Puzzles',
                metadata: {},
            },
        },
        'auspac-per610-wf15',
        'https://cdn2.amuselabs.com/pmm/date-picker?set=auspac-per610-wf15&embed=1',
    ),
    '/puzzles/crossword': getPuzzleRoute(
        {
            id: 'puzzles/crossword',
            title: 'Crossword',
            seoTitle: 'Crossword - Puzzles',
            seoDescription:
                "Solve The West Australian's latest Quick and Cryptic Crosswords, access past challenges or play our Word Search, Sudoku or Target puzzles now.",
            metadata: {},
            parent: {
                id: 'puzzles',
                title: 'Puzzles',
                seoTitle: 'Puzzles',
                metadata: {},
            },
        },
        'auspac-per610-nat140',
        'https://cdn2.amuselabs.com/pmm/date-picker?set=auspac-per610-nat140&embed=1',
    ),
    '/puzzles/target': getPuzzleRoute(
        {
            id: 'puzzles/target',
            title: 'Target',
            seoTitle: 'Target (Jumble Word) - Puzzles',
            seoDescription:
                "How many words can you make from these letters? Try The West Australian's Target challenge or play our Crossword, Word Search or Sudoku puzzles now.",
            metadata: {},
            parent: {
                id: 'puzzles',
                title: 'Puzzles',
                seoTitle: 'Puzzles',
                metadata: {},
            },
        },
        '',
        'https://jumble.auspacmedia.com.au/listgames/west-australian',
    ),
}
