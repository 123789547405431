import {
    AppState,
    AuthenticationState,
    authLogout,
    authLogoutPath,
    ConfigurationContext,
    debugExtendedAccess,
    getAuthParams,
    getLoginUrl,
    getPageUrl,
    getRegisterUrl,
    getSubscribeUrl,
    isArticleLikeType,
    LogoutEvent,
    Product,
    SignupOrigin,
    SubscribeClickOverrides,
} from '@news-mono/web-common'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { BreachScreenContext } from '../../../../web-common/src/context-providers/BreachContextProvider'
import { useSectionMetaInfo } from '../../__helpers/use-section-meta'
import { saveOriginUrl } from '../warden/__helpers/origin-url-helper'
import { useTheme } from '@emotion/react'
import { get as getCookie, remove as removeCookie } from 'js-cookie'

declare const google: {
    accounts: {
        id: {
            disableAutoSelect: () => {}
        }
    }
}

export type OnSubscribeEventHandler = (
    overrides?: SubscribeClickOverrides,
) => void

export type AuthProviderOnEvent = (event: LogoutEvent) => void
export interface AuthenticationProviderContextValues {
    onLoginClick(): void
    onRegisterClick(
        componentOrigin?: SignupOrigin,
        platform?: 'app' | 'web',
    ): void
    onLogoutClick(onEvent: AuthProviderOnEvent): void
    onSubscribeClick: OnSubscribeEventHandler
    getSubscribeLink: (overrides?: SubscribeClickOverrides) => string
}

export const AuthenticationProviderContext = React.createContext<
    AuthenticationProviderContextValues | undefined
>(undefined)

export function useAuthActions() {
    const values = useContext(AuthenticationProviderContext)
    if (!values) {
        throw new Error(
            'Ensure <AuthenticationProvider /> is rendered above the component using this hook',
        )
    }

    return values
}

export const AuthenticationProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    const dispatch = useDispatch()
    const config = useContext(ConfigurationContext)
    const sectionMeta = useSectionMetaInfo()
    const breachScreenContext = React.useContext(BreachScreenContext)
    const theme = useTheme()
    const context = breachScreenContext.context
    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )
    const location = useLocation()
    const pageUrl = getPageUrl(sectionMeta, config.publicUrl, location.pathname)

    const onLoginClick = () => {
        const loginUrl = getLoginUrl()
        window.location.href = loginUrl
    }

    const onLogoutClick = async (onEvent: AuthProviderOnEvent) => {
        dispatch(
            authLogout({
                onEvent,
            }),
        )

        debugExtendedAccess('Logging out %o', authentication)

        let isTheNightly = undefined

        // Check if using Auth0
        if (config.auth0) {
            isTheNightly = config.auth0.issuer.includes('thenightly')
        }

        // For TN we want to stop the extended access logout flow from triggering by doing this first
        if (isTheNightly) {
            debugExtendedAccess('Logging out normally')
            // Fallback cookie for auto newsletter signup
            if (getCookie('signedUpToNewsletter')) {
                removeCookie('signedUpToNewsletter')
            }
            window.location.href = authLogoutPath
        }

        if (
            authentication.loginProvider === 'Google' ||
            authentication.socialProviders.match(/Google/)
        ) {
            // Sign out google user: https://developers.google.com/identity/gsi/web/guides/automatic-sign-in-sign-out#sign-out
            debugExtendedAccess('Logging out google user')
            google.accounts && google.accounts.id.disableAutoSelect()
            window.location.href = authLogoutPath
        } else {
            window.location.href = authLogoutPath
        }
    }

    function getSubscribeLink(overrides: SubscribeClickOverrides | undefined) {
        const auth = getAuthParams({ sectionMeta })
        const article =
            context?.publication && isArticleLikeType(context?.publication)
                ? context.publication
                : undefined

        const subscribeLink = getSubscribeUrl({
            config,
            auth,
            pageUrl,
            article,
            packagePath:
                overrides?.packagePath ||
                context?.packagePath ||
                config.packagePath,
            source: sectionMeta.Hostname,
            productName: sectionMeta.Title,
            campaign: context?.breachCreativeName,
            callToAction: overrides?.callToAction,
            additionalParams: {
                utm_term: context?.breachDeal,
                utm_content: context?.publication?.id,
            },
            offerCode: overrides?.offerCode || context?.offerCode,
            breachScreenType: overrides?.breachScreenType,
            returningUser: context?.returningUser,
            requiredAccessLevel: context?.requiredAccessLevel,
        })
        return subscribeLink
    }

    const onSubscribeClick: OnSubscribeEventHandler = (overrides) => {
        const subscribeLink = getSubscribeLink(overrides)

        window.location.href = subscribeLink
    }

    const onRegisterClick = (
        componentOrigin: SignupOrigin,
        platform: 'app' | 'web',
    ) => {
        // we want to store the origin url for the nightly so
        // that it knows how to redirect
        if (
            theme.kind === Product.TheNightly ||
            theme.kind === Product.PerthNow
        ) {
            saveOriginUrl(true)
        }
        const registerUrl = getRegisterUrl(componentOrigin, platform)
        window.location.href = registerUrl
    }

    const values = {
        onLoginClick,
        onRegisterClick,
        onLogoutClick,
        onSubscribeClick,
        getSubscribeLink,
    }

    return (
        <AuthenticationProviderContext.Provider value={values}>
            {children}
        </AuthenticationProviderContext.Provider>
    )
}
