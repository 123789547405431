import { AppState, isPuzzlePage } from '@news-mono/web-common'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
    StyledAthena,
    StyledMain,
    StyledSidebar,
} from '../../compositions/Athena/Athena.styled'
import { ContainerWidth, ThemeMargins } from '../../__styling/settings/metrics'
import { BaseCompositionProps } from '../types'

export type SidebarOptions = 'none' | 'visibleMobile' | 'hiddenMobile'
export type ElementOptions = 'div' | 'article'

export interface AthenaProps extends BaseCompositionProps {
    mainContent: React.ReactElement<any>
    sidebarContent: React.ReactElement<any>
    verticalSpacing?: keyof ThemeMargins
    containerWidth?: ContainerWidth
    sidebarOptions: SidebarOptions
    elementType: 'div' | 'article'
}

export const Athena: React.FC<AthenaProps> = ({
    mainContent,
    sidebarContent,
    verticalSpacing,
    containerWidth,
    elementType,
    sidebarOptions = 'hiddenMobile',
}) => {
    const renditionType = useSelector(
        (state: AppState) => state.render.renditionType,
    )
    const location = useLocation()

    const isPuzzlePageAppRendition =
        renditionType === 'app' && isPuzzlePage({ location })

    return (
        <StyledAthena
            sidebarOptions={sidebarOptions}
            hideSidebar={isPuzzlePageAppRendition}
            verticalSpacing={verticalSpacing}
            containerWidth={containerWidth}
        >
            <StyledMain>{mainContent}</StyledMain>

            {sidebarOptions !== 'none' && (
                <StyledSidebar sidebarOptions={sidebarOptions}>
                    {sidebarContent}
                </StyledSidebar>
            )}
        </StyledAthena>
    )
}
Athena.displayName = 'Athena'
