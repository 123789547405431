import { Logger } from 'typescript-log'

let moatYieldIntelligenceInterval: any = null
const MOAT_YI_RETRY_INTERVAL = 250

export async function waitUntilMoatYieldIntelligenceIsLoadedForPage(
    cancelled: () => boolean,
    log: Logger,
) {
    return new Promise((resolve) => {
        let count = 0
        // Clear if interval is set already
        if (moatYieldIntelligenceInterval) {
            clearInterval(moatYieldIntelligenceInterval)
        }
        const start = Date.now()
        moatYieldIntelligenceInterval = setInterval(() => {
            // Give up after a certain number of retries as we need to load ads,
            // and this is being awaited as additional work
            count += MOAT_YI_RETRY_INTERVAL

            // If the renderKey has changed this particular interval instance is no
            // longer required so we can just resolve
            if (cancelled()) {
                clearInterval(moatYieldIntelligenceInterval)
                resolve(true)
            }

            if (typeof window !== 'undefined' && window.moatPrebidApi) {
                const safetyDataAvailable =
                    window.moatPrebidApi?.safetyDataAvailable()
                const slotDataAvailable =
                    window.moatPrebidApi?.slotDataAvailable()

                // Typically safety data & slot data will only be available against prod
                // Only safety data will be available on other environments
                if (safetyDataAvailable && slotDataAvailable) {
                    clearInterval(moatYieldIntelligenceInterval)
                    log.debug(
                        { initialisationTime: Date.now() - start },
                        `GPT Api: Moat YI ready to be used`,
                    )
                    resolve(true)
                } else {
                    log.debug(
                        { safetyDataAvailable, slotDataAvailable },
                        'GPT Api: MOAT YI Not loaded',
                    )
                }
            }

            // Lets retry until a second has elapsed
            if (count >= 1000) {
                clearInterval(moatYieldIntelligenceInterval)
                log.debug(
                    'GPT Api: Timeout exceeded waiting for moat to initialize or no slot data is available',
                )
                resolve(false)
            }
        }, MOAT_YI_RETRY_INTERVAL)
    })
}
