import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { StaticRoutes, Topic } from '@news-mono/web-common'
import { layout } from '../../App.routing'

const fixturesImage = require('./assets/meta.png')

export const aflTopicForAdTargeting: Topic = {
    title: 'Afl',
    id: 'sport/afl',
    seoTitle: 'Afl',
    metadata: {},
    parent: {
        title: 'Sport',
        id: 'sport',
        seoTitle: 'Sport',
        metadata: {},
    },
}

export const aflMatchCentreRoutes: StaticRoutes<TheWestSection> = {
    '/sport/afl/fixtures': ({ getAdTargeting, config, store }) => {
        const isRouteEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'the-west-match-centre',
        )
        if (!isRouteEnabled) {
            return null
        }
        return {
            kind: 'page',
            heading:
                '2023 AFL Match Centre | Follow Every Kick, Mark & Goal | The West Australian',
            pageType: 'static',
            hideHeading: true,
            pageMeta: {
                title: '2023 AFL Match Centre | Follow Every Kick, Mark & Goal | The West Australian',
                description: `Get in on the action with The West Australian's 2023 AFL Match Centre. Follow every kick, mark and goal with live scores, stats and match summaries. Stay informed with detailed player stats and a live match timeline. Don't miss a moment of the excitement.`,
                meta: [
                    {
                        property: 'og:image',
                        content: fixturesImage,
                    },
                    {
                        name: 'twitter:image',
                        content: fixturesImage,
                    },
                    {
                        property: 'og:image:height',
                        content: '400',
                    },
                    {
                        property: 'og:image:width',
                        content: '400',
                    },
                ],
            },
            socialMeta: {
                title: '',
                description: '',
            },

            noMetaTitleTemplate: true,
            adTargeting: getAdTargeting(
                'fixtures',
                'sport',
                aflTopicForAdTargeting,
            ),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'box',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: 'outerMargin',
                        verticalGutters: ['outerMargin', 'unset'],
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'section-header',
                                props: {
                                    heading: 'Finals Fixtures',
                                    hasTopMargin: true,
                                },
                            }),
                            layout.component({
                                type: 'afl-fixtures-widget',
                                props: {
                                    competitionId: '12039',
                                },
                            }),
                            layout.component({
                                type: 'section-header',
                                props: {
                                    heading: 'Fixtures',
                                    hasTopMargin: true,
                                },
                            }),
                            layout.component({
                                type: 'afl-fixtures-widget',
                                props: {},
                            }),
                            // AFL NEWS HEADING
                            // AFL NEWS DATA
                            layout.component({
                                type: 'taboola',
                                feature: 'taboola-regionals',
                                props: {
                                    taboolaContainerId:
                                        'taboola-below-category-thumbnails',
                                    locationType: 'category',
                                },
                            }),
                        ],
                    },
                }),
            ],
        }
    },
}
