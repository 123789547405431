import { useTheme } from '@emotion/react'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    AppState,
    Block,
    CardEvents,
    CollectionEvent,
    LinkClickedEvent,
    PlayerEvent,
    RenderTargetContext,
    RequiredAccess,
    useToggleState,
} from '@news-mono/web-common'
import React from 'react'
import { useSelector } from 'react-redux'
import { Logger } from 'typescript-log'
import { GetVideoQueue } from '../../content/Video/PlayerInterface'
import { StyledBlockContent } from '../../publication/ArticleBlockContent/ArticleBlockContent.styled'
import { InlinePositionedContent } from '../../templates/Publication/Publication.props'
import { LazyRegistrationWall } from '../../user-registration/breach/components/RegistrationWall/RegistrationWall.lazy'
import { ArticleBlock } from './ArticleBlock'
import { StyledMobilePaddingContainer } from '../../templates/Publication/TheNightlyPublication/TheNightlyPublication.styled'
import { ConditionalWrapper } from '../../__helpers/ConditionalWrapper'

export const articleContentID = 'ArticleContent'
const regWallPosition = 3

export interface ArticleBlockContentProps {
    showInlineVideoTitles?: boolean
    blocks: Block[]
    targetedContentElement?: React.ReactElement<any>
    inlinePositionedContent?: InlinePositionedContent[]
    onEvent: (
        event: PlayerEvent | LinkClickedEvent | CardEvents | CollectionEvent,
    ) => void
    log: Logger
    publicationPathname?: string
    publicationKind?: string
    adUnitPath: string
    className?: string
    getVideoQueue?: GetVideoQueue
    requiredAccess?: RequiredAccess
    isEventPost?: boolean
}

interface AmpButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    on: string
}
const AmpButton = (props: AmpButtonProps) => <button {...props} />
export const ArticleBlockContent: React.FC<ArticleBlockContentProps> = ({
    blocks,
    targetedContentElement,
    inlinePositionedContent,
    onEvent,
    log,
    publicationPathname,
    showInlineVideoTitles,
    adUnitPath,
    className,
    getVideoQueue,
    requiredAccess,
    publicationKind,
    isEventPost,
}): JSX.Element | null => {
    const toggles = useToggleState()
    const { isLoggedIn, hasAttemptedValidation } = useSelector(
        (state: AppState) => state.authentication,
    )
    const theme = useTheme()

    // Im not sure if the logic for checking to be logged in and whether the app has
    // attemptedValidation should be done here or within the regwall component?
    const hasRegistrationWall =
        isFeatureEnabled(toFeatureState(toggles), 'registration-wall') &&
        !isLoggedIn &&
        requiredAccess?.level === 'registered'

    const { renderTarget } = React.useContext(RenderTargetContext)
    const isReadMoreEnabledTwPn = !!(
        isFeatureEnabled(toFeatureState(toggles), 'amp-read-more') &&
        renderTarget === 'amp'
    )

    const isReadMoreEnabledSeven =
        renderTarget === 'amp' &&
        theme.kind === 'sevennews' &&
        !!isFeatureEnabled(
            toFeatureState(toggles),
            '7-news-taboola-read-more-amp-articles',
        )

    const isReadMoreEnabled = isReadMoreEnabledTwPn || isReadMoreEnabledSeven

    if (!blocks) {
        return null
    }

    const contentBlocks = (
        hasRegistrationWall ? blocks.slice(0, regWallPosition) : blocks
    ).map<JSX.Element | undefined>((block) => {
        /**
         * Dev note:
         *
         * If you are modifying the article content blocks make sure they render on
         * Google News RSS feeds as well. The issue we've previously run into was
         * that because the RSS feed renders the entire article content without a
         * router then the web links will cause an error.
         *
         * To test the Google News RSS feed you can run 7 News locally and go to
         * http://localhost:3040/rss?key=wn0at58dv9l5qg64nzwg
         */
        return (
            //add padding to thenightly blocks to offset the luna container
            <ConditionalWrapper
                condition={
                    block.kind !== 'inline' &&
                    theme.kind === 'thenightly' &&
                    (publicationKind !== 'event' || isEventPost !== true)
                }
                Wrapper={({ children }) => (
                    <StyledMobilePaddingContainer>
                        {children}
                    </StyledMobilePaddingContainer>
                )}
            >
                <ArticleBlock
                    block={block}
                    onEvent={onEvent}
                    log={log}
                    adUnitPath={adUnitPath}
                    getVideoQueue={getVideoQueue}
                    showInlineVideoTitles={showInlineVideoTitles}
                    publicationPathname={publicationPathname}
                    publicationKind={publicationKind}
                />
            </ConditionalWrapper>
        )
    })

    if (hasRegistrationWall && hasAttemptedValidation) {
        contentBlocks.push(
            <LazyRegistrationWall
                key={regWallPosition}
                position={regWallPosition}
                onEvent={onEvent}
            />,
        )
    }

    if (!hasRegistrationWall) {
        // Just be aware that if multiple items are passed in with the same position, the last will take precedence
        if (
            inlinePositionedContent !== undefined &&
            inlinePositionedContent.length > 0
        ) {
            inlinePositionedContent.forEach((inline, index) => {
                const keyedInlineElement = React.cloneElement(inline.element, {
                    key: `inlineContentElement-${index}`,
                })

                // if inline.position === undefined it default to 1 as per previous logic, same with -1
                // indexes > conentBlocks just go last
                contentBlocks.splice(
                    inline.position !== undefined && inline.position !== -1
                        ? inline.position
                        : 1,
                    0,
                    keyedInlineElement,
                )
            })
        }

        // Targeted content displays in the middle if content blocks >= 8
        if (targetedContentElement !== undefined) {
            const keyedTargetedElement = React.cloneElement(
                targetedContentElement,
                {
                    key: 'targetedContentElement',
                },
            )

            if (contentBlocks.length >= 8) {
                const halfwayPoint = Math.floor(contentBlocks.length / 2)
                contentBlocks.splice(halfwayPoint, 0, keyedTargetedElement) // Insert the targetedContentElement
            } else {
                // targetedContentElement displays at the end at content blocks ( < 8 )
                contentBlocks.push(keyedTargetedElement)
            }
        }
    }

    return (
        <StyledBlockContent
            className={className}
            id={articleContentID}
            hasRegistrationWall={hasRegistrationWall}
        >
            {isReadMoreEnabled && contentBlocks.length > 4 ? (
                <div
                    className="amp-article-body amp_cropped"
                    data-amp-bind-class="amp_expand"
                >
                    {contentBlocks}
                    <div
                        className="amp-read-more-box"
                        data-amp-bind-hidden="hiddenState"
                    >
                        <AmpButton
                            className={`amp-read-more-btn amp-read-more-btn-${theme.kind}`}
                            on="tap:AMP.setState({amp_expand: 'amp-article-body', hiddenState: true})"
                        >
                            READ MORE
                        </AmpButton>
                    </div>
                </div>
            ) : (
                contentBlocks
            )}
        </StyledBlockContent>
    )
}
ArticleBlockContent.displayName = 'ArticleBlockContent'
