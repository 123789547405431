import { metrics, ResponsivePictureLayout } from '@news-mono/component-library'
import { TogglesReduxState } from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { getThemedSegment } from './get-themed-segment'
import { getOlympicsSegment } from './olypmics-segment'

export const breakingNews = layout.component({
    type: 'breaking-news',
    props: {
        dataDefinitionArgs: {
            type: 'curation',
            name: 'breaking-news',
            offset: 0,
            pageSize: 1,
        },
    },
})

export const promoStrap = layout.component({
    type: 'breaking-news-promo-strap',
    props: {
        dataDefinitionArgs: {
            type: 'curation',
            name: 'breaking-news-promo-strap',
            offset: 0,
            pageSize: 1,
        },
    },
})

const westLiveBillboard = layout.component({
    type: 'the-west-live-billboard',
    props: {
        showSponsorLogo: true,
        verticalSpacing: ['unset', 'md'],
        twlLogoAdUnit: {
            hiddenUntilLoaded: false,
            noticePosition: 'none',
            padding: [metrics.thewest.margins.md, 0, 0, 0],
            slot: {
                id: 'twl-banner',
                size: 'theWestLiveLogo',
            },
            adType: 'inline',
        },
    },
})

function homepageHeroSegment() {
    return layout.component({
        type: 'sierra',
        props: {
            verticalSpacing: 'gridGap',
            fixedRatios: ['16:9'],
            heroImageLayout: ResponsivePictureLayout.ObjectFitContain,
            cardOrientation: {
                type: 'Landscape-Portrait',
                maxBreakpoint: 'md',
            },
            cardLayout: ['the-west-hero', 'hero-support'],
            restrictedVideoPlaybackMode: 'entitled',
            dataDefinitionArgs: {
                type: 'curation',
                name: 'home',
                offset: 0,
                pageSize: 2,
            },
        },
    })
}

const mobileParalaxAdUnit = [
    layout.component({
        type: 'ad-unit',
        feature: 'running-parallax',
        props: {
            noticePosition: 'below-center',
            hiddenUntilLoaded: true,
            breakout: {
                kind: 'percentage',
                value: 5,
                breakpoint: metrics.thewest.siteMetrics.mainContentWidth,
            },
            padding: [0, 0, metrics.thewest.margins.md, 0],
            slot: {
                id: 'mobile-parallax',
                size: 'mobileParallax',
            },
            adType: 'inline',
        },
    }),
    layout.component({
        type: 'ad-unit',
        feature: 'running-parallax',
        invertFeatureToggle: true,
        props: {
            noticePosition: 'below-center',
            padding: [metrics.thewest.margins.md, 0],
            slot: {
                id: 'mrec-parallax',
                size: 'mobileMrec',
            },
            adType: 'inline',
        },
    }),
]

const homepageSubheroSegment = layout.component({
    type: 'uniform',
    props: {
        section: 'default',
        verticalSpacing: 'xl',
        cardOrientation: { type: 'Landscape-Portrait' },
        cardLayout: [
            { type: 'november', listLength: 5 },
            ['large-card'],
            ['large-card-mdNoImage', 'large-card-mdNoImage'],
            ['large-card-mdNoImage', 'large-card-mdNoImage'],
        ],
        dataDefinitionArgs: {
            type: 'aggregated',
            sources: [
                {
                    type: 'curation',
                    name: 'top-stories',
                    offset: 0,
                    pageSize: 5,
                },
                {
                    type: 'curation',
                    name: 'home',
                    offset: 2,
                    pageSize: 6,
                    injectMetadataStory: {
                        kind: 'marketing-redirect-tile',
                        metadataKey: 'promotional-card',
                        position: 1,
                    },
                },
            ],
            keepDuplicates: true,
        },
    },
})

const paralaxAdUnit = [
    layout.component({
        type: 'ad-unit',
        feature: 'running-parallax',
        props: {
            noticePosition: 'below-center',
            hiddenUntilLoaded: true,
            breakout: {
                kind: 'percentage',
                value: 5,
                breakpoint: metrics.thewest.siteMetrics.mainContentWidth,
            },
            padding: [0, 0, metrics.thewest.margins.lg, 0],
            slot: {
                id: 'parallax',
                size: 'desktopParallax',
            },
            adType: 'inline',
        },
    }),
    layout.component({
        type: 'ad-unit',
        feature: 'running-parallax',
        invertFeatureToggle: true,
        props: {
            noticePosition: 'below-center',
            padding: [metrics.thewest.margins.lg, 0],
            slot: {
                id: 'leaderboard-billboard-parallax',
                size: 'desktopLeaderboardBillboard',
            },
            adType: 'inline',
        },
    }),
]

const emptyBox = layout.component({
    //tech debt: dummy component to fill alterrnate of kings coronation ternary
    type: 'iframe',
    props: {
        link: '',
        height: 0,
    },
})

export function homeSegment(toggleValues: TogglesReduxState) {
    return layout.nestedComposition({
        type: 'thor',
        props: {
            hasBackgroundFill: false,
            containerWidth: '100%',
            verticalGutters: false,
            horizontalGutters: true,
        },
        contentAreas: {
            main: [
                westLiveBillboard,
                homepageHeroSegment(),
                ...mobileParalaxAdUnit,
                homepageSubheroSegment,
                getThemedSegment({
                    featureToggle: 'go-matildas-homepage-collection',
                    layoutVersion: '4-card',
                    curation: 'go-matildas',
                    themeOverride: 'go-matildas',
                }),
                ...paralaxAdUnit,
            ],
        },
    })
}

/** Implement content after the first ad slot which can be used at full width with no parent padding */
export function postHomeSegment(toggleValues: TogglesReduxState) {
    return [...getOlympicsSegment(toggleValues)]
}
