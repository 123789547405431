import { useEffect, useState } from 'react'
import { useScript } from 'use-script'

const RETRY_INTERVAL = 1000

export const useOptaWidgetStart = () => {
    const [optaWidgetsStarted, setOptaWidgetsStarted] = useState<boolean>(false)

    const { loading, error } = useScript({
        src: `https://secure.widget.cloud.opta.net/v3/v3.opta-widgets.js`,
        defer: '',
    })

    useEffect(() => {
        if (!loading && !error) {
            if (optaWidgetsStarted === false) {
                const intervalId = setInterval(() => {
                    if (
                        typeof window !== 'undefined' &&
                        window.Opta &&
                        window.Opta.HiveManager
                    ) {
                        try {
                            window.Opta.start()
                            setOptaWidgetsStarted(true)
                        } catch (err) {
                            console.error('Failed to start Opta widgets', err)
                        }
                    }
                }, RETRY_INTERVAL)
                return () => clearInterval(intervalId)
            }
        } else {
            console.error('Failed to fetch Opta widgets', error)
        }
    }, [optaWidgetsStarted, loading, error])
}
