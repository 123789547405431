import React from 'react'
import { QueryClient } from 'react-query'
import { StyledPagesCTAContainer } from './TNCreateAccountCTA.styled'
import { VerifyEmailContent } from './components/VerifyEmailContent'
import { SaveNameContent } from './components/SaveNameContent'
import { LoginContent } from './components/LoginContent'
import { SignupOrigin } from '@news-mono/web-common'
import { UserSettingProps } from '../../../user-setting'
import { CTAText } from '../../warden'
import { CreateAccountCTA } from '../../warden/account-modify'

export const queryClient = new QueryClient()

export interface PageCtaProps {
    notLoggedInText: CTAText
    emailVerifyText: CTAText
    blockStyle?: 'comments' | 'default'
    userSettings?: UserSettingProps
    componentOrigin?: SignupOrigin
}

export const TNPageCreateAccountCTA: React.FC<PageCtaProps> = (props) => {
    const {
        blockStyle,
        notLoggedInText,
        componentOrigin,
        emailVerifyText,
        userSettings,
    } = props

    const TNLoginContent = () => (
        <StyledPagesCTAContainer blockStyle={blockStyle}>
            <LoginContent
                text={notLoggedInText}
                componentOrigin={componentOrigin}
            />
        </StyledPagesCTAContainer>
    )
    const TNEmailVerifiedContent = ({ userId }: { userId: string }) => (
        <StyledPagesCTAContainer blockStyle={blockStyle}>
            <VerifyEmailContent text={emailVerifyText} userId={userId} />
        </StyledPagesCTAContainer>
    )

    const TNSaveNameContent = ({ userId }: { userId: string }) => (
        <StyledPagesCTAContainer blockStyle={blockStyle}>
            <SaveNameContent {...userSettings!} userId={userId} />
        </StyledPagesCTAContainer>
    )

    return (
        <CreateAccountCTA
            {...props}
            LoginContent={TNLoginContent}
            SaveNameContent={TNSaveNameContent}
            VerifyEmailContent={TNEmailVerifiedContent}
            isFeatureEnabled={true}
        />
    )
}
