import { regionalSiteConfig } from '@news-mono/common'
import {
    ArticleLikePublication,
    ArticlePublication,
    BaseClientConfig,
    getBaseUrl,
    getSectionMetaInfo,
    isArticleLikeType,
} from '@news-mono/web-common'
import {
    ArticleV4DTO,
    EventV4DTO,
    GalleryV4DTO,
    PageMetaDTO,
    SectionMetaDTO,
} from '@west-australian-newspapers/publication-types'

/**
 * If a canonical URL is set on a publication (i.e. the publication is an article which is not the original source) return a link to the original source
 * @param publication
 */
export function getCanonicalLink(publication: ArticleLikePublication) {
    if (!isArticleLikeType(publication)) {
        return
    }

    if (!publication.canonicalUrl || !publication.canonicalTitle) {
        return
    }

    const canonicalLink = {
        text: publication.canonicalTitle,
        href: publication.canonicalUrl,
    }

    return canonicalLink
}

/**
 * The get the section info for the the matching source of the article. For example if the article's source is "Albany Advertiser"
 * we will return the section info for that.
 **/
function sectionForArticleSource(sectionMeta: SectionMetaDTO, source: string) {
    const regionalKeys = Object.keys(regionalSiteConfig) as Array<
        keyof typeof regionalSiteConfig
    >
    const sectionId = regionalKeys.find(
        (key) => regionalSiteConfig[key].publicationSource === source,
    )
    return sectionId ? getSectionMetaInfo(sectionMeta, sectionId) : undefined
}

function buildCanonicalUrl(
    baseUrl: string,
    primaryTopic: string,
    slug: string,
) {
    const builtUrl = `${baseUrl}/${primaryTopic}/${slug}`
    return builtUrl
}

export function ampRegionalCanonicalMeta(
    config: BaseClientConfig,
    sectionMeta: SectionMetaDTO,
    article: ArticlePublication,
): string | undefined {
    const sectionInfo = sectionForArticleSource(sectionMeta, article.source)
    if (article.source === 'The West Australian' && !sectionInfo) {
        return
    }

    const baseUrl = getBaseUrl(config.publicUrl, sectionInfo)
    return buildCanonicalUrl(baseUrl, article.primaryTopic.id, article.slug)
}

export function regionalCanonicalMeta(
    config: BaseClientConfig,
    sectionMeta: SectionMetaDTO,
    article: ArticleV4DTO | GalleryV4DTO | EventV4DTO,
): PageMetaDTO | undefined {
    const sectionInfo = sectionForArticleSource(sectionMeta, article.source)
    if (article.source !== 'The West Australian' && !sectionInfo) {
        return
    }

    const baseUrl = getBaseUrl(config.publicUrl, sectionInfo)

    return {
        link: [
            {
                rel: 'canonical',
                href: buildCanonicalUrl(
                    baseUrl,
                    article.topics.primary.id,
                    article.slug,
                ),
            },
        ],
    }
}
