import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { StaticRoute, Topic } from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { getTopicSponsor } from '../topic/get-topic-sponsor'

const crosswordSocialImage = require('./assets/crossword-social.png')
const sudokuSocialImage = require('./assets/sudoku-social.png')
const targetSocialImage = require('./assets/target-social.png')
const wordSearchSocialImage = require('./assets/wordSearch-social.png')

function getSocialImage(gameTitle: string) {
    switch (gameTitle) {
        case 'Word Search':
            return wordSearchSocialImage
        case 'Sudoku':
            return sudokuSocialImage
        case 'Crossword':
            return crosswordSocialImage
        case 'Target':
            return targetSocialImage
    }
}

export function getPuzzleRoute(
    topic: Topic,
    gameFrameName: string,
    gameUrl: string,
): StaticRoute<TheWestSection> {
    return ({ getAdTargeting, store, config }) => {
        if (
            !isFeatureEnabled(
                toFeatureState(store.getState().toggles),
                'puzzles',
            )
        ) {
            return null
        }

        const swgCampaignEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'breach-puzzles-swg-30',
        )

        const canonicalUrl = config.publicUrl + `/${topic.id}`
        const renditionType = store.getState().render.renditionType

        return {
            kind: 'page',
            heading: 'Puzzles',
            pageType: 'static',
            hideHeading: true,
            refreshOnInactive: false,
            pageMeta: {
                title: topic.seoTitle,
                description: topic.seoDescription,
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: [
                    {
                        property: 'og:image',
                        content: getSocialImage(topic.title),
                    },
                    {
                        name: 'twitter:image',
                        content: getSocialImage(topic.title),
                    },
                    {
                        property: 'og:image:height',
                        content: '630',
                    },
                    {
                        property: 'og:image:width',
                        content: '1200',
                    },
                    {
                        property: 'og:description',
                        content: topic.seoDescription,
                    },
                    {
                        property: 'twitter:description',
                        content: topic.seoDescription,
                    },
                ],
            },

            adTargeting: getAdTargeting(
                'home',
                'default',
                topic.parent ?? topic,
            ),
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'box',
                    props:
                        renditionType !== 'app'
                            ? {
                                  containerWidth:
                                      metrics.thewest.siteMetrics
                                          .mainContentWidth,
                                  horizontalGutters: 'outerMargin',
                                  verticalGutters: ['outerMargin', 'unset'],
                              }
                            : {},
                    contentAreas: {
                        main:
                            renditionType !== 'app'
                                ? [
                                      layout.component({
                                          type: 'breadcrumb',
                                          props: {
                                              topic,
                                              sponsor: getTopicSponsor(topic),
                                              enableHeaderTag: true,
                                              verticalSpacing: 'lg',
                                          },
                                      }),
                                  ]
                                : [],
                    },
                }),

                layout.composition({
                    type: 'zeus',
                    props: {
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        elementType: 'div',
                        sidebarOptions: 'visibleMobile',
                    },
                    contentAreas: {
                        main:
                            renditionType !== 'app'
                                ? [
                                      layout.component({
                                          type: 'puzzleIframe',
                                          requiresAccess: {
                                              level: 'subscriber',
                                              notAuthorized: layout.composition(
                                                  {
                                                      type: 'box',
                                                      props: {
                                                          containerWidth:
                                                              metrics.thewest
                                                                  .siteMetrics
                                                                  .mainContentWidth,
                                                          hasBackgroundFill:
                                                              true,
                                                          verticalSpacing: 'lg',
                                                          horizontalGutters:
                                                              'unset',
                                                      },
                                                      contentAreas: {
                                                          main: [
                                                              layout.component({
                                                                  type: 'breachscreen',
                                                                  props: {
                                                                      breachwall:
                                                                          'puzzles',
                                                                      title: isFeatureEnabled(
                                                                          toFeatureState(store.getState().toggles),
                                                                          'default-price-increase',
                                                                      )
                                                                          ? 'Access new puzzles every day'
                                                                          : 'Access puzzles for only $1 a day',
                                                                      breachDeal:
                                                                          'Puzzles',
                                                                      breachType:
                                                                          'Puzzles',
                                                                      breachCreativeName:
                                                                          'Puzzles',
                                                                      terms: '',
                                                                      section:
                                                                          'default',
                                                                      renditionType:
                                                                          renditionType,
                                                                      enableSwgCampaign:
                                                                          swgCampaignEnabled,
                                                                  },
                                                              }),
                                                          ],
                                                      },
                                                  },
                                              ),
                                          },
                                          props: {
                                              name: gameFrameName,
                                              link: gameUrl,
                                              height: 700,
                                          },
                                      }),
                                      layout.nestedComposition({
                                          type: 'box',
                                          props: {},
                                          contentAreas: {
                                              main: [
                                                  layout.component({
                                                      type: 'ad-unit',
                                                      props: {
                                                          noticePosition:
                                                              'above-center',
                                                          hiddenUntilLoaded:
                                                              false,
                                                          padding: [
                                                              0,
                                                              0,
                                                              metrics.sevennews
                                                                  .margins.lg,
                                                              0,
                                                          ],
                                                          slot: {
                                                              id: 'puzzles-leaderboard-two',
                                                              size: 'leaderboard',
                                                          },
                                                          adType: 'inline',
                                                      },
                                                  }),
                                              ],
                                          },
                                      }),
                                  ]
                                : [
                                      layout.component({
                                          type: 'puzzleIframe',
                                          props: {
                                              name: gameFrameName,
                                              link: gameUrl,
                                              height: 700,
                                          },
                                      }),
                                  ],
                        sidebar:
                            renditionType !== 'app'
                                ? [
                                      layout.component({
                                          type: 'ad-unit',
                                          props: {
                                              noticePosition: 'below-center',
                                              padding: [
                                                  0,
                                                  0,
                                                  metrics.thewest.margins.md,
                                                  0,
                                              ],
                                              slot: {
                                                  id: 'sidebar-top',
                                                  size: 'desktopMrecHalfPage',
                                                  pageType: 'static',
                                              },
                                              adType: 'inline',
                                          },
                                      }),
                                      layout.nestedComposition({
                                          type: 'box',
                                          props: {
                                              stickyOffset: 350,
                                              fillContainer: true,
                                          },
                                          contentAreas: {
                                              main: [
                                                  layout.component({
                                                      type: 'ad-unit',
                                                      props: {
                                                          noticePosition:
                                                              'below-center',
                                                          padding: [
                                                              0,
                                                              0,
                                                              metrics.thewest
                                                                  .margins.md,
                                                              0,
                                                          ],
                                                          slot: {
                                                              id: 'sidebar-middle',
                                                              size: 'desktopMrec',
                                                              pageType:
                                                                  'static',
                                                          },
                                                          adType: 'inline',
                                                      },
                                                  }),

                                                  layout.component({
                                                      type: 'juliet',
                                                      props: {
                                                          cardType: {
                                                              type: 'landscape',
                                                              format: 'landscape-smallImage',
                                                          },
                                                          removeHorizontalGutters:
                                                              true,
                                                          kickerMode: 'hidden',
                                                          contentIsTangential:
                                                              true,
                                                          sectionHeader: {
                                                              heading:
                                                                  'Top Stories',
                                                          },
                                                          dataDefinitionArgs: {
                                                              type: 'curation',
                                                              name: 'home',
                                                              offset: 0,
                                                              pageSize: 3,
                                                          },
                                                      },
                                                  }),
                                              ],
                                          },
                                      }),
                                  ]
                                : [],
                    },
                }),
            ],
        }
    }
}
