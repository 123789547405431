import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { authCheckAccessToken } from '@news-mono/web-common'
import { UserSettingProps } from '../../../../user-setting'
import { useChangeName } from '../../../../user-setting/hooks/useChangeName'
import { WardenButton } from '../../../warden'
import {
    StyledSaveNameContentInput,
    StyledSaveNameContentSpinner,
} from '../../../warden/account-modify/components/SaveNameContent.styled'
import {
    validateNames,
    ValidationError,
} from '../../../warden/account-modify/validateNames'
import {
    AccountLink,
    FormErrorText,
    FormInputWrapper,
    SaveNameForm,
    StyledContainer,
    StyledText,
    StyledTextContainer,
    StyledTitle,
} from './SaveNameContent.styled'

type FormData = {
    firstName: string
    lastName: string
}

type SaveNameContentProps = {
    userId: string
    userSettings: UserSettingProps
}

export const SaveNameContent = ({
    userId,
    userSettings,
}: SaveNameContentProps) => {
    const dispatch = useDispatch()
    const [isRefreshingAuth, setIsRefreshingAuth] = useState<boolean>(false)

    const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
        [],
    )

    const [formData, setFormData] = useState<FormData>({
        firstName: '',
        lastName: '',
    })

    const { mutateAsync: changeName, isLoading: isChangingName } =
        useChangeName({
            firstName: formData.firstName.trim(),
            lastName: formData.lastName.trim(),
            userId,
            onSuccess: () => {
                setIsRefreshingAuth(true)

                dispatch(
                    authCheckAccessToken({
                        ...userSettings,
                        invocation: 'auto',
                        forceRefresh: true,
                    }),
                )
                setInterval(() => {
                    // Just incase something goes wrong with the auth refresh
                    setIsRefreshingAuth(false)
                }, 5000)
            },
            onError: () => {},
        })

    const isLoading = isChangingName || isRefreshingAuth

    const onSave = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const errors = validateNames(
            formData.firstName.trim(),
            formData.lastName.trim(),
        )
        setValidationErrors(errors)

        if (errors.length > 0) {
            return
        }

        changeName()
    }

    console.log('AAA')

    return (
        <StyledContainer>
            <StyledTextContainer>
                <StyledTitle>Want to leave a comment?</StyledTitle>
                <StyledText>
                    Finish your profile by entering your first and last name.
                    You can change this later in your{' '}
                    <AccountLink to={'/manage-account'}>Account</AccountLink>
                </StyledText>
            </StyledTextContainer>
            <SaveNameForm method="POST" onSubmit={onSave}>
                <FormInputWrapper>
                    <StyledSaveNameContentInput
                        hasError={validationErrors.some(
                            ({ type }) => type === 'first-name',
                        )}
                        disabled={isLoading}
                        name="first-name"
                        placeholder="First name"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                firstName: e.currentTarget.value,
                            })
                        }
                    />
                    {validationErrors
                        .filter(({ type }) => type === 'first-name')
                        .map((error) => (
                            <FormErrorText>{error.message}</FormErrorText>
                        ))}
                </FormInputWrapper>

                <FormInputWrapper>
                    <StyledSaveNameContentInput
                        hasError={validationErrors.some(
                            ({ type }) => type === 'last-name',
                        )}
                        disabled={isLoading}
                        name="last-name"
                        placeholder="Last name"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                lastName: e.currentTarget.value,
                            })
                        }
                    />
                    {validationErrors
                        .filter(({ type }) => type === 'last-name')
                        .map((error) => (
                            <FormErrorText>{error.message}</FormErrorText>
                        ))}
                </FormInputWrapper>
                <WardenButton type="submit" disabled={isLoading}>
                    {isLoading ? <StyledSaveNameContentSpinner /> : 'Save'}
                </WardenButton>
            </SaveNameForm>
        </StyledContainer>
    )
}
