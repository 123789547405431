import { useTheme } from '@emotion/react'
import {
    AppState,
    createEditionSelectItemEvent,
    entitledToViewContent,
    Issue,
    PremiumPublicationsSet, useFeature,
} from '@news-mono/web-common'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSection } from '../../__helpers'
import { useProduct } from '../../__product'
import { calcRem, colors } from '../../__styling'
import {
    StyledEPaperArrow,
    StyledEPaperBanner,
    StyledEPaperBannerWrapper,
    StyledEPaperButton,
    StyledEPaperChevon,
    StyledEPaperCoffee,
    StyledEPaperCoverLeft,
    StyledEPaperCoverRight,
    StyledEPaperDateTime,
    StyledEPaperHeading,
    StyledEPaperLink,
    StyledEPaperPNButton,
    StyledEPaperSubheading,
    StyledEpaperSubscribeButton,
    StyledEpaperSubscribeText,
    StyledEpaperSubscribeUnderlineText,
    StyledEpaperUnsubscribedBanner,
} from './ePaperPage.styled'
import { TheWestSection } from '@news-mono/common'

export type PNRegionalSections =
    | 'joondalup'
    | 'wanneroo'
    | 'stirling'
    | 'western-suburbs'
    | 'central'
    | 'southern'
    | 'canning'
    | 'cockburn'
    | 'fremantle'
    | 'melville'
    | 'mandurah-times'

export type ePaperRegions =
    | 'North'
    | 'South'
    | 'Central'
    | 'Mandurah'
    | 'default'

export interface ePaperPageProps {
    latestEdition: Issue
    allEditions: Issue[]
    onEvent: (event: any) => void
    regionalSection?: PNRegionalSections
}

export function selectPNRegion(publication: PNRegionalSections | undefined): {
    section: ePaperRegions
    color: string
} {
    if (
        publication === 'joondalup' ||
        publication === 'wanneroo' ||
        publication === 'stirling'
    ) {
        return { section: 'North', color: colors.perthnow.pinkThulite }
    }
    if (
        publication === 'western-suburbs' ||
        publication === 'central' ||
        publication === 'southern'
    ) {
        return { section: 'Central', color: colors.perthnow.purpleAmethyst }
    }
    if (
        publication === 'canning' ||
        publication === 'cockburn' ||
        publication === 'fremantle' ||
        publication === 'melville'
    ) {
        return { section: 'South', color: colors.perthnow.blueCerulean }
    }
    if (publication === 'mandurah-times') {
        return { section: 'Mandurah', color: colors.perthnow.greenTourmaline }
    }
    return { section: 'default', color: 'default' }
}

function getBannerImageBySection(
    section: ePaperRegions,
    device: 'mobile' | 'tablet' | 'desktop',
) {
    if (device === 'mobile') {
        return require(`./assets/${section}-mobile.jpg`)
    }
    if (device === 'tablet') {
        return require(`./assets/${section}-web.jpg`)
    }
    if (device === 'desktop') {
        return require(`./assets/${section}-web.jpg`)
    }
}

export interface ePaperPageProps {
    latestEdition: Issue
    allEditions: Issue[]
    onEvent: (event: any) => void
    regionalSection?: PNRegionalSections
    title: string
}

export const EPaperPage: React.FC<ePaperPageProps> = (props) => {
    const isEntitled = entitledToViewContent(
        {
            level: 'subscriber',
        },
        useSelector((state: AppState) => state.authentication),
    )

    const section = useSection()
    const product = useProduct()
    const theme = useTheme()
    const sectionInfo: { section: ePaperRegions; color: string } =
        selectPNRegion(props.regionalSection)
    const today = new Date()

    const [originUrl, setOriginUrl] = useState('')
    const pricePoint = useFeature('default-price-increase')
        ? '$8 per week'
        : '$1 per day'

    // Build heading text.
    let headingText = "Today's Digital Edition"
    if (product === 'thewest') {
        if (section !== 'default') {
            headingText = `${props.title} Latest Digital Edition`
        }
    } else if (product === 'perthnow') {
        if (props.regionalSection === 'mandurah-times') {
            headingText = `${props.title} Digital Edition`
        } else {
            headingText = `PerthNow ${props.title} Digital Edition`
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setOriginUrl(window.location.origin)
        }
    }, [])
    return (
        <>
            {theme.kind === 'perthnow' && (
                <StyledEPaperLink to={`${originUrl}/editions`} isPrimary={true}>
                    <StyledEPaperArrow />
                    All Suburbs
                </StyledEPaperLink>
            )}
            <StyledEPaperHeading>{headingText}</StyledEPaperHeading>
            {theme.kind === 'perthnow' && (
                <div style={{ paddingBottom: calcRem(16) }}>
                    Printed weekly on{' '}
                    {props.regionalSection === 'mandurah-times'
                        ? ' Wednesday'
                        : ' Thursday'}
                </div>
            )}
            {!isEntitled &&
            PremiumPublicationsSet[props.latestEdition.publicationName] ? (
                <StyledEpaperUnsubscribedBanner
                    bannerUrls={{
                        mobile: require('./assets/unsubscribed/img-epaper-bg-UNSUB-S.jpg'),
                        tablet: require('./assets/unsubscribed/img-epaper-bg-UNSUB-M.jpg'),
                        desktop: require('./assets/unsubscribed/img-epaper-bg-UNSUB-L.jpg'),
                    }}
                    backgroundGradient={
                        'linear-gradient(91deg, rgba(0, 12, 35, 0.8) 0.63%, rgba(0, 0, 0, 0) 126.65%)'
                    }
                >
                    <StyledEpaperSubscribeText>
                        Read the paper, as it was printed
                    </StyledEpaperSubscribeText>
                    <StyledEpaperSubscribeUnderlineText>
                        {pricePoint}
                    </StyledEpaperSubscribeUnderlineText>
                    <StyledEpaperSubscribeButton to={`/editions/subscribe`}>
                        Subscribe now
                        <StyledEPaperChevon />
                    </StyledEpaperSubscribeButton>
                </StyledEpaperUnsubscribedBanner>
            ) : (
                <StyledEPaperBanner
                    bannerUrls={{
                        desktop: getBannerImageBySection(
                            sectionInfo.section,
                            'desktop',
                        ),
                        mobile: getBannerImageBySection(
                            sectionInfo.section,
                            'mobile',
                        ),
                        tablet: getBannerImageBySection(
                            sectionInfo.section,
                            'tablet',
                        ),
                    }}
                >
                    <StyledEPaperBannerWrapper>
                        <StyledEPaperDateTime id={'timestamp-read-now'}>
                            {product === 'thewest' &&
                            section != 'default' &&
                            props.latestEdition.date.getDate() -
                                today.getDate() ===
                                0
                                ? "Today's paper"
                                : props.latestEdition?.date?.toLocaleDateString(
                                      'en-AU',
                                      {
                                          weekday: 'long',
                                          day: 'numeric',
                                          month: 'short',
                                      },
                                  )}
                        </StyledEPaperDateTime>
                        <StyledEPaperSubheading>
                            As it was printed
                        </StyledEPaperSubheading>
                        {theme.kind === 'perthnow' && (
                            <StyledEPaperPNButton
                                to={props.latestEdition?.url}
                                color={sectionInfo.color}
                                onClick={() =>
                                    props.onEvent(
                                        createEditionSelectItemEvent({
                                            issue: props.latestEdition,
                                            collectionName: `PerthNowEditionPage.${props.regionalSection}`,
                                            product,
                                            itemDetails: {
                                                category: `perthnow_${props.latestEdition.publicationName}_editions_page`,
                                                linkText: 'Read now',
                                                linkUrl:
                                                    props.latestEdition.url,
                                                itemName: `${
                                                    props.latestEdition
                                                        .publicationName
                                                } - ${props.latestEdition.date.toLocaleString()}`,
                                            },
                                        }),
                                    )
                                }
                            >
                                Read now{' '}
                                <StyledEPaperChevon aria-hidden={true} />
                            </StyledEPaperPNButton>
                        )}
                        {theme.kind === 'thewest' && (
                            <StyledEPaperButton
                                to={props.latestEdition?.url}
                                onClick={() =>
                                    props.onEvent(
                                        createEditionSelectItemEvent({
                                            issue: props.latestEdition,
                                            collectionName: `TheWestEditionPage.${props.regionalSection}`,
                                            product,
                                            itemDetails: {
                                                category: `thewest_${props.latestEdition.publicationName}_editions_page`,
                                                linkText: 'Read now',
                                                linkUrl:
                                                    props.latestEdition.url,
                                            },
                                        }),
                                    )
                                }
                            >
                                Read now{' '}
                                <span aria-hidden={true}>
                                    <StyledEPaperChevon />
                                </span>
                            </StyledEPaperButton>
                        )}
                    </StyledEPaperBannerWrapper>
                    <StyledEPaperCoverLeft
                        src={props.latestEdition?.coverUrl}
                        draggable={false}
                        aria-hidden={true}
                        alt={''}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = require('./assets/paper.jpg')
                        }}
                    />
                    <StyledEPaperCoverRight
                        draggable={false}
                        src={props.latestEdition?.coverUrl}
                        aria-hidden={true}
                        alt={''}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = require('./assets/paper.jpg')
                        }}
                    />
                    {theme.kind === 'thewest' && (
                        <>
                            <StyledEPaperCoffee
                                src={require('./assets/coffee.png')}
                                draggable={false}
                                aria-hidden={true}
                                alt={''}
                            />
                        </>
                    )}
                </StyledEPaperBanner>
            )}
        </>
    )
}

EPaperPage.displayName = 'ePaperPage'

export default EPaperPage
