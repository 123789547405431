import H from 'history'

// Converts a string into a url safe slug separated by -
export function slugify(text: string) {
    return (
        text
            .toString()
            .toLowerCase()
            // Replace spaces with -
            .replace(/\s+/g, '-')
            // Remove all non-word chars
            .replace(/[^\w-]+/g, '')
            // Replace multiple - with single -
            .replace(/--+/g, '-')
            // Trim - from start of text
            .replace(/^-+/, '')
            // Trim - from end of text
            .replace(/-+$/, '')
    )
}

export function find<T>(
    collection: T[],
    predicate: (value: T) => boolean,
): T | undefined {
    for (const value of collection) {
        if (predicate(value)) {
            return value
        }
    }

    return undefined
}

export function flatMap<T, Mapped>(
    collection: T[],
    map: (value: T, i: number) => Mapped[],
) {
    return collection.reduce((acc: Mapped[], val: T, i: number) => {
        map(val, i).forEach((v) => acc.push(v))
        return acc
    }, [])
}

export function scriptExists(src: string) {
    const scripts = document.getElementsByTagName('script')
    for (let i = scripts.length; i--; ) {
        if (scripts[i].src === src) {
            return true
        }
    }
    return false
}

export function insertSortedAsc<T>(
    arr: T[],
    toInsert: T,
    select: (item: T) => number,
) {
    for (let index = 0; index < arr.length; index++) {
        const val = select(arr[index])

        if (select(toInsert) === val) {
            return
        }
        if (select(toInsert) < val) {
            arr.splice(index, 0, toInsert)
            return
        }
    }
    arr.push(toInsert)
}

export function distinct<T>(arr: T[]): T[] {
    return arr.filter((el, pos) => arr.indexOf(el) === pos)
}

/**
 * Inserts a specified path into a segment of a url. Segment index is 0 based and includes the domain name
 * (which will be path number 0).
 */
export function insertPathIntoUrl(
    url: string,
    segmentIndex: number,
    toInsert: string,
) {
    const protocol =
        url.indexOf('https') !== -1
            ? 'https://'
            : url.indexOf('http') !== -1
            ? 'http://'
            : ''
    const hash = url.indexOf('#') !== -1 ? url.slice(url.indexOf('#')) : ''
    const queryStr =
        url.indexOf('?') !== -1
            ? url.slice(url.indexOf('?'), hash ? url.indexOf(hash) : undefined)
            : ''
    const path = url
        .slice(
            protocol.length,
            queryStr || hash ? url.indexOf(queryStr + hash) : undefined,
        )
        .split('/')

    path.splice(segmentIndex, 0, toInsert)

    return `${protocol}${path.join('/')}${queryStr}${hash}`
}

export function addUrlParams(
    url: string,
    params: Array<[string, string]>,
    overrideExisting = false,
) {
    return params.reduce(
        (acc, param) => addUrlParam(acc, param[0], param[1], overrideExisting),
        url,
    )
}

export const addUrlParam = (
    url: string,
    param: string,
    value: string,
    overrideExisting = false,
) => {
    // check if already set
    if (url.indexOf(param + '=') !== -1) {
        if (overrideExisting) {
            return url.replace(
                new RegExp(`(&|\\?)${param}=.+?(?:&|$)`),
                `$1${param}=${value}`,
            )
        } else {
            return url
        }
    }

    const addedParam = param + '=' + value

    if (url.indexOf('?') !== -1) {
        return url + '&' + addedParam
    }

    return url + '?' + addedParam
}

/**
 * Groups an array by a selector, if the selector is an object then use the 3rd
 * parameter to serialise it into a string
 *
 * @returns an array of {key, values }[]
 */
export function groupBy<T, TKey>(
    items: T[],
    selector: (val: T) => TKey,
    toString = (val: TKey) => (val as any).toString(),
) {
    const lookup: { [key: string]: T[] } = {}
    return items.reduce<Array<{ key: TKey; values: T[] }>>((acc, val) => {
        const selectorValue = selector(val)
        const key = toString(selectorValue)

        if (lookup[key]) {
            lookup[key].push(val)
        } else {
            lookup[key] = [val]
            acc.push({ key: selectorValue, values: lookup[key] })
        }

        return acc
    }, [])
}

export function toArray(count: number): any[] {
    const array = []

    for (let i = 0; i < count; i++) {
        array.push(null)
    }

    return array
}

export function capitalize(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function isPuzzlePage({ location }: { location: H.Location }) {
    const regex = /^\/puzzles(\/|$)/gm
    const isMatch = location.pathname.match(regex)
    return isMatch ? isMatch.length >= 1 : false
}

export function isPathToPageContainingFilteredList({
    location,
}: {
    location: H.Location
}) {
    const regex = /^\/(search|saved-articles)(\/|$)/gm
    const isMatch = location.pathname.match(regex)
    return isMatch ? isMatch.length >= 1 : false
}

export function isArticleLikeKind(
    kind: 'article' | 'redirect' | 'gallery' | 'event' | 'unknown' | 'video',
) {
    return kind === 'article' || kind === 'event'
}
