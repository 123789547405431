import { handleUnknownError } from '@news-mono/common'
import {
    AnalyticsEventDef,
    AppState,
    AuthenticationActions,
    AuthenticationState,
    authEntitlementsChecked,
    authLogin,
    authPaywallBypass,
    authRefreshLogin,
    AuthTokenExchangeResponse,
    DataLayerEventName,
    debugExtendedAccess,
    Entitlements,
    ExtendedAccessEvent,
    getAuthStateFromUserInfoToken,
    getLoginUrl,
    GoogleEvent,
    isSwGEvent,
    LoginDetails,
    setSwGState,
    SubscribeClickOverrides,
    SubscribeWithGoogleEvent,
    Subscriptions,
    tokenExchangePath,
} from '@news-mono/web-common'
import { PromiseCompletionSource } from 'promise-completion-source'
import { Dispatch, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Logger } from 'typescript-log'
import {
    CredentialResponse,
    decodeToken,
    getGoogleOAuthClientId,
    getReferrers,
    IdConfiguration,
    productIdFromLocation,
    PromptMomentNotification,
    UpdateEventManagerLoaded,
    UserState,
} from '.'
import { debugSwg, isAuth0UserInfoResponse } from '../../authentication'
import { BaseClientConfig } from '../../client'
import { AddToCartEvent } from '../../events'
import { GaaMeteringType, InitParams } from './swg-api'

declare global {
    interface Window {
        SWG: Array<(subscriptions: Subscriptions) => void>
    }
}

interface SubscribeWithGoogleProps {
    onEvent: (
        event: ExtendedAccessEvent | SubscribeWithGoogleEvent | AddToCartEvent,
    ) => void
    getSubscribeLink: (overrides?: SubscribeClickOverrides) => string
}

export const SubscribeWithGoogleContent: React.FC<SubscribeWithGoogleProps> = ({
    onEvent,
    getSubscribeLink,
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        // Only initialize swg.js when EA is not triggered
        if (getSubscribeLink) {
            debugExtendedAccess('Initialize swg.js')
            SwGInitialisedPromise = SwGInitialised(
                getSubscribeLink,
                dispatch,
                onEvent,
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

// https://developers.google.com/news/subscribe/extended-access/guides/integrate-swg-ea?hl=en#initialize-client-libraries
export const SwGInitialised = (
    getSubscribeLink: (overrides?: SubscribeClickOverrides) => string,
    dispatch: Dispatch<any>,
    onEvent: (
        event: ExtendedAccessEvent | SubscribeWithGoogleEvent | AddToCartEvent,
    ) => void,
) =>
    new Promise<void>((resolve) => {
        ;(self.SWG = self.SWG || []).push(function (
            subscriptions: Subscriptions,
        ) {
            // Init manually https://github.com/subscriptions-project/swg-js/blob/main/docs/embed-client.md#manual-initialization
            const productId = productIdFromLocation()
            debugExtendedAccess('Initialising SwG productId: %s', productId)

            subscriptions.init(productId)

            // Handle when the user clicks "Already have an account?"
            subscriptions.setOnLoginRequest(() => {
                document.location.href = getLoginUrl()
            })

            if (getSubscribeLink) {
                // Handle when the user clicks "Subscribe"
                subscriptions.setOnNativeSubscribeRequest(() => {
                    document.location.href = getSubscribeLink({
                        callToAction: 'extended-access',
                        packagePath: 'subscribe/new',
                    })
                })
            }

            subscriptions.setOnFlowCanceled((arg) =>
                debugSwg('SwG flow canceled by user %o', arg),
            )

            const GoogleEntitlementPromise =
                new PromiseCompletionSource<Entitlements>()
            subscriptions.setOnEntitlementsResponse(
                (entitlementsPromise: Promise<Entitlements>) => {
                    entitlementsPromise.then((googleEntitlement) => {
                        // Prob need to have some sort of timeout/error handling?
                        GoogleEntitlementPromise.resolve(googleEntitlement)
                    })
                },
            )

            subscriptions
                .getEventManager()
                .then((manager) => {
                    debugExtendedAccess(
                        'registering SwG analytics event listener',
                    )
                    manager.registerEventListener(swgEventListener(onEvent))

                    dispatch(
                        setSwGState({
                            subscriptions: subscriptions,
                            entitlementsPromise:
                                GoogleEntitlementPromise.promise,
                        }),
                    )

                    resolve(undefined)
                })
                .then(() => {
                    // Ensure swg button attaches after event listener is registered,
                    // otherwise IMPRESSION_SWG_BUTTON will not fire
                    dispatch(UpdateEventManagerLoaded(true))
                })
        })
    })

export let SwGInitialisedPromise: Promise<void> | null = null

declare const GaaMetering: GaaMeteringType
declare const google: {
    accounts: {
        id: {
            initialize: (args: IdConfiguration) => void
            prompt: (
                args: (notification: PromptMomentNotification) => void,
            ) => void
            revoke: (email: string) => void
        }
    }
}

/**
 *
 * @todo unsure if this is still required - added the one tap login for now just to
 * verify the new library is working
 */
export async function handleReturningGoogleUser(
    dispatch: Dispatch<any>,
    log: Logger,
    config: BaseClientConfig,
) {
    return new Promise((resolve) => {
        try {
            debugExtendedAccess('init google accounts')
            google.accounts.id.initialize({
                client_id: getGoogleOAuthClientId(config, location.hostname),
                callback: async (token: CredentialResponse) => {
                    const googleUser = decodeToken(token.credential)
                    debugExtendedAccess('google.accounts.id callback %o', {
                        token,
                        user: googleUser,
                    })
                    debugExtendedAccess(
                        'Attempting to link returning google user %o',
                        googleUser,
                    )
                    dispatch({
                        type: AuthenticationActions.GOOGLE_LOGIN,
                        payload: { googleLogin: 'linking' },
                    })

                    resolve(true)
                    await registerUser(token.credential, dispatch, log)
                },
                auto_select: true,
            })
            resolve(false)
        } catch (err) {
            debugExtendedAccess('Link returning google user failed %o', err)
            resolve(false)
        }
    })
}

function handleLoginPromise(dispatch: Dispatch<any>, log: Logger) {
    debugExtendedAccess('creating handleLoginPromise')
    return new Promise(() => {
        GaaMetering.getLoginPromise().then(() => {
            debugExtendedAccess('resolving handleLoginPromise')
            // Redirect to a login page for existing users to login.
            window.location.href = getLoginUrl()
        })
    })
}

/**
 * This functions gives the publisher the option to look up the user if we want to
 * check their entitlements or perhaps do our own metering
 * @todo maybe provide user id for logged in / no entitlement?
 */
async function publisherEntitlementPromise(
    dispatch: Dispatch<any>,
    log: Logger,
    authState: AuthenticationState,
) {
    debugExtendedAccess('publisherEntitlementPromise')
    // We've already done a user refresh before we get here so just return no entitlement
    return getUserState(authState)
}

function unlockArticle(dispatch: Dispatch<any>, bypassSlug: string) {
    return () => {
        dispatch(
            authPaywallBypass({
                bypassSlug,
                reason: 'Google extended access metering',
            }),
        )
        debugExtendedAccess('EA unlockArticle: %o')
    }
}

/**
 * There is nothing to do because the paywall is already showing!
 */
function showPaywall() {
    debugExtendedAccess('showPaywall')
}

/**
 * @todo Do the SWG stuff here
 * This callback triggers if the user is a Subscribe with Google subscriber.
 * Update user
 */
// https://developers.google.com/news/subscribe/extended-access/integration-steps/web-implementation?feed=client-side#initialize-the-extended-access-library
// https://developers.google.com/news/subscribe/extended-access/guides/integrate-swg-ea?hl=en#check-entitlements
function handleSwGEntitlement(
    entitlements: Entitlements,
    dispatch: Dispatch<any>,
    bypassSlug: string,
) {
    debugExtendedAccess('handleSwGEntitlement: %o', entitlements)
    const hasSwgEntitlements = entitlements.entitlements.some(
        (entitlement) =>
            entitlement.source === 'google' ||
            entitlement.source === 'google:metering',
    )
    if (hasSwgEntitlements) {
        dispatch(
            authPaywallBypass({
                bypassSlug,
                reason: 'Google extended access metering',
            }),
        )
        debugExtendedAccess('Swg entitlements detected, EA unlockArticle.')
    }
}

// Launch Google registration intervention when an anonymous user does not have access to read
// an article
export async function showGoogleRegwall(
    dispatch: Dispatch<any>,
    log: Logger,
    config: BaseClientConfig,
    articleSlug: string,
    authState: AuthenticationState,
    onEvent: (
        event: ExtendedAccessEvent | SubscribeWithGoogleEvent | AddToCartEvent,
    ) => void,
) {
    // initialise userstate properly for existing SwG and publisher subscribers
    const userState = getUserState(authState)

    const params: InitParams = {
        googleApiClientId: getGoogleOAuthClientId(config, location.hostname),
        userState,
        allowedReferrers: getReferrers(config),
        handleLoginPromise: handleLoginPromise(dispatch, log),
        registerUserPromise: registerUserPromise(dispatch, log),
        // should match user id here to userState - can provide additional data
        publisherEntitlementPromise: publisherEntitlementPromise(
            dispatch,
            log,
            authState,
        ),
        unlockArticle: unlockArticle(dispatch, articleSlug),
        showPaywall,
        handleSwGEntitlement: (entitlements: Entitlements) =>
            handleSwGEntitlement(entitlements, dispatch, articleSlug),

        // SwG is initialized via swg.js(subscriptions.init) so we need to bypass it here, see
        // https://developers.google.com/news/subscribe/extended-access/guides/integrate-swg-ea?hl=en#initialize-client-libraries
        // https://developers.google.com/news/subscribe/extended-access/integration-steps/web-implementation
        shouldInitializeSwG: false,
    }
    debugExtendedAccess('Initiating GaaMetering %o', params)
    GaaMetering.init(params)
    registerSwgEventListener(onEvent, dispatch)
}

function swgEventListener(onEvent: any) {
    return (event: GoogleEvent) => {
        const eventType =
            event.eventType && isSwGEvent(event.eventType)
                ? DataLayerEventName.subscribeWithGoogle
                : DataLayerEventName.extendedAccess
        if (
            eventType === DataLayerEventName.extendedAccess ||
            eventType === DataLayerEventName.subscribeWithGoogle
        ) {
            const internalEvent = {
                type: eventType,
                payload: {
                    ...event,
                    eventType: AnalyticsEventDef[event.eventType as number],
                    eventCode: event.eventType,
                },
                originator: 'extended-access',
            }
            onEvent(internalEvent)
            debugExtendedAccess('analytics event: %o', internalEvent)
        }
    }
}

function registerSwgEventListener(
    onEvent: (
        event: ExtendedAccessEvent | SubscribeWithGoogleEvent | AddToCartEvent,
    ) => void,
    dispatch: Dispatch<any>,
) {
    ;(self.SWG = self.SWG || []).push((subscriptions: Subscriptions) => {
        debugExtendedAccess('subscriptions %o', subscriptions)

        subscriptions
            .getEventManager()
            .then((manager) => {
                debugExtendedAccess(
                    'registering SwG analytics event listener in EA',
                )

                manager.registerEventListener(swgEventListener(onEvent))
            })
            .then(() => {
                // Ensure swg button attaches after event listener is registered,
                // otherwise IMPRESSION_SWG_BUTTON will not fire
                dispatch(UpdateEventManagerLoaded(true))
            })

        dispatch(
            setSwGState({
                subscriptions,
                entitlementsPromise: undefined,
            }),
        )
    })
}

function getUserState(authState: AuthenticationState): UserState {
    const { isLoggedIn, isEntitled, wanUserId, registrationTimestamp } =
        authState
    if (!isLoggedIn) {
        return { granted: false }
    }

    if (isEntitled) {
        return {
            id: wanUserId,
            registrationTimestamp,
            // Use registrationTimestamp here as it's not available
            subscriptionTimestamp: registrationTimestamp,
            granted: true,
            grantReason: 'SUBSCRIBER',
        }
    } else {
        return {
            id: wanUserId,
            registrationTimestamp,
            granted: false,
        }
    }
}

function completeSiteLogin(
    dispatch: Dispatch<any>,
    data: AuthTokenExchangeResponse,
    log: Logger,
) {
    const invocation = 'manual'
    const onEvent = () => null

    debugExtendedAccess('Completing site login with google sign in: %o', {
        data,
        invocation,
    })

    if (data.loginProvider === 'Google') {
        const authState: LoginDetails = {
            coralToken: data.coralToken,
            entitlements: [],
            googleUserState: data.googleUserState,
            loginProvider: data.loginProvider,
            socialProviders: '',
            subscriptionType: 'none',
            userName: `${data.userInfo.given_name} ${data.userInfo.family_name}`,
            wanUserId: data.userInfo.sub,
            hashedSophiUserID: data.hashedSophiUserID,
            hashedLiveRampEmail: data.hashedLiveRampEmail,
            registrationTimestamp: Math.round(
                new Date(
                    isAuth0UserInfoResponse(data.userInfo)
                        ? data.userInfo['swm.registered']
                        : data.userInfo.registered,
                ).getTime() / 1000,
            ),
        }
        dispatch(authLogin({ ...authState, onEvent }))
    } else {
        dispatch(authEntitlementsChecked())
        const authState = getAuthStateFromUserInfoToken(
            data.userInfo,
            onEvent,
            data.hashedSophiUserID,
        )
        dispatch(
            authRefreshLogin({
                ...authState,
                socialProviders: 'Google',
                googleUserState: data.googleUserState,
                coralToken: data.coralToken,
            }),
        )
    }
}

async function registerUserPromise(
    dispatch: Dispatch<any>,
    log: Logger,
): Promise<UserState> {
    const gaaUser =
        (await GaaMetering.getGaaUserPromise()) as CredentialResponse
    const token = decodeToken(gaaUser.credential)
    debugExtendedAccess(
        'Exchanging google token for auth tokens',
        gaaUser,
        token,
    )
    const registerUserRes = await registerUser(
        gaaUser.credential,
        dispatch,
        log,
    )
    debugExtendedAccess('registerUserRes', registerUserRes)
    return registerUserRes
}

export async function registerUser(
    token: string,
    dispatch: Dispatch<any>,
    log: Logger,
) {
    dispatch({
        type: AuthenticationActions.GOOGLE_LOGIN,
        payload: { googleLogin: 'linking' },
    })
    try {
        const response = await fetch(tokenExchangePath, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
        })
        debugExtendedAccess('Token exchange complete')
        const result = await response.json()
        dispatch({
            type: AuthenticationActions.GOOGLE_LOGIN,
            payload: {
                googleLogin: 'complete',
                googleUserState: result.googleUserState,
            },
        })
        completeSiteLogin(dispatch, result, log)
        const state: UserState = {
            granted: false,
            id: result.googleUserState.metering.state.id,
            registrationTimestamp:
                result.googleUserState.metering.state.standardAttributes
                    .registered_user.timestamp,
        }
        debugExtendedAccess('Token exchange login complete', state)
        return state
    } catch (error) {
        const err = handleUnknownError(error)
        log.error({ err }, 'Token exchange failed')
        return { granted: false }
    }
}
