import styled from '@emotion/styled'
import { Product } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { metrics } from '../../__styling/settings/metrics'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'

export const StyledFootnote = styled('p')(
    {
        fontWeight: 400,
    },
    breakWords,
    ({ theme }) => ({
        fontFamily: themedValue(theme, {
            thewest: theme.fonts.body,
            fallback: theme.fonts.sansSerif,
        }),
        fontSize: calcRem(14),
        lineHeight: themedValue(theme, {
            thewest: 1.25,
            perthnow: 1.429, // 20px / 14px
            fallback: undefined,
        }),
        color: themedValue(theme, {
            thewest: colors.black,
            perthnow: colors.perthnow.greyMoonstone,
            fallback: undefined,
        }),
        margin: themedValue(theme, {
            thewest: `${calcRem(metrics.thewest.textMargin)} 0 0 0`,
            perthnow: `${calcRem(metrics.perthnow.textMargin)} 0 0 0`,
            fallback: undefined,
        }),
        // Needs to clear potential floated images
        clear: theme.kind === Product.TheWest ? 'both' : undefined,
    }),
)
