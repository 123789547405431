import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { durationInt } from '../../__styling/settings/easing'
import {
    StyledClearButton,
    StyledCrossIcon,
    StyledSearchContainer,
    StyledSearchForm,
    StyledSearchComponents,
    StyledSearchIcon,
    StyledSearchInput,
    StyledSearchSubmitButton,
} from './SearchForm.styled'
import {
    RESULT_SORT_PARAM,
    TIME_FILTER_PARAM,
    TOPIC_FILTER_PARAM,
} from '../../result-filters'
import { getSearchEventArgs } from '../../result-filters/filter-events-util'
import { DataLayerEventName, SearchEvent } from '@news-mono/web-common'

interface State {
    searchValue: string
    searchHasFocus: boolean
}

interface RenderProps {
    renderSearchButton: () => React.ReactNode
}

export type SearchCallLocation = 'Flyout' | 'Top Nav' | 'Search Page'

export interface SearchFormProps {
    isLarge?: boolean
    inMainNavigation?: boolean
    mountsAsVisable?: boolean
    mountsWithValue?: string
    children?: (renderProps: RenderProps) => React.ReactNode
    searchIconSize?: number
    hasPlaceholder?: boolean
    resetFormAfterSubmit?: boolean
    onSubmit?: () => void
    onEvent?: (event: SearchEvent) => void
    callLocation?: SearchCallLocation
}

/**
 * The search component can be implemented as a stand alone component or as a render pop component, for example: <Search /> or
 * <Search>({ renderSearchButton }) => (...)</Search>. This provides an alternative in the case where
 * you need to render the search butten and suggestions list in different locations.
 */

class InternalSearchForm extends React.Component<
    SearchFormProps & RouteComponentProps<any>,
    State
> {
    private inputEl: HTMLInputElement | null = null
    private willUnfocusTimeout: NodeJS.Timer | null = null
    static displayName = 'InternalSearch'

    constructor(props: SearchFormProps & RouteComponentProps<any>) {
        super(props)
        this.state = {
            searchValue: props.mountsWithValue ? props.mountsWithValue : '',
            searchHasFocus: props.mountsAsVisable
                ? props.mountsAsVisable
                : false,
        }
    }

    searchMinimumLength = (): boolean => {
        return this.state.searchValue.length >= 1
    }

    resetState(ignoreValue?: boolean) {
        this.setState((state) => ({
            searchValue: ignoreValue ? state.searchValue : '',
            searchHasFocus: false,
        }))
    }

    handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.currentTarget.value
        this.setState({ searchValue })
    }

    handleClear = () => {
        this.setState({ searchValue: '' })
        this.setInputFocusState(true)
        if (this.inputEl) {
            this.inputEl.focus()
        }
    }

    handleClickSearch = () => {
        this.setInputFocusState(true)
        // Cannot set focus until the animation has finished
        this.willUnfocusTimeout = setTimeout(() => {
            if (this.inputEl) {
                this.inputEl.focus()
            }
        }, durationInt.fast)
    }

    handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (this.searchMinimumLength()) {
            this.props.onSubmit && this.props.onSubmit()

            // This maintains any existing search params, which are use for filtering results
            const searchParams = new URLSearchParams(this.props.location.search)

            // remove any filtering and sorting
            if (this.props.inMainNavigation) {
                searchParams.delete('page')
                searchParams.delete(RESULT_SORT_PARAM)
                searchParams.delete(TOPIC_FILTER_PARAM)
                searchParams.delete(TIME_FILTER_PARAM)
            }

            searchParams.set('search', this.state.searchValue)
            this.props.history.push(`/search?${searchParams.toString()}`)

            const searchTerm = this.state.searchValue

            if (this.props.resetFormAfterSubmit) {
                this.handleClear()
            }

            if (this.props.onEvent && this.props.callLocation) {
                this.props.onEvent({
                    type: DataLayerEventName.search,
                    originator: this.props.callLocation,
                    payload: getSearchEventArgs(searchTerm, searchParams),
                })
            }
        }
    }

    setInputFocusState = (isFocused: boolean) => {
        if (
            isFocused === false &&
            this.inputEl &&
            this.inputEl.value.trim().length === 0
        ) {
            this.willUnfocusTimeout = setTimeout(() => {
                this.resetState()
            }, 100)
        } else {
            if (this.willUnfocusTimeout) {
                clearTimeout(this.willUnfocusTimeout)
                this.willUnfocusTimeout = null
            }
            this.setState({ searchHasFocus: true })
        }
    }

    render() {
        const { searchValue, searchHasFocus } = this.state
        const searchHasValue = searchValue.length > 0
        const isLarge = this.props.isLarge || false

        const searchInput = (
            <StyledSearchContainer
                searchInMainNav={this.props.inMainNavigation}
                searchHasValue={searchHasValue}
                isLarge={isLarge}
            >
                <StyledSearchForm
                    onSubmit={this.handleSubmit}
                    method="get"
                    action="/search"
                    autoComplete="off"
                    role="search"
                    searchHasFocus={searchHasFocus}
                    searchHasValue={searchHasValue}
                    isLarge={isLarge}
                    searchInMainNav={this.props.inMainNavigation}
                >
                    <StyledSearchComponents>
                        <StyledSearchInput
                            name="search"
                            placeholder={
                                this.props.hasPlaceholder ? 'Search' : undefined
                            }
                            type="search"
                            ref={(el: HTMLInputElement) => (this.inputEl = el)}
                            value={searchValue}
                            onChange={this.handleSearchInput}
                            onFocus={() => this.setInputFocusState(true)}
                            onBlur={() => this.setInputFocusState(false)}
                        />
                        <StyledClearButton
                            type="reset"
                            isLarge={isLarge}
                            tabIndex={
                                searchHasFocus && searchHasValue
                                    ? undefined
                                    : -1
                            }
                            onFocus={() => this.setInputFocusState(true)}
                            onBlur={() => this.setInputFocusState(false)}
                            onClick={this.handleClear}
                            searchHasValue={searchHasValue}
                        >
                            <StyledCrossIcon aria-label="Clear Search Input" />
                        </StyledClearButton>
                    </StyledSearchComponents>
                    <StyledSearchSubmitButton
                        type="submit"
                        isLarge={isLarge}
                        disabled={!this.searchMinimumLength()}
                        title={
                            !this.searchMinimumLength()
                                ? 'Please enter a search term.'
                                : ''
                        }
                    >
                        <StyledSearchIcon />
                    </StyledSearchSubmitButton>
                </StyledSearchForm>
            </StyledSearchContainer>
        )

        if (this.props.children) {
            return this.props.children({
                renderSearchButton: () => searchInput,
            })
        }

        return searchInput
    }
}

export const SearchForm = withRouter(InternalSearchForm)
SearchForm.displayName = 'SearchForm'
