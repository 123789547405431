import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { Box, BreakPointProps, FlexProps } from '../../compositions/Box/Box'
import { Heading } from '../../typography/Heading/Heading'
import { ContainerWidth, ThemeMargins } from '../../__styling/settings/metrics'
import { BaseCompositionProps } from '../types'
import { BreakpointKey } from '../../cards/Kicker/Kicker.styled'

export interface BoxRouteProps extends BaseCompositionProps {
    id?: string
    pageHeading?: string
    hasTopSpacing?: boolean
    verticalSpacing?: keyof ThemeMargins
    horizontalSpacing?: keyof ThemeMargins
    horizontalGutters?: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    containerWidth?: ContainerWidth
    breakpoints?: BreakPointProps
    hasBackgroundFill?: boolean
    backgroundColor?: string
    fillContainer?: boolean
    positionOffset?: {
        direction: 'top' | 'bottom'
        value: number
    }
    containerHeight?: number
    stickyOffset?: number | string
    nightlyArticleSidebar?: boolean
    flex?: FlexProps
    horizontalSpacingBreakpoint?: BreakpointKey
    hideIfEmpty?: boolean
}

export const BoxRegistration = createRegisterableComposition<'main'>()(
    'box',
    (contentAreas, props: BoxRouteProps) => (
        <Box {...props}>
            {props.pageHeading && <Heading text={props.pageHeading} />}
            {contentAreas.main}
        </Box>
    ),
)
