import { TheWestSection } from '@news-mono/common'
import {
    ConfigurationContext,
    EntitledToView,
    getBaseUrl,
    isLiveSportsTopic,
    useFeature,
} from '@news-mono/web-common'
import {
    EventPostV4DTO,
    KilledEventPostV4DTO,
} from '@west-australian-newspapers/publication-types'
import { WebsocketServiceMessageWithPayload } from '@west-australian-newspapers/websocket-message-types'
import { nth } from 'ramda'
import React, { useContext, useState } from 'react'
import { LinkBanner } from '../../../banners/LinkBanner/LinkBanner'
import { ArticleBlockContent } from '../../../publication/ArticleBlockContent/ArticleBlockContent'
import { ArticleHero } from '../../../publication/ArticleHero/ArticleHero'
import { Byline } from '../../../publication/Byline/Byline'
import { Footnote } from '../../../publication/Footnote/Footnote'
import { Headline } from '../../../publication/Headline/Headline'
import { LiveEvent } from '../../../publication/LiveEvent/LiveEvent'
import { getCanonicalLink } from '../../../templates/Publication/lib/get-canonical-link'
import { getEditorialType } from '../../../templates/Publication/lib/get-editorial-type'
import { getFundingType } from '../../../templates/Publication/lib/get-funding-type'
import { PublicationProps } from '../../../templates/Publication/Publication.props'
import {
    StyledArticleContent,
    StyledGrid,
    StyledNestedGrid,
    StyledStickyCell,
    StyledStickyTop,
    TheWestStyledSharing,
} from '../../../templates/Publication/TheWestPublication/TheWestPublication.styled'
import { EditorialLabel } from '../../../typography/EditorialLabel/EditorialLabel'
import { ArticleBreach } from '../../../user-registration/breach/ArticleBreach'
import { appendToHeadline } from '../../../__helpers/append-to-headline'
import {
    useSection,
    useSectionMetaInfo,
} from '../../../__helpers/use-section-meta'

export const TheWestPublication: React.FC<PublicationProps> = (props) => {
    const publication = props.data
    const content = publication.content
    const primaryTopic = publication.primaryTopic.id
    const secondaryTopics = publication.secondaryTopics
    const canonicalLink = getCanonicalLink(publication)
    const heroImage = publication.heroImage
    const { publicUrl } = useContext(ConfigurationContext)
    const sectionMeta = useSectionMetaInfo()
    const articleUrl = `${getBaseUrl(publicUrl, sectionMeta)}/${primaryTopic}/${
        publication.slug
    }`
    const storyClassification = true
    const fundingType = getFundingType(publication, storyClassification)
    const editorialType = getEditorialType(publication, storyClassification)
    const subscribeEmailLink = '/manage-email-preferences'
    const allowVideoPlaylist =
        publication.heroVideo?.seriesInfo !== null &&
        publication.heroVideo?.seriesInfo?.episode !== 'trailer'

    const topicIds: string[] = secondaryTopics.map((topic) => topic.id)
    topicIds.push(primaryTopic)

    const paywallCssClass =
        publication.requiredAccess.level !== 'anonymous' ? 'paywall' : undefined

    const section = useSection<TheWestSection>()

    const publicationKind = publication.kind
    const isEvent = publicationKind === 'event'

    const [liveEventUpdated, setLiveEventUpdated] = useState(
        nth(0, isEvent ? publication.entries?.documents : [])?.lastUpdated ??
            publication.lastUpdated,
    )

    const websocketSetTimestamp = (
        post: EventPostV4DTO | KilledEventPostV4DTO,
    ) => setLiveEventUpdated(post.lastUpdated)

    return (
        <StyledGrid itemProp="articleBody">
            {editorialType && (
                <div>
                    <EditorialLabel type={editorialType} isLarge />
                </div>
            )}

            <div>
                {editorialType && isLiveSportsTopic(primaryTopic) ? (
                    <Headline
                        text={appendToHeadline(
                            publication.heading,
                            editorialType,
                        )}
                    />
                ) : (
                    <Headline text={publication.heading} />
                )}
            </div>

            <div>
                <Byline
                    profile={publication.profile}
                    text={publication.byline}
                    timestampUpdated={isEvent ? liveEventUpdated : undefined}
                    timestamp={publication.publicationDate}
                    fundingType={fundingType}
                    source={publication.source}
                    isFullWidth={true}
                    url={articleUrl}
                    title={publication.heading}
                    requiredAccess={publication.requiredAccess}
                    allowCommenting={publication.allowCommenting}
                    publicationKind={publication.kind}
                />
            </div>
            <EntitledToView
                requiredAccess={publication.requiredAccess}
                articleSlug={publication.slug}
                topicIds={topicIds}
                entitled={() => {
                    if (props.heroOptions.isVideoStickyEnabled === true) {
                        return (
                            <React.Fragment>
                                <StyledStickyTop className="sticky-top" />
                                <StyledStickyCell className="sticky-cell">
                                    <ArticleHero
                                        heroVideo={publication.heroVideo}
                                        heroImage={heroImage}
                                        heading={publication.heading}
                                        onEvent={props.onEvent}
                                        showVideoTitles={true}
                                        isVideoStickyEnabled={true}
                                        getVideoQueue={props.getVideoQueue}
                                        publicationPathname={
                                            props.location.pathname
                                        }
                                        adUnitPath={
                                            props.adState.targeting.adUnitPath
                                        }
                                        showVideoPlaylist={allowVideoPlaylist}
                                    />
                                </StyledStickyCell>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <div>
                                <ArticleHero
                                    heroVideo={publication.heroVideo}
                                    heroImage={heroImage}
                                    heading={publication.heading}
                                    onEvent={props.onEvent}
                                    showVideoTitles={true}
                                    isVideoStickyEnabled={false}
                                    getVideoQueue={props.getVideoQueue}
                                    publicationPathname={
                                        props.location.pathname
                                    }
                                    adUnitPath={
                                        props.adState.targeting.adUnitPath
                                    }
                                />
                            </div>
                        )
                    }
                }}
            />
            <div className={paywallCssClass} data-testid="BreachScreen">
                {content && (
                    <StyledArticleContent>
                        <ArticleBreach
                            requiredAccess={publication.requiredAccess}
                            topicIds={topicIds}
                            location={props.location}
                            onEvent={props.onEvent}
                            blocks={content}
                            targetedContent={props.targetedContent}
                            inlinePositionedContent={
                                props.inlinePositionedContent
                            }
                            publication={publication}
                            slug={publication.slug}
                            log={props.log}
                        >
                            {({
                                blocks,
                                targetedContent,
                                inlinePositionedContent,
                            }) => (
                                <StyledNestedGrid hideSocialLinks={isEvent}>
                                    <aside>
                                        <TheWestStyledSharing
                                            publicationId={publication.id}
                                            url={articleUrl}
                                            text={publication.heading}
                                            onEvent={props.onEvent}
                                            shareOptions={[
                                                'facebook',
                                                'twitter',
                                                'email',
                                                'clipboard',
                                            ]}
                                        />
                                    </aside>
                                    <div>
                                        <ArticleBlockContent
                                            blocks={blocks}
                                            targetedContentElement={
                                                targetedContent &&
                                                targetedContent.position ===
                                                    'inline'
                                                    ? targetedContent.element
                                                    : undefined
                                            }
                                            inlinePositionedContent={
                                                inlinePositionedContent
                                            }
                                            onEvent={props.onEvent}
                                            log={props.log}
                                            publicationKind={publication.kind}
                                            publicationPathname={
                                                props.location.pathname
                                            }
                                            adUnitPath={
                                                props.adState.targeting
                                                    .adUnitPath
                                            }
                                            getVideoQueue={props.getVideoQueue}
                                            requiredAccess={
                                                publication.requiredAccess
                                            }
                                        />

                                        {isEvent && (
                                            <LiveEvent
                                                publication={publication}
                                                log={props.log}
                                                adState={props.adState}
                                                onEvent={props.onEvent}
                                                onLiveEventUpdate={
                                                    websocketSetTimestamp
                                                }
                                            />
                                        )}
                                        <EntitledToView
                                            requiredAccess={
                                                publication.requiredAccess
                                            }
                                            topicIds={topicIds}
                                            entitled={() =>
                                                canonicalLink ? (
                                                    <Footnote
                                                        text="Originally published as "
                                                        link={canonicalLink}
                                                    />
                                                ) : null
                                            }
                                        />

                                        {section !== 'genwest' && (
                                            <LinkBanner
                                                url={
                                                    section === 'default'
                                                        ? subscribeEmailLink
                                                        : `https://thewest.com.au${subscribeEmailLink}`
                                                }
                                                message="Get the latest news from thewest.com.au in your inbox."
                                                cta="Sign up for our emails"
                                                verticalGutters={['md', 'md']}
                                            />
                                        )}
                                    </div>
                                </StyledNestedGrid>
                            )}
                        </ArticleBreach>
                    </StyledArticleContent>
                )}
            </div>
        </StyledGrid>
    )
}
TheWestPublication.displayName = 'TheWestPublication'
