import { handleUnknownError } from '@news-mono/common'
import { Logger } from 'typescript-log'
import { promiseWithTimeout, timeoutOccuredMsg } from './promiseWithTimeout'

export const grapeShotCache: { [key: string]: string[] } = {}

// Returns a set of channels to be used in the gs_channels for an add call
export async function getGrapeshotChannels(logger: Logger, timeoutMs: number) {
    if (typeof window !== 'undefined') {
        const url =
            window.location !== window.top!.location
                ? document.referrer
                : window.location.href
        // check cache first
        if (grapeShotCache[url]) {
            return grapeShotCache[url]
        }
        try {
            // timeout the grapeshot response
            logger.debug(
                { timeoutMs },
                'Preparing to make a Grapeshot request with a specified timeout',
            )
            // this is expected to run against a sevennews domain and not localhost so will throw cors errors locally
            const grapeshotResponse = await promiseWithTimeout<Response>(
                timeoutMs,
                fetch(
                    `//sevenwest.gscontxt.net/main/channels-json.cgi?url=${encodeURIComponent(
                        url,
                    )}`,
                ),
            )
            const response: {
                channels: {
                    name: string
                }[]
            } = await grapeshotResponse.json()
            const channels =
                response && response.channels
                    ? response.channels.map((c) => c.name)
                    : []
            logger.debug(
                { channels },
                'Grapeshot request complete, channels returned, stored in cache',
            )
            // set cache
            // TODO: Check this eslint rule
            // eslint-disable-next-line require-atomic-updates
            grapeShotCache[url] = channels
            return channels
        } catch (error) {
            const err = handleUnknownError(error)
            if ((err as Error).message !== timeoutOccuredMsg) {
                logger.error({ err }, 'Grapeshot call failed')
            }
            return 'timeout'
        }
    }
    return []
}
