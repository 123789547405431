import {
    EventPostV4DTO,
    LayoutViewV4DTO,
    SeriesInfoDTO,
} from '@west-australian-newspapers/publication-types'
import { ImageSourceWithCaptions } from '../client-data-types/image-item'
import { Profile } from '../client-data-types/Profile'
import {
    PublicationType,
    RequiredAccess,
} from '../client-data-types/publication'
import { Topic } from '../client-data-types/topic'
import { ClickedCardInformation } from '../events'
import { ComponentEvent } from '../events/component-event'
import { SharingEvent } from '../events/share-events'
import { PlayerEvent } from '../events/video-event-types'
import { DataLayerEventName } from '../helpers'
import { Classification } from './classification'

export type ClickableCardItems = string | CardItem | BreakingNewsCard

export interface CardClickedEventProps {
    cardItem: ClickableCardItems
    cardInfo: string
    cardNumber: number
    cardFormat:
        | 'Portrait'
        | 'Landscape'
        | 'InlineContent'
        | 'LandscapeStacked'
        | 'LandscapeWide'
        | 'List'
        | 'HeroCard'
        | 'Branded'
        | 'BreakingNews'
        | 'PromoStrap'
        | 'BlackFridayPromoStrap'
        | 'Announcement'
        | 'SevenNewsBreakingNews'
        | 'PromotionalCard'
        | 'ProfileCard'
        | 'VideoCard'
        | 'InlineRelatedContent'
        | 'SevenNewsV2PromotionalCard'
        | 'OlympicsArticleCard'
        | 'OlympicsFeatureArticleCard'
    fundingType?: string
}

export interface CardClickedEvent
    extends ComponentEvent<CardClickedEventProps> {
    type: 'card.clicked'
}
export interface SelectItemAvailableEvent
    extends ComponentEvent<Partial<ClickedCardInformation>> {
    type: DataLayerEventName.selectItemEvent
}

export type CardEvents =
    | CardClickedEvent
    | PlayerEvent
    | SharingEvent
    | SelectItemAvailableEvent

export type LiveBlogEntry = {
    milestones: EventPostV4DTO[]
    stickies: EventPostV4DTO[]
    documents: EventPostV4DTO[]
    total: number
}

export interface PublicationCardItemWithoutVideo {
    cardType: 'publication'

    // content
    id: string
    headline: string
    shortHeadline: string
    link: string
    primaryTopic: Topic
    secondaryTopics: Topic[]
    kicker?: string
    byline: string
    profiles?: Profile[]
    publicationKind: PublicationType['kind']
    source: string
    layoutView?: LayoutViewV4DTO
    classification?: Classification
    allowCommenting: boolean

    teaser: string
    publicationDate?: string
    lastUpdated?: string

    // media
    image?: ImageSourceWithCaptions
    providerLogo?: string

    // status
    isLive: boolean
    isSponsored: boolean
    requiredAccess: RequiredAccess

    // number of images in gallery
    imageCount?: number

    // full url path to publication
    _self: string
    promotionType?: string

    readTimeMinutes: number | null
    canonicalUrl?: string | null

    // to support search results with highlighted matches
    headingWithSearchHighlight?: string
    teaserWithSearchHighlight?: string
    userInteractionId?: number

    entries?: LiveBlogEntry
}

export interface PublicationCardItemVideo {
    id: string
    accountId: string
    heading: string
    source: string | undefined
    duration: string | null
    videoType: 'vod' | 'live' | null
    videoSeries?: SeriesInfoDTO | null
}

export interface PublicationCardItemWithVideo
    extends PublicationCardItemWithoutVideo {
    video: PublicationCardItemVideo
    provider?: string
    providerLogo?: string
}

export interface MarketingRedirectTileCard {
    cardType: 'marketing-redirect-tile'
    image: string
    url: string
    altText: string
    promotionType?: string
    id: string
    source: string
    primaryTopic: Topic
    headline: string
    classification?: Classification
}
export interface BreakingNewsCard {
    cardType: 'breaking-news'
    shortHeadline: string
    link: string
    listId: string
}

export type PublicationCardItem =
    | PublicationCardItemWithoutVideo
    | PublicationCardItemWithVideo

export type CardItem = PublicationCardItem | MarketingRedirectTileCard

export function isPublicationCardItem(
    cardItem: CardItem,
): cardItem is PublicationCardItem {
    return cardItem.cardType === 'publication'
}
export function isPublicationCardItemWithVideo(
    cardItem: PublicationCardItemWithoutVideo,
): cardItem is PublicationCardItemWithVideo {
    return 'video' in cardItem
}
